import globalLinkServices from "../../services/GlobalLinkService"
import { notification } from "../../util/func";
import { 
    GET_GLOBAL_LINK, 
    GET_ALL_GLOBAL_LINK, 
    GET_CUSTOM_GLOBAL_LINK, 
    RELOAD_GLOBAL_LINK 
} from "../types/GlobalLinkType";
import { tableLoadingAction } from "./LoadingAction";

export const getGlobalLinkAction = (id) => {
    return (dispatch2) => {
        let promise = globalLinkServices.getGlobalLink(id);
        promise.then((result) => {
            let action2 = {
                type: GET_GLOBAL_LINK,
                globalLink: result.data.content
            }
            dispatch2(action2);
        });
        promise.catch((error) => {
            console.log(error);
        })
    };
};

export const getAllGlobalLinkAction = () => {
    return (dispatch2) => {
        let promise = globalLinkServices.getAllGlobalLink();
        promise.then((result) => {
            let action2 = {
                type: GET_ALL_GLOBAL_LINK,
                allGlobalLink: result.data.content
            }
            dispatch2(action2);
        });
        promise.catch((error) => {
            console.log(error);
        })
    }
};

export const getCustomGlobalLinkAction = (filterValue) => {
    return (dispatch2) => {
        dispatch2(tableLoadingAction(true));
        let promise = globalLinkServices.getCustomGlobalLink(filterValue);
        promise.then((result) => {
            let action2 = {
                type: GET_CUSTOM_GLOBAL_LINK,
                customGlobalLink: result.data.content,
            };
            dispatch2(action2);
            dispatch2(tableLoadingAction(false));
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const addGloballLinkAction = (data) => {
    return (dispatch2) => {
        let promise = globalLinkServices.addGloballLink(data);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_GLOBAL_LINK,
                reloadGlobalLink: true,
            }
            dispatch2(action2);
            notification("Thêm liên kết thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const updateGlobalLinkAction = (id, data) => { 
    return (dispatch2) => { 
        let promise = globalLinkServices.updateGlobalLink(id, data);
        promise.then((result) => { 
            let action2 = {
                type: RELOAD_GLOBAL_LINK,
                reloadGlobalLink: true,
            }
            dispatch2(action2);
            notification("Cập nhập liên kết thành công");
         });
         promise.catch((error) => { 
            notification(error.response?.data.message);
          });
     };
 };

 export const removeGlobalLinkAction = (id = '') => {
    return (dispatch2) => {
        let promise = globalLinkServices.deleteGlobalLink(id);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_GLOBAL_LINK,
                reloadGlobalLink: true,
            }
            dispatch2(action2);
            notification("Xóa liên kết thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const reloadGlobalLinkAction = () => {
    return (dispatch2) => {
        let action2 = {
            type: RELOAD_GLOBAL_LINK,
            reloadGlobalLink: false,
        }
        dispatch2(action2);
    };
};




