import React from 'react'
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import parse from 'html-react-parser';

export default function About(props) {
    const customAbout = props.customAbout;
    const renderAbout = (data = []) => {
        return data?.map((el) => {
            return <Carousel.Item key={`about${el?.id}`}>
                <Row>
                    <Col md={5} className='about__left' >
                        <Carousel.Caption>
                            <h3>{parse(el?.title)}</h3>
                            <div className='caption__content'>{parse(el?.content)}</div>
                            {/* <p dangerouslySetInnerHTML={{ __html: el.aboutContent }}></p> */}
                        </Carousel.Caption>
                    </Col>
                    <Col md={7} className='about__right ms-auto'>
                        <div className='carousel__image'>
                            <img src={el?.image?.url}
                                alt="ANTE"
                            />
                        </div>
                    </Col>
                </Row>
            </Carousel.Item>
        })
    }

    return (
        <div className='about'>
            <Container fluid className='about__content'>
                <Carousel slide={false} controls={true}>
                    {renderAbout(customAbout)}
                </Carousel>
            </Container>
        </div>
    )
}
