import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Upload, TreeSelect, Form, Input, Button, Modal } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import { useParams } from 'react-router-dom';
import {
    getAllCategoryAction,
    getCategoryAction,
    updateCategoryAction,
} from '../../../redux/actions/CategoryAction';
import { uploadFile } from '../../../util/func';
import categoryServices from '../../../services/CategoryService';

export default function EditCategory() {
    const { id } = useParams();
    const [detailImageId, setDetailImageId] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isTinymce, setIsTinymce] = useState(false);
    const editorRef = useRef(null);
    const { allCategory, category, reloadCategory } = useSelector(state => state.CategoryReducer);
    let dispatch = useDispatch();
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const formik = useFormik({
        initialValues: {
            name: '',
            parentId: '',
            image: {},
            detail: '',
            fileIdList: []
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Tên không được để trống")
                .test("Tên", "Tên đã tồn tại", async (name) => {
                    let validationCategory = {
                        id: category?.id,
                        name: name
                    }
                    let check = await categoryServices.validationCategory(validationCategory).then((result) => {
                        return result.data.content;
                    });
                    return !check;
                }),
            parentId: Yup.string().required("Danh mục cha không được để trống")
        }),
        onSubmit: values => {
            let action = updateCategoryAction(category?.id, values);
            dispatch(action);
        },
    });


    const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

    useEffect(() => {
        getCategoryAPI();
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        getAllCategoryAPI();
    }, []);

    useEffect(() => {
        if (Object.keys(category).length) {
            form.setFieldsValue({
                name: category?.name,
                detail: category?.detail,
                parentId: category?.parentId
            });
            formik.setValues(category);
        }
        // eslint-disable-next-line
    }, [JSON.stringify(category)]);

    useEffect(() => {
        if (reloadCategory !== false) {
            navigate('/admin/categories');
        }
        // eslint-disable-next-line
    }, [reloadCategory]);

    useEffect(() => {
        if (isTinymce === true) {
            const content = values?.detail;
            if (editorRef.current) {
                if (content) {
                    editorRef.current.setContent(content)
                }
                else {
                    editorRef.current.resetContent();
                }
            }
        }
    }, [isTinymce]);

    useEffect(() => {
        if (detailImageId > 0) {
            let arrFile = values?.fileIdList ? values?.fileIdList : [];
            arrFile.push(detailImageId);
            setFieldValue('fileIdList', arrFile);
        }
    }, [detailImageId]);

    //control modal
    const showModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const treeCategories = (data) => {
        let tree = [];
        for (const el of data) {
            if (el.id !== category.id) {
                if ((el?.childes || []).length) {
                    tree.push({ title: el?.name, value: el?.id, children: treeCategories(el.childes) });
                }
                else {
                    tree.push({ label: el?.name, value: el?.id });
                }
            }
        }
        return tree;
    }

    let getCategoryAPI = () => {
        let action = getCategoryAction(id);
        dispatch(action);
    };

    const uploadDetailImageAPI = (blobInfo, progress) => new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.blob()?.name);
        let image = uploadFile(formData);
        resolve(image);
    }).then((value) => {
        setDetailImageId(value.id);
        return value.url;
    });

    const getAllCategoryAPI = () => {
        let action = getAllCategoryAction();
        dispatch(action);
    }

    //upload image
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const updateImageCategoryAPI = async (file) => {
        let formData = new FormData();
        formData.append('file', file, file.name);
        let imageFile = await uploadFile(formData);
        setFieldValue('image', { uid: imageFile.id, url: imageFile.url });
        return false;
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    return (
        <div>
            <h2 >Cập nhập thông tin nhóm sản phẩm</h2>
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }}
                layout="horizontal"
                onFinish={handleSubmit}
                form={form}
            >
                <Form.Item label="Tên" name="name"
                    validateStatus={touched.name && errors.name ? "error" : "success"}
                    help={touched.name && errors.name ? (errors.name) : null}>
                    <Input onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Danh mục" name="parentId"
                    help={touched.parentId && errors.parentId ? errors.parentId : null}
                    validateStatus={touched.parentId && errors.parentId ? "error" : "success"}
                >
                    <TreeSelect
                        showSearch
                        style={{
                            width: '100%',
                        }}
                        value={values.parentId}
                        dropdownStyle={{
                            maxHeight: 400,
                            overflow: 'auto',
                        }}
                        placeholder="Please select"
                        allowClear
                        treeDefaultExpandAll
                        onChange={(value) => setFieldValue('parentId', value)}
                        treeData={[{ title: 'Danh mục gốc', value: 0, children: treeCategories(allCategory) }]}
                    />
                </Form.Item>
                <Form.Item label="Hình ảnh" valuePropName="fileList">
                    <Upload
                        customRequest={dummyRequest}
                        listType="picture-card"
                        fileList={
                            Object.keys(values?.image || {})?.length > 0 ?
                                [{
                                    uid: values?.image?.uid,
                                    name: `image${values?.image?.uid}`,
                                    status: 'done',
                                    url: values?.image?.url,
                                }] : []
                        }
                        onRemove={() => setFieldValue('image', {})}
                        beforeUpload={updateImageCategoryAPI}
                        onPreview={onPreview}
                    >
                        {Object.keys(values?.image || {})?.length > 0 ? null : uploadButton}
                    </Upload>
                </Form.Item>
                <Form.Item label="Mô tả" name="detail">
                    <Button onClick={() => showModal()}>
                        Cập nhập nội dung
                    </Button>
                </Form.Item>
                <Form.Item label="Hành động">
                    <Button type="primary" htmlType="submit">Cập nhập</Button>
                </Form.Item>
            </Form>
            <Modal title="Mô tả chi tiết"
                centered
                width={1000}
                open={isModalOpen}
                onOk={() => {
                    if (editorRef.current) {
                        setFieldValue('detail', editorRef.current.getContent());
                    }
                    closeModal();
                }}
                onCancel={closeModal}>
                <Editor
                    apiKey='a68gdz94s7gbdrmzq6n8h99gw0yr98wxwmpfd7rfk84hdpkt'
                    init={{
                        height: "80vh",
                        menubar: true,
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                        ],
                        toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        automatic_uploads: true,
                        images_upload_handler: uploadDetailImageAPI,
                        file_picker_types: 'image',
                        forced_root_block: 'div'
                    }}
                    onInit={(evt, editor) => {
                        editorRef.current = editor;
                        setIsTinymce(editor.initialized);
                    }}
                />
            </Modal>
        </div>
    )
}
