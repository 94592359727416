import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import attributeServices from '../../../services/AttributeService';
import { 
    getAttributeAction, 
    updateAttributeAction 
} from '../../../redux/actions/AttributeAction';

export default function EditAttribute() {
    const { id } = useParams();
    const { attribute, reloadAttribute } = useSelector(state => state.AttributeReducer)
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Tên không được để trống")
                .test("Tên", "Tên đã tồn tại", async (name) => {
                    let validationAttribute = {
                        id: attribute?.id,
                        name: name
                      }
                    let check = await attributeServices.validationAtribute(validationAttribute).then((result) => {
                        return result.data.content;
                    });
                    return !check;
                }),
        }),
        onSubmit: values => {
            let action = updateAttributeAction(attribute?.id, values);
            dispatch(action);
        },
    });

    const { touched, errors, handleChange, handleBlur, handleSubmit } = formik;

    useEffect(() => {
        getAttributeAPI();
    }, [id]);

    useEffect(() => {
        if (Object.keys(attribute).length) {
            form.setFieldsValue({
                name: attribute?.name,
            });
            formik.setValues(attribute);
        }
    }, [JSON.stringify(attribute)])

    useEffect(() => {
        if (reloadAttribute !== false) {
            navigate('/admin/attributes');
        }
        // eslint-disable-next-line
    }, [reloadAttribute]);

    const getAttributeAPI = () => {
        let action = getAttributeAction(id);
        dispatch(action);
    }

    return (
        <div>
            <h2 >Cập nhật thuộc tính</h2>
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} layout="horizontal"
                onFinish={handleSubmit}
                form={form}
            >
                <Form.Item label="Tên" name="name"
                    validateStatus={touched.name && errors.name ? "error" : "success"}
                    help={touched.name && errors.name ? (errors.name) : null}>
                    <Input onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Hành động">
                    <Button type="primary" htmlType="submit">Cập nhật</Button>
                </Form.Item>
            </Form>
        </div>
    );
};
