import { http } from "../util/config";

class FileServices {
    getFile = (id) => {
        return http.get(`/api/v1/files/${id}`);
    }

    getCustomFile = (filterValue) => {
        const { pageIndex, pageSize, keyword } = filterValue;
        return http.get(`/api/v1/files/paging?pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}`);
    }

    addFile = (data) => {
        return http.post(`/api/v1/files`, data);
    }

    updateFile = (id, data) => {
        return http.put(`/api/v1/files/${id}`, data);
    }

    deleteFile = (id) => {
        return http.delete(`/api/v1/files/${id}`);
    }
}

const fileServices = new FileServices();
export default fileServices;