import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Nav } from 'react-bootstrap';
import 'swiper/css';
import "swiper/css/navigation";
import { Navigation } from 'swiper';
import { IoIosArrowBack, IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { useEffect } from 'react';
import { useRef } from 'react';

export default function SimpleTabs({
    name,
    tabs,
    defaultActiveTab,
    pagination,
    onSelectTab = () => { },
    onChangePage = () => { },
}) {
    const [keyTab, setKeyTab] = useState(defaultActiveTab || 'pop');
    const simpleTabRef = useRef();
    const parentTabRef = useRef();
    const childTabRef = useRef();
    const pagingAct = Math.ceil((pagination?.pageIndex * pagination?.pageSize) / pagination?.pageSize);
    const totalPaging = Math.ceil(pagination?.totalRow / pagination?.pageSize);
    const childsTab = (tabs || []).filter((el) => (el?.childs || []).length > 0);
   
    useEffect(() => {
        const handleClickOutside = e => {
            if (!childTabRef.current?.contains(e.target) && !parentTabRef.current?.contains(e.target)) {
                showChildsTab(false);
            }
        };

        document.addEventListener("touchstart", handleClickOutside);
        document.addEventListener("mouseout", handleClickOutside);
        return () => {
            document.removeEventListener("mouseout", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        }
    }, []);

    const renderPaging = () => {
        return <div className='simple__tabs__paging'>
            <IoIosArrowBack onClick={() => {
                onChangePage({
                    name: name,
                    pageIndex: pagingAct > 1 ? pagingAct - 1 : 1
                });
            }} />
            <span>{pagingAct}/{totalPaging}</span>
            <IoIosArrowForward onClick={() => {
                onChangePage({
                    name: name,
                    pageIndex: pagingAct < totalPaging ? pagingAct + 1 : totalPaging
                });
            }} />
        </div>
    }

    const showChildsTab = (status) => {
        const childTab = childTabRef.current;
        if(childTab){
            childTab.style.opacity = status ? 1 : 0;
            childTab.style.visibility = status ? 'visible' : 'hidden';
        }
        return childTab;
    }

    const renderTab = (data) => {
        return (data || []).map((el) => {
            if ((el?.childs || []).length) {
                return (
                    <SwiperSlide key={`simple-tabs-${el?.eventKey}`}>
                        <Nav.Link className='select__price' ref={parentTabRef}
                            eventKey={el?.eventKey}
                            onMouseOver={() => {
                                // const parentTab = document.querySelector(`.simple__tabs.${name} .select__price`).getBoundingClientRect();
                                const simpleTab = simpleTabRef.current.getBoundingClientRect();
                                const parentTab = parentTabRef.current.getBoundingClientRect();
                                const childTab = showChildsTab(true);
                                const withChildTab = childTab.getBoundingClientRect().width;
                                const centerparentTab = parentTab.left - simpleTab.left + parentTab.width / 2;
                                childTab.style.top = `${parentTab.top - simpleTab.top + 50}px`;
                                childTab.style.left = `${centerparentTab > withChildTab / 2 ? centerparentTab - withChildTab / 2 : simpleTab.left}px`;
                            }}
                        >
                            <span>{el?.title}</span>
                            <IoIosArrowDown />
                        </Nav.Link>
                    </SwiperSlide>
                );
            }
            else {
                return (
                    <SwiperSlide key={`simple-tabs-${el?.eventKey}`} className='simple__item'>
                        <Nav.Link eventKey={el?.eventKey} onClick={() => {
                            setKeyTab(el?.eventKey);
                            onSelectTab({
                                name: name,
                                keyTab: el?.eventKey,
                            });
                        }}>
                            {el?.title}
                        </Nav.Link>
                    </SwiperSlide>
                );
            }
        });
    };

    return (
        <div className={`simple__tabs ${name}`} ref={simpleTabRef}>
            <Nav className="simple__tabs__wrapper" activeKey={keyTab}>
                <div className='simple__tabs__content'>
                    <Swiper
                        slidesPerView={"auto"}
                        slidesPerGroup={2}
                        spaceBetween={30}
                        breakpoints={{
                            480: {
                                slidesPerGroup: 3,
                            },
                            576: {
                                slidesPerGroup: 4,
                            },
                            768: {
                                slidesPerGroup: 5,
                            },
                            992: {
                                slidesPerGroup: 6,
                            },
                            1140: {
                                slidesPerGroup: 7,
                            },
                        }}
                        modules={[Navigation]}
                        navigation={true}
                        className='simple__swiper'
                    >
                        {renderTab(tabs)}
                    </Swiper>
                </div>
                {totalPaging && renderPaging()}
                {(childsTab || []).map((el) =>
                    <div className='child__tab' ref={childTabRef} key={`child-tab-${el?.eventKey}`}>
                        {(el?.childs || []).map((elc) => {
                            return <div className='child__tab__item' key={`child-tab-item-${elc?.eventKey}`}
                                onClick={() => {
                                    setKeyTab(el?.eventKey);
                                    showChildsTab(false);
                                    onSelectTab({
                                        name: name,
                                        keyTab: elc?.eventKey,
                                    });
                                    
                                }}>
                                {elc?.title}
                            </div>
                        })}
                    </div>
                )}
            </Nav>
        </div>
    );
};
