import React, { useEffect, useState } from 'react'
import { Fragment } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { Outlet, useLocation } from 'react-router-dom';
import { FileOutlined, UserOutlined, SettingOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { getAllWebInfoAction } from '../../redux/actions/WebInfosAction';
import { URL_API } from '../../util/setting';

const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    label,
    key,
    icon,
    children,
  };
};

const items = [
  getItem('Cấu hình trang web', 'grp1', <SettingOutlined />, [
    getItem('Thông tin', 'sub1', null, [
      getItem('Danh sách', '/admin/webinfos'),
      getItem('Thêm', '/admin/add-webinfo'),
    ]),
    getItem('Vị trí đặt', 'sub2', null, [
      getItem('Danh sách ', '/admin/placements'),
      getItem('Thêm ', '/admin/add-placement'),
    ]),
    getItem('Liên kết', 'sub3', null, [
      getItem('Danh sách', '/admin/global-links'),
      getItem('Thêm', '/admin/add-global-link'),
    ]),
  ]),
  getItem('Quản lý truyền thông', 'grp2', <FileOutlined />, [
    getItem('Truyền thông', 'sub4', null, [
      getItem('Danh sách', '/admin/medias'),
      getItem('Thêm', '/admin/add-media'),
    ]),
    getItem('Bài viết', 'sub5', null, [
      getItem('Danh sách', '/admin/posts'),
      getItem('Thêm', '/admin/add-post'),
    ]),
  ]),
  getItem('Quản lý người dùng', 'grp3', <UserOutlined />, [
    getItem('Danh sách', '/admin/users'),
    getItem('Thêm ', '/admin/add-user'),
  ]),
  getItem('Quản lý sản phẩm', 'grp4', <FileOutlined />, [
    getItem('Danh mục', 'sub6', null, [
      getItem('Danh sách', '/admin/categories'),
      getItem('Thêm ', '/admin/add-category'),
    ]),
    getItem('Nhà cung cấp', 'sub7', null, [
      getItem('Danh sách ', '/admin/brands'),
      getItem('Thêm ', '/admin/add-brand'),
    ]),
    getItem('Thuộc tính', 'sub8', null, [
      getItem('Danh sách ', '/admin/attributes'),
      getItem('Thêm ', '/admin/add-attribute'),
    ]),
    getItem('Khuyến mãi', 'sub9', null, [
      getItem('Danh sách ', '/admin/promotions'),
      getItem('Thêm ', '/admin/add-promotion'),
    ]),
    getItem('Sản phẩm', 'sub10', null, [
      getItem('Danh sách', '/admin/products'),
      getItem('Thêm', '/admin/add-product'),
    ]),
  ]),
  getItem('Quản lý dữ liệu', 'grp5', <FileOutlined />, [
    getItem('Tập tin', 'sub11', null, [
      getItem('Danh sách', '/admin/files'),
      getItem('Thêm', '/admin/add-file'),
    ]),
  ]),
];

export default function Admin() {
  const { user } = useSelector(state => state.AuthReducer);
  const { allWebInfo } = useSelector(state => state.WebInfoReducer);
  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState('/admin/webinfos');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = useLocation().pathname;
  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    getAllWebInfoAPI();
  }, [])

  useEffect(() => {
    setCurrent(url);
  }, [navigate])


  const getAllWebInfoAPI = () => {
    let action = getAllWebInfoAction();
    dispatch(action);
  }

  return <Fragment>
    <Layout style={{ minHeight: '100vh', }} className='admin__main'>
      <Sider width='fit-content' collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className='admin__brand'>
          <NavLink to="/home">
            <div className='website__logo'>
              <img src={`${URL_API}/api/v1/webinfos/viewfile/logo`} alt="logo" />
            </div>
            <div className='website__name'>{allWebInfo?.name}</div>
          </NavLink>
        </div>
        <Menu theme="dark"
          onClick={(link) => {
            navigate(link.key);
            // setCurrent(link.key);
          }}
          // onOpenChange={(first) => { 
          //   console.log(first)
          //  }}
          selectedKeys={[current]}
          mode="inline" items={items}
        />
      </Sider>
      <Layout className="site-layout">
        <Header className='admin__header'>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-info">
              {user?.displayName}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ lineHeight: '1.5rem', fontSize: '1.2rem' }}>
              <Dropdown.Item onClick={() => {
                navigate(`/admin/edit-user/${user.id}`);
                // setCurrent('/admin/users');
              }}>Cập nhập thông tin</Dropdown.Item>
              <Dropdown.Item onClick={() => {
                handleLogout();
                navigate('/admin');
              }}>Đăng xuất</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Header>
        <Content className='admin__content'>
          <Outlet />
        </Content>
        <Footer style={{ textAlign: 'center' }} >
          Copyright by Q
        </Footer>
      </Layout>
    </Layout>
  </Fragment>
}
