import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import { Form, Input, Button, Table } from 'antd';
import {
  getCustomBrandAction,
  reloadBrandAction,
  removeBrandAction,
} from '../../../redux/actions/BrandAction';
import { showDetail } from '../../../util/func';

export default function Brands() {
  const [searchName, setSearchName] = useState('');
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  let { customBrand, reloadBrand } = useSelector(state => state.BrandReducer);
  let { tableLoading } = useSelector(state => state.LoadingReducer);
  let dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getCustomBrandAPI();
    // eslint-disable-next-line
  }, [JSON.stringify(tableParams), searchName]);

  useEffect(() => {
    if (reloadBrand !== false) {
      getCustomBrandAPI();
      let action = reloadBrandAction();
      dispatch(action);
    }
    // eslint-disable-next-line
  }, [reloadBrand]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a?.id - b?.id,
    },
    {
      title: 'Tên',
      dataIndex: 'name',
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'image',
      width: '20%',
      render: (t, r) => <img style={{ maxWidth: '150px', maxHeight: '100px' }} src={`${r?.image?.url}`} alt={`${r?.name}`} />
    },
    {
      title: 'Mô tả',
      dataIndex: 'detail',
      render: (t, r) => <Button type="primary" onClick={() => showDetail(r?.detail)}>
        Xem chi tiết
      </Button>
    },
    {
      title: 'Hàng động',
      dataIndex: '',
      width: '10%',
      render: (t, r) => <div>
        <EditFilled style={{ fontSize: '16px', color: '#1677ff', marginRight: '10px' }} onClick={() => {
          navigate(`/admin/edit-brand/${r?.id}`);
        }} />
        <DeleteFilled style={{ fontSize: '16px', color: '#ff4d4f' }} onClick={() => {
          let action = removeBrandAction(r?.id);
          dispatch(action);
        }} />
      </div>,
    },
  ];

  const handleTableChange = (pagination, sorter) => {
    setTableParams({
      pagination,
      ...sorter,
    });
    // `dataSource` is useless since `pageSize` changed
    if (pagination?.pageSize !== tableParams.pagination?.pageSize) {
      customBrand.data = [];
    }
  };

  const validate = values => {
    setSearchName(values.name.trim());
  };

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validate,
    onSubmit: values => {
      setSearchName(values.name.trim());
    },
  });

  const { handleChange, handleSubmit } = formik;

  let getCustomBrandAPI = () => {
    let { current, pageSize } = tableParams.pagination;
    let filterValue = {
      pageIndex: current,
      pageSize: pageSize,
      keyword: searchName,
      order: 'ASC',
      sortBy: 'createdAt'
    }
    let action = getCustomBrandAction(filterValue);
    dispatch(action);
  }

  return (
    <div>
      <h2 >Quản lý nhà cung cấp</h2>
      <Button type="primary" style={{ marginBottom: '20px' }}
        onClick={() => {
          navigate(`/admin/add-brand`);
        }}>Thêm nhà cung cấp</Button>
      <Form layout="horizontal" onFinish={handleSubmit}>
        <Form.Item name="name" style={{ display: 'inline-block', width: '80%' }}>
          <Input onChange={handleChange} placeholder="Tìm kiếm theo tên nhà cung cấp" />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block', marginLeft: '10px' }}>
          <Button type="default" htmlType="submit">Tìm kiếm</Button>
        </Form.Item>
      </Form>
      <Table rowKey='id' columns={columns} dataSource={customBrand?.data}
        pagination={{
          current: customBrand?.pageIndex,
          pageSize: customBrand?.pageSize,
          total: customBrand?.totalRow
        }}
        loading={tableLoading}
        onChange={handleTableChange} />
    </div>
  );
};
