import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
// import parse from 'html-react-parser';
import {
    Carousel,
    Col,
    Container,
    Row
} from 'react-bootstrap';
import 'swiper/css';
import "swiper/css/navigation";
import { Navigation } from 'swiper';
import { getCustomAdverAction, getCustomBannerAction } from '../../redux/actions/MediaAction';
import { getCustomBrandAction } from '../../redux/actions/BrandAction';


export default function Banner() {
    let { customBrand } = useSelector(state => state.BrandReducer);
    let { customBanner, customAdver } = useSelector(state => state.MediaReducer);
    let dispatch = useDispatch();
    useEffect(() => {
        getCustomBrandAPI();
        getCustomBannerAPI();
        getCustomAdverAPI();
    }, []);

    let getCustomBrandAPI = () => {
        let filterValue = {
            pageIndex: 1,
            pageSize: 15,
            keyword: '',
            order: 'DESC',
            sortBy: 'viewed'
        }
        let action = getCustomBrandAction(filterValue);
        dispatch(action);
    }

    let getCustomBannerAPI = () => {
        let filterValue = {
            pageIndex: 1,
            placement: 'homeBanner',
            type: 'banner',
        }
        let action = getCustomBannerAction(filterValue);
        dispatch(action);
    }

    let getCustomAdverAPI = () => {
        let filterValue = {
            pageIndex: 1,
            placement: 'homeBanner',
            type: 'advertise',
        }
        let action = getCustomAdverAction(filterValue);
        dispatch(action);
    }

    const renderBanner = (data = []) => {
        return <Col className='banner__left'>
            <Carousel variant="light" slide={false}>
                {data?.map((el) => {
                    return <Carousel.Item key={`banner${el?.id}`}>
                        <div className='carousel-wapper'>
                            <img src={el?.image?.url}
                                alt={el?.title}
                            />
                        </div>
                        {/* <Carousel.Caption>
                    <h3>{parse(String(el?.title))}</h3>
                    <div className='caption__content'>{parse(String(el?.mediaContent))}</div>
                </Carousel.Caption> */}
                    </Carousel.Item>
                })}
            </Carousel>
        </Col>;
    };

    const renderAdvertise = (data = []) => {
        data = data?.filter((el, index) => index < 2);
        return <Col className='banner__right d-none d-md-flex flex-column' xs={4}>
            {data?.map((el) => {
                return <Link key={`card${el?.id}`} className='endow' to={el?.url}>
                    <div className="img__wapper">
                        <img src={el?.image?.url} alt={el?.title} />
                    </div>
                </Link>
            })}
        </Col>;
    };

    const renderProducer = (data = []) => {
        return <div className='producer'>
            <Swiper
                slidesPerView={3}
                slidesPerGroup={3}
                spaceBetween={10}
                navigation={true}
                breakpoints={{
                    480: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                    },
                    576: {
                        slidesPerView: 5,
                        slidesPerGroup: 5,
                    },
                    768: {
                        slidesPerView: 6,
                        slidesPerGroup: 6,
                    },
                    992: {
                        slidesPerView: 7,
                        slidesPerGroup: 7,
                    },
                    1140: {
                        slidesPerView: 8,
                        slidesPerGroup: 8,
                    },
                }}
                modules={[Navigation]}
                className='producer__swiper'
            >
                {data?.map((el) => {
                    return <SwiperSlide key={`producer-list${el?.id}`}>
                        <Link to={`/brands/${el?.slug}-bra.${el.id}`}>
                            <div className='producer__item'>
                                <img src={el?.image?.url} alt={el?.name} />
                            </div>
                        </Link>
                    </SwiperSlide>
                })}
            </Swiper>
        </div>
    }

    return (
        <div className='banner'>
            <Container fluid="lg">
                <Row>
                    {(customBanner?.data || []).length ? renderBanner(customBanner?.data) : ''}
                    {(customAdver?.data || []).length ? renderAdvertise(customAdver?.data): ''}
                </Row>
                {(customBrand?.data || []).length ? renderProducer(customBrand?.data) : ''}
            </Container>
        </div>
    )
}
