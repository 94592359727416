import React from 'react';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

export default function SubPage() {
    const { subPageUrl } = useParams();
    return (
        <div>
            <Container fluid='lg'>
                {subPageUrl}
            </Container>
        </div>
    )
}
