import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import { Form, Input, Button, Table } from 'antd';
import { 
  getCustomWebInfoAction, 
  reloadWebInfoAction, 
  removeWebInfoAction 
} from '../../../redux/actions/WebInfosAction';

export default function WebInfos() {
  const [searchName, setSearchName] = useState('');
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  let { customWebInfo, reloadWebInfo } = useSelector(state => state.WebInfoReducer);
  let { tableLoading } = useSelector(state => state.LoadingReducer);
  let dispatch = useDispatch();
  const navigate = useNavigate();



  useEffect(() => {
    getCustomWebInfoAPI();
    // eslint-disable-next-line
  }, [JSON.stringify(tableParams), searchName]);

  useEffect(() => {
    if (reloadWebInfo !== false) {
      getCustomWebInfoAPI();
      let action = reloadWebInfoAction();
      dispatch(action);
    }
    // eslint-disable-next-line
  }, [reloadWebInfo]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a?.id - b?.id,
    },
    {
      title: 'Danh mục',
      dataIndex: 'name',
    },
    {
      title: 'Khóa',
      dataIndex: 'key',
    },
    {
      title: 'Giá trị',
      dataIndex: 'value',
      render: (t, r) => {
        switch (r?.type) {
          case 'text':
            return r?.value;
          case 'file':
            return <img style={{ maxWidth: '150px', maxHeight: '100px' }} src={`${r?.value?.url}`} alt={`${r?.key}`} />
          case 'list':
            return Object.keys(r?.value).map((el) => <div key={`info${r?.key}-${el}`} >{el}: {r?.value?.[el]}</div>)
          default:
            return '';
        }
      }
    },
    {
      title: 'Loại dữ liệu',
      dataIndex: 'type',
    },
    {
      title: 'Hàng động',
      dataIndex: '',
      width: '10%',
      render: (t, r) => <div>
        <EditFilled style={{ fontSize: '16px', color: '#1677ff', marginRight: '10px' }} onClick={() => {
          navigate(`/admin/edit-webinfo/${r?.id}`);
        }} />
        <DeleteFilled style={{ fontSize: '16px', color: '#ff4d4f' }} onClick={() => {
          let action = removeWebInfoAction(r?.id);
          dispatch(action);
        }} />
      </div>,
    },
  ];

  const handleTableChange = (pagination, sorter) => {
    setTableParams({
      pagination,
      ...sorter,
    });
    // `dataSource` is useless since `pageSize` changed
    if (pagination?.pageSize !== tableParams.pagination?.pageSize) {
      customWebInfo.data = [];
    }
  };

  const validate = values => {
    setSearchName(values.name.trim());
  };

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validate,
    onSubmit: values => {
      setSearchName(values.name.trim());
    },
  });

  let getCustomWebInfoAPI = () => {
    let { current, pageSize } = tableParams.pagination;
    let filterValue = {
      pageIndex: current,
      pageSize: pageSize,
      keyword: searchName
    }
    let action = getCustomWebInfoAction(filterValue);
    dispatch(action);
  }

  const { handleChange, handleSubmit } = formik;

  return (
    <div>
      <h2>Thông tin trang web</h2>
      <Button type="primary" style={{ marginBottom: '20px' }} onClick={() => {
        navigate('/admin/add-webinfo');
      }}>Thêm thông tin</Button>
      <Form layout="horizontal" onFinish={handleSubmit}>
        <Form.Item name="name" style={{ display: 'inline-block', width: '80%' }}>
          <Input onChange={handleChange} placeholder="Tìm kiếm theo danh mục" />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block', marginLeft: '10px' }}>
          <Button type="default" htmlType="submit">Tìm kiếm</Button>
        </Form.Item>
      </Form>
      <Table rowKey='id' columns={columns} dataSource={customWebInfo?.data}
        pagination={{
          current: customWebInfo?.pageIndex,
          pageSize: customWebInfo?.pageSize,
          total: customWebInfo?.totalRow
        }}
        loading={tableLoading}
        onChange={handleTableChange}
      />
    </div>
  );
}
