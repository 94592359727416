import { http } from "../util/config";

class AttributeServices {
    getAtribute = (id) => {
        return http.get(`/api/v1/attributes/${id}`);
    }

    validationAtribute = (validationAtribute) => {
        const { id, name } = validationAtribute;
        return http.get(`/api/v1/attributes/validation?id=${id}&name=${name}`);
    }

    getAllAtribute = () => {
        return http.get(`/api/v1/attributes`);
    }

    getCustomAttribute = (filterValue) => {
        const { pageIndex, pageSize, keyword, order, sortBy } = filterValue;
        return http.get(`/api/v1/attributes/paging?pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}&order=${order}&sortBy=${sortBy}`);
    }

    addAttribute = (data) => {
        return http.post(`/api/v1/attributes`, data);
    }

    updateAttribute = (id, data) => {
        return http.put(`/api/v1/attributes/${id}`, data);
    }

    deleteAttribute = (id) => {
        return http.delete(`/api/v1/attributes/${id}`);
    }
}

const attributeServices = new AttributeServices();
export default attributeServices;
