import { 
    GET_GLOBAL_LINK, 
    GET_ALL_GLOBAL_LINK, 
    GET_CUSTOM_GLOBAL_LINK, 
    RELOAD_GLOBAL_LINK 
} from "../types/GlobalLinkType";

const initialState = {
    allGlobalLink: [],
    customGlobalLink: {},
    globalLink: {},
    reloadGlobalLink: false,
};

export const GlobalLinkReducer = (state = initialState, action) => { 
    switch (action.type) {
        case GET_ALL_GLOBAL_LINK:
            state.allGlobalLink = action.allGlobalLink;
            return {...state};
        case GET_CUSTOM_GLOBAL_LINK:
            state.customGlobalLink = action.customGlobalLink;
            return {...state};
        case GET_GLOBAL_LINK:
            state.globalLink = action.globalLink;
            return {...state};
        case RELOAD_GLOBAL_LINK:
            state.reloadGlobalLink = action.reloadGlobalLink;
            return {...state};
        default:
            return state;
    }
 };
