import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Nav } from 'react-bootstrap';
import 'swiper/css';
import "swiper/css/navigation";
import { Navigation } from 'swiper';
import { IoIosArrowBack, IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { Link } from 'react-router-dom';
import { removeAccents } from '../../util/func';

export default function DoubleTabs({
    name,
    topTabs,
    defaultActiveTopKey,
    bottomTabs,
    defaultActiveBottomKey,
    pagination,
    onSelectTab = () => { },
    onChangePage = () => { },
}) {
    const doubleTabBottomRef = useRef();
    const parentBottomTabRef = useRef();
    const childBottomTabRef = useRef();
    const [keyTopTab, setKeyTopTab] = useState(defaultActiveTopKey || 0); //mac dinh
    const [keyBottomTab, setKeyBottomTab] = useState(defaultActiveBottomKey || 'pop'); //mac dinh
    const objKeysTopTabs = Object.keys(topTabs ? topTabs : {});
    const pagingAct = Math.ceil((pagination?.pageIndex * pagination?.pageSize) / pagination?.pageSize);
    const totalPaging = Math.ceil(pagination?.totalRow / pagination?.pageSize);
    const childsTab = (bottomTabs || []).filter((el) => (el?.childs || []).length > 0);

    useEffect(() => {
        defaultActiveTopKey !== keyTopTab && setKeyTopTab(defaultActiveTopKey);
    }, [defaultActiveTopKey])

    // hide child tab bottom
    useEffect(() => {
        const handleClickOutside = e => {
            if (!childBottomTabRef.current?.contains(e.target) && !parentBottomTabRef.current?.contains(e.target)) {
                showChildsBottomTab(false);
            }
        };
        document.addEventListener("touchstart", handleClickOutside);
        document.addEventListener("mouseout", handleClickOutside);
        return () => {
            document.removeEventListener("mouseout", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        }
    }, []);

    const showChildsBottomTab = (status) => {
        const childTab = childBottomTabRef.current;
        if (childTab) {
            childTab.style.opacity = status ? 1 : 0;
            childTab.style.visibility = status ? 'visible' : 'hidden';
        }
        return childTab;
    }

    //render double tab
    const renderLeftTopTab = (data) => {
        return data?.map((el) => {
            return <Link key={`tabTopLeft-${el?.tabLeftName}`} to={`${el?.tabLeftUrl}/${removeAccents(el?.tabLeftName)}-${el?.tabLeftId}`}>{el?.tabLeftName}</Link>
        })
    }

    const renderRightTopTab = (data) => {
        return data?.map((el) => {
            const objKeyEl = Object.keys(el);
            return <SwiperSlide key={`tabTop-${el[objKeyEl[1]]}`}
                onClick={() => {
                    //get rightId right tab
                    setKeyTopTab(el[objKeyEl[0]]);
                    onSelectTab({
                        name: name,
                        keyTopTab: el[objKeyEl[0]],
                        keyBottomTab: keyBottomTab
                    });
                }}
            >
                <Nav.Link eventKey={`doubleTab-${el[objKeyEl[0]]}`}>
                    {el[objKeyEl[1]]}
                </Nav.Link>
            </SwiperSlide>
        })
    }

    const renderPaging = () => {
        return <div className='double__tabs__paging'>
            <IoIosArrowBack onClick={() => {
                onChangePage({
                    name: name,
                    pageIndex: pagingAct > 1 ? pagingAct - 1 : 1
                });
            }} />
            <span>{pagingAct}/{totalPaging}</span>
            <IoIosArrowForward onClick={() => {
                onChangePage({
                    name: name,
                    pageIndex: pagingAct < totalPaging ? pagingAct + 1 : totalPaging
                });
            }} />
        </div>
    }

    const renderbottomTab = (data) => {
        return (data || []).map((el) => {
            if ((el?.childs || []).length) {
                return (
                    <SwiperSlide key={`double-tabs-${el?.eventKey}`}>
                        <Nav.Link className='select__price' ref={parentBottomTabRef}
                            eventKey={el?.eventKey}
                            onMouseOver={() => {
                                // const doubleTab = document.querySelector(`.double__tabs.${name} > .bottom`).getBoundingClientRect();
                                // const parentTab = document.querySelector(`.double__tabs.${name} > .bottom .select__price`).getBoundingClientRect();
                                const doubleTabBottom = doubleTabBottomRef.current.getBoundingClientRect();
                                const parentTab = parentBottomTabRef.current.getBoundingClientRect();
                                const childsTab = showChildsBottomTab(true);
                                const withChildsTab = childsTab.getBoundingClientRect().width;
                                const centerparentTab = parentTab.left - doubleTabBottom.left + parentTab.width / 2;
                                childsTab.style.top = `${parentTab.top - doubleTabBottom.top + 50}px`;
                                childsTab.style.left = `${centerparentTab > withChildsTab / 2 ? centerparentTab - withChildsTab / 2 : doubleTabBottom.left}px`;
                            }}
                        >
                            <span>{el?.title}</span>
                            <IoIosArrowDown />
                        </Nav.Link>
                    </SwiperSlide>
                );
            }
            else {
                return (<SwiperSlide key={`double-tabs-${el?.eventKey}`} className='simple__item'>
                    <Nav.Link eventKey={el?.eventKey} onClick={() => {
                        setKeyBottomTab(el?.eventKey);
                        onSelectTab({
                            name: name,
                            keyTopTab: keyTopTab,
                            keyBottomTab: el?.eventKey,
                        });
                    }}>
                        {el?.title}
                    </Nav.Link>
                </SwiperSlide>
                );
            }
        });
    };

    return (
        <div className={`double__tabs ${name}`}>
            <Nav className="double__tabs__wrapper top" activeKey={`doubleTab-${keyTopTab}`}>
                <div className='double__tabs__title'>
                    {objKeysTopTabs[0] !== undefined ? renderLeftTopTab(topTabs[objKeysTopTabs[0]]) : ''}
                </div>
                <div className='double__tabs__content'>
                    <Swiper id='topswiper'
                        slidesPerView={"auto"}
                        slidesPerGroup={3}
                        spaceBetween={30}
                        breakpoints={{
                            480: {
                                slidesPerGroup: 4,
                            },
                            576: {
                                slidesPerGroup: 5,
                            },
                            768: {
                                slidesPerGroup: 6,
                            },
                            992: {
                                slidesPerGroup: 7,
                            },
                            1140: {
                                slidesPerGroup: 8,
                            },
                        }}
                        modules={[Navigation]}
                        navigation={true}
                        className='top__swiper'
                    >
                        {objKeysTopTabs[1] !== undefined ? renderRightTopTab(topTabs[objKeysTopTabs[1]]) : ''}
                    </Swiper>
                </div>
            </Nav>
            <Nav className="double__tabs__wrapper bottom" activeKey={keyBottomTab} ref={doubleTabBottomRef}>
                <div className='double__tabs__content'>
                    <Swiper
                        slidesPerView={"auto"}
                        slidesPerGroup={2}
                        spaceBetween={30}
                        breakpoints={{
                            480: {
                                slidesPerGroup: 3,
                            },
                            576: {
                                slidesPerGroup: 4,
                            },
                            768: {
                                slidesPerGroup: 5,
                            },
                            992: {
                                slidesPerGroup: 6,
                            },
                            1140: {
                                slidesPerGroup: 7,
                            },
                        }}
                        modules={[Navigation]}
                        navigation={true}
                        className='bottom__swiper'
                    >
                        {renderbottomTab(bottomTabs)}
                    </Swiper>
                </div>
                {totalPaging ? renderPaging() : ''}
                {/* childs bottom tab */}
                {(childsTab || []).map((el) =>
                    <div className='child__tab__bottom' ref={childBottomTabRef} key={`child-tab-bottom-${el?.eventKey}`}>
                        {el?.childs.map((elc) => {
                            return <div className='child__tab__item' key={`child-tab-item-${elc?.eventKey}`}
                                onClick={() => {
                                    setKeyBottomTab(el?.eventKey)
                                    showChildsBottomTab(false);
                                    onSelectTab({
                                        name: name,
                                        keyTopTab: keyTopTab,
                                        keyBottomTab: elc?.eventKey,
                                    });
                                }}>
                                {elc?.title}
                            </div>
                        })}
                    </div>
                )}

            </Nav>
        </div>
    )
}
