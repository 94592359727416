import { http } from "../util/config";

class PromotionServices {
    getPromotion = (id) => {
        return http.get(`/api/v1/promotions/${id}`);
    }

    validationPromotion = (validationPromotion) => {
        const { id, code } = validationPromotion;
        return http.get(`/api/v1/promotions/validation?id=${id}&code=${code}`);
    }

    getAllPromotion = () => {
        return http.get(`/api/v1/promotions`);
    }

    getCustomPromotion = (filterValue) => {
        const { pageIndex, pageSize, keyword } = filterValue;
        return http.get(`/api/v1/promotions/paging?pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}`);
    }

    addPromotion = (data) => {
        return http.post(`/api/v1/promotions`, data);
    }

    updatePromotion = (id, data) => {
        return http.put(`/api/v1/promotions/${id}`, data);
    }

    deletePromotion = (id) => {
        return http.delete(`/api/v1/promotions/${id}`);
    }
}

const promotionServices = new PromotionServices();
export default promotionServices;