import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Upload, Form, Input, Button, Select, Space } from 'antd';
import webInfosServices from '../../../services/WebInfosService';
import { addWebInfoAction } from '../../../redux/actions/WebInfosAction';


const { TextArea } = Input;

export default function AddWebInfo() {
    const { reloadWebInfo } = useSelector(state => state.WebInfoReducer);
    const [form] = Form.useForm();
    let dispatch = useDispatch();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: '',
            key: '',
            type: 'text',
            value: '',
            file: {},
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Tên không được để trống"),
            type: Yup.string().required("Bạn chưa chọn loại dữ liệu"),
            key: Yup.string().required("Khóa chính không được để trống")
                .test("khóa chính", "Khóa chính đã tồn tại", async (values) => {
                    let check = await webInfosServices.validationWebInfo({ key: values }).then((result) => {
                        return result.data.content;
                    });
                    return !check;
                }),
        }),
        onSubmit: values => {
            if (values.type === 'list') {
                if (form.getFieldValue('value')?.length > 0) {
                    // let obj = {}
                    // for (const item of form.getFieldValue('infoValue')) {
                    //     obj = { ...obj, [item?.key]: item?.value }
                    // }
                    // values.infoValue = obj;
                    values.value = form.getFieldValue('value').reduce((obj, item) => ({ ...obj, [item?.key]: item?.value }), {})
                }

            }
            let action = addWebInfoAction(values);
            dispatch(action);
        },
    });

    const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

    useEffect(() => {
        if (reloadWebInfo !== false) {
            navigate('/admin/webinfos');
        }
        // eslint-disable-next-line
    }, [reloadWebInfo]);

    //upload image
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const updateFileAPI = (file) => {
        setFieldValue('file', {
            uid: file?.uid,
            name: file?.name,
            originFileObj: file,
            status: 'done',
        })
        return false;
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    const renderFile = () => {
        return <Form.Item label="File" valuePropName="fileList">
            <Upload
                customRequest={dummyRequest}
                listType="picture-card"
                fileList={
                    Object.keys(values?.file || {})?.length > 0 ?
                        [values?.file] : []
                }
                onRemove={() => setFieldValue('file', {})}
                beforeUpload={updateFileAPI}
                onPreview={onPreview}
            >
                {Object.keys(values?.file || {})?.length > 0 ? null : uploadButton}
            </Upload>
        </Form.Item>
    }

    const renderText = () => {
        return <Form.Item label="Giá trị" name="value">
            <TextArea onChange={handleChange} onBlur={handleBlur} />
        </Form.Item>
    }

    const renderList = () => {
        return <Form.Item label="Đối tượng">
            <Form.List name="value">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <Space key={key} style={{ display: 'flex' }} align="baseline">
                                <Form.Item
                                    {...restField}
                                    name={[name, 'key']}
                                    rules={[{ required: true, message: 'Chưa có khóa chính' }]}
                                >
                                    <Input placeholder="Khóa chính" />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'value']}
                                    rules={[{ required: true, message: 'Chưa có giá trị' }]}
                                >
                                    <Input placeholder="Giá trị" />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                            </Space>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Thêm thuộc tính
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Form.Item>
    }

    return (
        <div>
            <h2>Thêm thông tin trang web</h2>
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }}
                layout="horizontal"
                onFinish={handleSubmit}
                form={form}
                initialValues={values}
            >
                <Form.Item label="Tên" name="name"
                    validateStatus={touched.name && errors.name ? "error" : "success"}
                    help={touched.name && errors.name ? (errors.name) : null}>
                    <Input onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Khóa chính" name="key"
                    validateStatus={touched.key && errors.key ? "error" : "success"}
                    help={touched.key && errors.key ? (errors.key) : null}>
                    <Input onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Loại thông tin" name="type"
                    help={touched.type && errors.type ? errors.type : null}
                    validateStatus={touched.type && errors.type ? "error" : "success"}
                >
                    <Select
                        onChange={(value) => {
                            form.setFieldValue('value', '');
                            setFieldValue('value', '');
                            setFieldValue('file', {});
                            setFieldValue('type', value);
                        }}
                        options={[
                            { label: 'Text', value: 'text' },
                            { label: 'File', value: 'file' },
                            { label: 'List', value: 'list' }
                        ]}
                    />
                </Form.Item>
                {
                    {
                        text: renderText(),
                        file: renderFile(),
                        list: renderList()
                    }[values.type]
                }
                <Form.Item label="Hành động">
                    <Button type='primary' htmlType="submit">Thêm</Button>
                </Form.Item>
            </Form>
        </div>
    )
}
