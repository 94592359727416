import {
    GET_CUSTOM_PRODUCT,
    GET_PRODUCT,
    GET_PRODUCT_BY_PARENT,
    CREATED_FILTER_PRODUCTS,
    RELOAD_PRODUCT,
    SEARCH_PRODUCT,
} from "../types/ProductType";

const initialState = {
    customProduct: {},
    productsByParent: {},
    searchProduct: [],
    filterProducts: [],
    product: {},
    reloadProduct: false,
}

export const ProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT:
            state.product = action.product;
            return { ...state };
        case SEARCH_PRODUCT:
            state.searchProduct = action.searchProduct;
            return { ...state };
        case GET_CUSTOM_PRODUCT:
            state.customProduct = action.customProduct;
            return { ...state };
        case GET_PRODUCT_BY_PARENT:
            state.productsByParent = action.productsByParent;
            return { ...state };
        case CREATED_FILTER_PRODUCTS:
            let iFilter = state.filterProducts.findIndex((el) => el.name === action.filterProduct.name);
            if (iFilter > -1) {
                state.filterProducts[iFilter] = action.filterProduct;
            }
            else {
                state.filterProducts.push(action.filterProduct); 
            }
            return { ...state };
        case RELOAD_PRODUCT:
            state.reloadProduct = action.reloadProduct;
            return { ...state };
        default:
            return state;
    }
}