import { 
    GET_CUSTOM_POST, 
    GET_POST, 
    RELOAD_POST 
} from "../types/PostType";

const initialState = {
    customPost: {},
    post: {},
    reloadPost: false,
};

export const PostReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CUSTOM_POST:
            state.customPost = action.customPost;
            return { ...state };
        case GET_POST:
            state.post = action.post;
            return { ...state };
        case RELOAD_POST:
            state.reloadPost = action.reloadPost;
            return { ...state };
        default:
            return state;
    }
};