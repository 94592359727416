import { TABLE_LOADING } from "../types/LoadingType"

export const tableLoadingAction = (tableLoading) => {
    return (dispatch2) => {
        let action2 = {
            type: TABLE_LOADING,
            tableLoading: tableLoading,
        };
        dispatch2(action2);
    };
};