import { http } from "../util/config";

class MediaServices {
    getMedia = (id) => {
        return http.get(`/api/v1/medias/${id}`);
    }

    validationMedia = (validationMedia) => {
        const { id, title } = validationMedia;
        return http.get(`api/v1/medias/validation?id=${id}&title=${title}`);
    }

    getCustomMedia = (filterValue) => {
        const { pageIndex, pageSize, placement, type } = filterValue;
        return http.get(`/api/v1/medias/paging?pageIndex=${pageIndex}&pageSize=${pageSize}&placement=${placement}&type=${type}`);
    }

    addMedia = (data) => {
        return http.post(`/api/v1/medias`, data);
    }

    updateMedia = (id, data) => {
        return http.put(`/api/v1/medias/${id}`, data);
    }

    deleteMedia = (id) => {
        return http.delete(`/api/v1/medias/${id}`);
    }
}

const mediaServices = new MediaServices();
export default mediaServices;