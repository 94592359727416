import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Modal from 'react-bootstrap/Modal';
import {
    FaSearch,
    FaMapMarkerAlt,
    FaEnvelope,
    FaRegBell,
    FaRegQuestionCircle,
    FaUserCircle,
    FaUserEdit,
    FaBars,
    FaHome,
    FaUser,
    FaAddressCard,
    FaAward,
    FaArrowLeft,
    FaAngleDoubleRight,
    FaAngleDoubleLeft,
} from "react-icons/fa";
import { SiZalo } from "react-icons/si";
import { RiFacebookFill } from "react-icons/ri";
import { CgShoppingCart } from "react-icons/cg";
import { IoIosArrowForward } from "react-icons/io";
import { removeAccents } from '../../util/func';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { useFormik } from 'formik';
import parse from 'html-react-parser';
import { getAllCategoryAction } from '../../redux/actions/CategoryAction';
import { searchProductAction } from '../../redux/actions/ProductAction';
import { getAllWebInfoAction } from '../../redux/actions/WebInfosAction';
import { getAllGlobalLinkAction } from '../../redux/actions/GlobalLinkAction';

export default function Header() {
    const [searchWord, setSearchWord] = useState('');
    const [showSearchResult, setShowSearchResult] = useState(false);
    const [categoryId, setCategoryId] = useState(0);
    const [showMap, setShowMap] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showMbMenuBrand, setshowMbMenuBrand] = useState(false);
    const [showDeskMenu, setShowDeskMenu] = useState(false);
    const [showRightBar, setShowRightBar] = useState(false);
    const [scrollHeader, setScrollHeader] = useState(false);
    const outsideRef = useRef();
    const navigate = useNavigate();
    let dispatch = useDispatch();
    let { searchProduct } = useSelector(state => state.ProductReducer);
    let { allCategory } = useSelector(state => state.CategoryReducer);
    let { allWebInfo } = useSelector(state => state.WebInfoReducer);
    let { allGlobalLink } = useSelector(state => state.GlobalLinkReducer);

    const validate = values => {
        setSearchWord(values.searchWord.trim());
    };

    const formik = useFormik({
        initialValues: {
            searchWord: '',
        },
        validate,
        onSubmit: values => {
            setSearchWord(values.searchWord.trim());
        },
    });
    const { handleChange, handleSubmit } = formik;

    const handleCloseDeCategory = () => setShowDeskMenu(false);
    const handleShowDeCategory = () => setShowDeskMenu(true);

    const handleCloseMbCategory = () => setShowMobileMenu(false);
    const handleShowMbCategory = () => setShowMobileMenu(true);

    const handleCloseMbMenuBrand = () => setshowMbMenuBrand(false);
    const handleShowMbMenuBrand = () => setshowMbMenuBrand(true);

    const handleCloseMobileMenu = () => {
        setshowMbMenuBrand(false);
        setShowMobileMenu(false);
    }

    // right menu 
    const toggleShowRightBar = () => setShowRightBar((showRightBar) => !showRightBar);


    // google map
    const defaultProps = {
        center: {
            lat: 10.8793892,
            lng: 106.7607672
        },
        zoom: 14
    };
    const AnyReactComponent = ({ emblem }) => <div style={{ color: 'red', fontSize: '1rem' }}><FaMapMarkerAlt />{emblem}</div>;

    useEffect(() => {
        const handleClickOutside = e => {
            if (!outsideRef.current.contains(e.target)) {
                handleCloseDeCategory();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            setScrollHeader(window.scrollY > 20);
        }
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        getAllCategoryAPI();
        getAllWebInfoAPI();
        getAllExternalLinkAPI();
    }, []);


    useEffect(() => {
        if (allWebInfo?.logo?.url) {
            document.querySelector("link[rel*='icon']").href = allWebInfo?.logo?.url;
            document.querySelector("link[rel*='apple-touch-icon']").href = allWebInfo?.logo?.url;
        }
    }, [allWebInfo?.logo?.url]);



    useEffect(() => {
        searchProductAPI();
    }, [searchWord]);

    const searchProductAPI = () => {
        let action = searchProductAction(searchWord);
        dispatch(action);
    }

    const getAllWebInfoAPI = () => {
        let action = getAllWebInfoAction();
        dispatch(action);
    }

    const getAllExternalLinkAPI = () => {
        let action = getAllGlobalLinkAction();
        dispatch(action);
    }

    let getAllCategoryAPI = () => {
        let action = getAllCategoryAction();
        dispatch(action);
    }

    const renderCategoriesMenu = (data) => {
        return <Nav className='menu__product__content' navbarScroll>
            {
                data?.map((el) => {
                    return <div key={`categories-menu${el?.id}`} className='menu__item'>
                        <div className='title__list'>
                            <NavLink to={`/categories/${el?.slug}-cat.${el?.id}`} state={{ id: el?.id }} onClick={handleCloseDeCategory}>{el?.name}</NavLink>
                            <IoIosArrowForward />
                        </div>
                        <Nav className='one__list' navbarScroll>
                            {
                                el?.brands?.map((elc) => {
                                    return <div key={`brands-menu${elc?.id}`} className='one__list__item'>
                                        <NavLink to={`/brands/${elc?.slug}-bra.${elc?.id}.${el?.id}`} onClick={handleCloseDeCategory}>{elc?.name}</NavLink>
                                    </div>
                                })
                            }
                        </Nav>
                    </div>
                })
            }
        </Nav>
    };

    const renderMainMenu = (data) => {
        return data?.map((el) => {
            return <NavLink key={`main-menu${el?.id}`} to={removeAccents(el?.href)}>{el?.text}</NavLink>
        })
    };

    const renderCategoriesMenuMobile = (data) => {
        return data?.map((el, index) => {
            return <div key={`category-menu-mobile${el.id}`} className='menu__item'
                onClick={() => {
                    setCategoryId(index);
                    handleShowMbMenuBrand();
                }}>
                <NavLink to="#" >{el.name}</NavLink>
                <IoIosArrowForward />
            </div>
        });
    };

    const renderBrandsMenuMobile = (data) => {
        return data[categoryId]?.brands.map((el) => {
            return <div key={`brand-menu-mobile${el.id}`} className='menu__item'>
                <NavLink to={`/brands/${el?.slug}-bra.${el.id}`} onClick={handleCloseMobileMenu}>{el.name}</NavLink>
            </div>
        })
    };

    const renderSearchResult = (data) => {
        return <div className='search__result'>
            <div className="search__result__title">
                {searchWord ? `Tìm sản phẩm "${parse(String(searchWord))}"` : "Vui lòng nhập tên sản phẩm"}
            </div>
            {data?.map((el, index) => {
                return <Link key={`search-result${index}`} className='search__result__entry' to={`/products/${el?.slug}-pro.${el?.id}`}>{el?.name}</Link>
            })}
        </div>
    };

    return (
        <header>
            <div className={scrollHeader ? 'header__wrap nav__fixed' : 'header__wrap'}>
                <Container fluid="xl" className='header__top py-0'>
                    <Navbar className='navbar__top py-0' variant="dark" expand="md">
                        <Navbar.Collapse className="navbar__top__content justify-content-between">
                            <Nav className='navbar__top__left'>
                                <Link className='vertical' to="#" onClick={() => setShowMap(true)}><FaMapMarkerAlt />{`${allWebInfo?.location?.shortAddress}`}</Link>
                                <Link className='vertical' to={`mailto:${allWebInfo?.email}`}><FaEnvelope /> {`${allWebInfo?.email}`}</Link>
                                <Link className='vertical hotline' to={`tel:${allWebInfo?.hotline}`}>Hotline: {`${allWebInfo?.hotline}`}</Link>
                            </Nav>
                            <Nav className='navbar__top__right'>
                                <Link to="#"><FaRegBell /> Thông báo</Link>
                                <Link to="/admin"><FaRegQuestionCircle /> Hỗ trợ</Link>
                                <Link to="/auth"><FaUserCircle /> Đăng nhập</Link>
                                <Link className='vertical' to="#"><FaUserEdit /> Đăng ký</Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
                <Container fluid="xl" className='header__main'>
                    <Navbar className='navbar__main' variant="dark" expand="md" >
                        <div className='navbar__search'>
                            <Link to="/" className='navbar__brand d-none d-md-flex '>
                                <div className='website__logo'><img src={allWebInfo?.logo?.url} alt="logo" /></div>
                                <div className='website__name'>{parse(String(allWebInfo?.name))}</div>
                            </Link>
                            <div className='my-2 my-md-0 d-flex justify-content-around w-100'>
                                <Button className='button__menu d-md-none' onClick={handleShowMbCategory}>
                                    <FaBars />
                                </Button>
                                <div className='search__wrap'
                                    onBlur={(e) => {
                                        if (e.relatedTarget === null) {
                                            setShowSearchResult(false);
                                        }
                                        else {
                                            const url = e.relatedTarget?.getAttribute("href");
                                            if (url !== null) {
                                                navigate(e.relatedTarget?.getAttribute("href"));
                                                setShowSearchResult(false);
                                            }
                                        }
                                    }}
                                >
                                    <Form.Control className='input__search'
                                        name='searchWord'
                                        onChange={handleChange}
                                        onFocus={() => setShowSearchResult(true)}
                                        type="text"
                                        placeholder="Tìm kiếm theo tên sản phẩm"
                                        aria-label="Search product"
                                    />
                                    <div className="button__search__wrapper">
                                        <Button className='button__search' onClick={handleSubmit}><FaSearch /></Button>
                                    </div>
                                    <div className="search__result__wrapper">
                                        {showSearchResult ? renderSearchResult(searchProduct) : ''}
                                    </div>

                                </div>
                                <Button className='button__cart'><CgShoppingCart /></Button>
                            </div>
                        </div>
                        <div className='navbar__menu d-none d-md-flex'>
                            <div className='menu__product' ref={outsideRef}>
                                <div className='menu__product__title' onClick={showDeskMenu ? handleCloseDeCategory : handleShowDeCategory}>
                                    <FaBars />
                                    <span>DANH MỤC</span>
                                </div>
                                {showDeskMenu ? renderCategoriesMenu(allCategory) : ''}
                            </div>
                            <div className='menu__home d-flex'>
                                {renderMainMenu((allGlobalLink || [])?.filter((el) => (el?.placements || []).map(elP => elP?.key).includes('homeMenu')))}
                            </div>
                        </div>
                    </Navbar>
                </Container>
            </div>

            <div className='header__bottom d-block d-md-none fixed-bottom'>
                <div className='navbar__bottom'>
                    <Nav className='link__wrapper d-flex justify-content-around w-100'>
                        <NavLink to="/">
                            <FaHome />
                            <span>Home</span>
                        </NavLink>
                        <NavLink to="/about">
                            <FaAddressCard />
                            <span>Liên hệ</span>
                        </NavLink>
                        <NavLink to="/products" state={{ activeTab: 'hot' }}>
                            <FaAward />
                            <span>Bán chạy</span>
                        </NavLink>
                        <NavLink to="/notify">
                            <FaRegBell />
                            <span>Thông báo</span>
                        </NavLink>
                        <NavLink to="/profile">
                            <FaUser />
                            <span>Tôi</span>
                        </NavLink>
                    </Nav>
                </div>
            </div>

            <div className='pull__right__bar' onClick={toggleShowRightBar}>
                <span className='toggle-right-sidebar'> {showRightBar ? <FaAngleDoubleLeft /> : <FaAngleDoubleRight />}</span>
            </div>
            <div className={showRightBar ? 'right__sidebar hide_right_bar' : 'right__sidebar'}>
                <div className='right__sidebar__content'>
                    <div className='right__sidebar__wrap'>
                        <div className='right__sidebar__item'>
                            <span className='right__sidebar__icon zalo'><SiZalo /></span>
                        </div>
                        <div className='right__sidebar__item'>
                            <span className='right__sidebar__icon facebook'><RiFacebookFill /></span>
                        </div>
                    </div>
                </div>
            </div>

            <Offcanvas className='mobile__menu'
                show={showMobileMenu}
                onHide={handleCloseMbCategory}
                scroll={true}
            >
                <Offcanvas.Header>
                    <FaArrowLeft onClick={handleCloseMbCategory} />
                    <Offcanvas.Title>Danh mục</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='menu__product'>
                    <div className='menu__product__content'>
                        {renderCategoriesMenuMobile(allCategory)}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            <Offcanvas className='mobile__menu'
                show={showMbMenuBrand}
                onHide={handleCloseMbMenuBrand}
                scroll={true}
            >
                <Offcanvas.Header>
                    <FaArrowLeft onClick={handleCloseMbMenuBrand} />
                    <Offcanvas.Title>
                        {allCategory[categoryId]?.name}
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='menu__product'>
                    <div className='menu__product__content'>
                        {renderBrandsMenuMobile(allCategory)}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

            {/* show map */}
            <Modal
                size="lg"
                show={showMap}
                onHide={() => setShowMap(false)}
                aria-labelledby="modal-GoogleMap"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="modal-GoogleMap">
                        Vị trí của chúng tôi
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: '70vh', width: '100%', borderRadius: '40px' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: `${allWebInfo?.location?.key || ''}` }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                        >
                            <AnyReactComponent
                                lat={allWebInfo?.location?.lat}
                                lng={allWebInfo?.location?.lng}
                                emblem={allWebInfo?.location?.emblem}
                            />
                        </GoogleMapReact>
                    </div>
                </Modal.Body>
            </Modal>
        </header>
    );
}
