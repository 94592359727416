import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import Nav from 'react-bootstrap/Nav';
import { FaStar } from "react-icons/fa";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import 'swiper/css';
import "swiper/css/navigation";
import { Navigation } from 'swiper';

export default function FilterProducts({
    tabValues,
    defaultActiveKey,
    content = [],
    selectedTab = () => { },
}) {
    const { name, tabs, allHref } = tabValues;
    const [keyTab, setKeyTab] = useState(defaultActiveKey || tabs[0]?.eventKey);

    const renderTab = (data) => {
        return data?.map((el) => {
            return <Nav.Item key={`simple-tab-${el.eventKey}`}>
                <Nav.Link eventKey={el?.eventKey}
                    onClick={() => {
                        setKeyTab(el?.eventKey);
                        selectedTab({ name: name, activeKey: el?.eventKey })
                    }}>
                    {el?.title}
                </Nav.Link>
            </Nav.Item>
        })
    }

    const renderProduct = (data) => {
        return <Swiper
            slidesPerView={1}
            slidesPerGroup={1}
            spaceBetween={0}
            navigation={true}
            breakpoints={{
                480: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,

                },
                576: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                768: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
                992: {
                    slidesPerView: 5,
                    slidesPerGroup: 5,
                },
                1140: {
                    slidesPerView: 6,
                    slidesPerGroup: 6,

                },
            }}
            modules={[Navigation]}
            className='selling__swiper'
        >
            {data?.map((el) => {
                return <SwiperSlide key={`product${el?.name}`}>
                    <div className='product__item'>
                        <Link to={`/products/${el?.slug}-pro.${el?.id}`}>
                            <div className='product__image'>
                                <img
                                    src={el?.images?.length ? el?.images[0]?.url : ''}
                                    alt={el?.name}
                                    loading="lazy" />
                                <div className="swiper-lazy-preloader"></div>
                            </div>
                            <div className='product__content'>
                                <h4 className='product__name'>{el?.name}</h4>
                                <div className='product__price'>{el?.contact ? "Liên hệ" : `${new Intl.NumberFormat().format(el?.price)}₫`}</div>
                                <div className='product__rate'><b>{el?.voted?.toFixed(1)}</b><FaStar /></div>
                            </div>
                        </Link>
                    </div>
                </SwiperSlide>
            })}
        </Swiper>
    }

    return (
        <div className='selling'>
            <Nav className="section__tab__header" activeKey={keyTab}>
                {renderTab(tabs)}
                <div className='viewall'>
                    <Link to={`${allHref}`} state={{ activeTab: keyTab }} className="nav-link"><span>Xem tất cả</span><IoIosArrowDroprightCircle /></Link>
                </div>
            </Nav>
            <div className='section__tab__content'>
                <div>
                    {renderProduct(content || [])}
                </div>
            </div>
        </div>
    )
}
