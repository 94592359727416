import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import DoubleTabs from '../../components/DoubleTabs/DoubleTabs';
import { Link, NavLink, useParams } from 'react-router-dom';
import { FaStar } from "react-icons/fa";
import { IoMdArrowDropright } from "react-icons/io";
import Pagination from '../../components/Pagination/Pagination';
import { extractUrl } from '../../util/func';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import {
  getCategoriesByBrandAction,
  getCustomBrandAction
} from '../../redux/actions/BrandAction';
import { getSubcategoriesAction } from '../../redux/actions/CategoryAction';
import { getProductsByParentAction } from '../../redux/actions/ProductAction';
import brandServices from '../../services/BrandService';

// set name components
const nameSub = 'subBrand';
const namePro = 'proBrand';

//set value filter
const bottomTabsSub = [
  {
    eventKey: 'pop',
    title: 'Phổ biến',
    filterValue: { order: 'DESC', sortBy: 'viewed', old: 0, detail: false }
  },
  {
    eventKey: 'new',
    title: 'Mới nhất',
    filterValue: { order: 'DESC', sortBy: 'createdAt', old: 0, detail: false }
  },
  {
    eventKey: 'detail',
    title: 'Thông tin chi tiết',
    filterValue: { order: 'DESC', sortBy: 'viewed', old: 0, detail: true }
  },
];

const bottomTabsPro = [
  {
    eventKey: 'pop',
    title: 'Phổ biến',
    filterValue: { order: 'DESC', sortBy: 'viewed', old: 0, detail: false }
  },
  {
    eventKey: 'new',
    title: 'Mới nhất',
    filterValue: { order: 'DESC', sortBy: 'createdAt', old: 0, detail: false }
  },
  {
    eventKey: 'hot',
    title: 'Bán chạy',
    filterValue: { order: 'DESC', sortBy: 'sold', old: 0, detail: false }
  },
  {
    eventKey: 'price',
    title: 'Giá',
    filterValue: { order: 'ASC', sortBy: 'price', old: 0 },
    childs: [
      {
        eventKey: 'cheap',
        title: 'Giá: Thấp đến Cao',
        filterValue: { order: 'ASC', sortBy: 'price', old: 0, detail: false }
      },
      {
        eventKey: 'expensive',
        title: 'Giá: Cao đến Thấp',
        filterValue: { order: 'DESC', sortBy: 'price', old: 0, detail: false }
      },
    ]
  },
  {
    eventKey: 'old',
    title: 'Đồ cũ',
    filterValue: { order: 'DESC', sortBy: 'viewed', old: 1, detail: false }
  },
  {
    eventKey: 'detail',
    title: 'Thông tin chi tiết',
    filterValue: { order: 'DESC', sortBy: 'viewed', old: 0, detail: true }
  },
];

const tabsConvert = bottomTabsSub.flatMap(element => element.childs ? [element, ...element.childs] : [element]);

export default function Brands() {
  const { brandUrl } = useParams();
  const [keyListRelated, setKeyListRelated] = useState('');
  const [pageIndexSub, setPageIndexSub] = useState(1);
  const [pageIndexPro, setPageIndexPro] = useState(1);
  const [activeTopTab, setActiveTopTab] = useState(0);
  const [activeBottomTabSub, setActiveBottomTabSub] = useState('pop');
  const [activeBottomTabPro, setActiveBottomTabPro] = useState('pop');
  const { customBrand, brand } = useSelector(state => state.BrandReducer);
  const { subcategories } = useSelector(state => state.CategoryReducer);
  const { productsByParent } = useSelector(state => state.ProductReducer);
  const dispatch = useDispatch();
  const id = extractUrl(brandUrl, '-bra.')[0];
  const categoryIdParent = extractUrl(brandUrl, '-bra.')[1] || 0;
  const categoryId = extractUrl(brandUrl, '-bra.')[2] || 0;

  const topTabs = {
    "tabLeft": [
      {
        "tabLeftUrl": "/brands",
        "tabLeftId": `bra.${brand?.id}`,
        "tabLeftName": brand?.name,
      },
    ],
    "tabRight": brand?.categories?.map((el) => {
      return {
        "tabRightId": el?.id,
        "tabRightName": el?.name,
      }
    }),
  };

  useEffect(() => {
    getCustomBrandAPI();
  }, []);

  useEffect(() => {
    id && getCategoriesByBrandAPI();
  }, [id, categoryIdParent]);

  //get result by double tab, brand, pagingation
  useEffect(() => {
    const updateViewsBrand = async (id) => {
      await brandServices.updateViewsBrand(id);
    }
    if (brand?.id) {
      document.title = brand?.name;
      setKeyListRelated(brand?.name);
      setActiveTopTab(categoryId || ((brand?.categories || []).length && brand?.categories[0].id))
      setActiveBottomTabSub('pop');
      setActiveBottomTabPro('pop');
      updateViewsBrand(brand?.id);
    }
  }, [JSON.stringify(brand)]);

  //get result by double tabs
  useEffect(() => {
    if (activeTopTab) {
      getSubcategoriesAPI();
      getProductsByParentAPI();
    }
  }, [brand?.id, activeTopTab, pageIndexSub, pageIndexPro, activeBottomTabSub, activeBottomTabPro]);

  //fuction 
  const getCustomBrandAPI = () => {
    let filterValue = {
      pageIndex: 1,
      pageSize: 10,
      keyword: '',
      order: 'DESC',
      sortBy: 'viewed'
    }
    let action = getCustomBrandAction(filterValue);
    dispatch(action);
  }

  const getCategoriesByBrandAPI = () => {
    let action = getCategoriesByBrandAction(id, categoryIdParent);
    dispatch(action);
  }

  const getSubcategoriesAPI = () => {
    let tabValue = tabsConvert.find((el) => el?.eventKey === activeBottomTabSub)?.filterValue;
    let filterValue = {
      categoryId: activeTopTab,
      brandId: id,
      pageIndex: pageIndexSub,
      pageSize: 15,
      keyword: '',
    }
    filterValue = Object.assign(filterValue, tabValue ? tabValue : {});
    let action = getSubcategoriesAction(filterValue);
    dispatch(action);
  }

  const getProductsByParentAPI = () => {
    const tabsConvert = bottomTabsPro.flatMap(element => element.childs ? [element, ...element.childs] : [element]);
    let tabValue = tabsConvert.find((el) => el?.eventKey === activeBottomTabPro)?.filterValue;
    let filterValue = {
      categoryId: activeTopTab,
      brandId: id,
      pageIndex: pageIndexPro,
      pageSize: 15,
      keyword: '',
    }
    filterValue = Object.assign(filterValue, tabValue ? tabValue : {});
    let action = getProductsByParentAction(filterValue);
    dispatch(action);
  }

  const renderMenu = (data) => {
    return data?.map((el) => {
      return <NavLink className={`hadchild ${el?.name === keyListRelated ? 'active' : ''}`}
        key={`related-${el?.slug}`}
        to={`/brands/${el?.slug}-bra.${el?.id}`}
        onClick={() => setKeyListRelated(el?.name)}
      >
        <IoMdArrowDropright />
        <span>{el?.name}</span>
      </NavLink>
    })
  }

  const renderCategories = (data = []) => {
    return <Fragment>
      <Row className='result__content' xs={2} sm={3} md={4} xl={5}>
        {data?.map((el) => {
          return <Col className='result__item' key={`cat-child-${el?.name}`}>
            <div className='product__item'>
              <Link to={`/brands/${brand?.slug}-bra.${brand?.id}.${el?.parentId}.${el?.id}`}>
                <div className='product__image'>
                  <img src={JSON.parse(el?.image || '{}')?.url} alt={el?.name} />
                </div>
                <div className='product__content'>
                  <h4 className='product__name'>{el?.name}</h4>
                  <div className='product__rate'><b>4.5</b><FaStar /></div>
                </div>
              </Link>
            </div>
          </Col>
        })}
      </Row>
      <div className='pagination__content d-none d-md-block'>
        <Pagination propsPagination={{
          name: nameSub,
          pageIndex: subcategories?.pageIndex,
          pageSize: subcategories?.pageSize,
          totalRow: subcategories?.totalRow,
        }} />
      </div>
    </Fragment>
  };

  const renderProducts = (data = []) => {
    return <Fragment>
      <Row className='result__content' xs={2} sm={3} md={4} xl={5}>
        {data?.map((el) => {
          return <Col className='result__item' key={`product-${el?.name}`}>
            <div className='product__item'>
              <Link to={`/products/${el?.slug}-pro.${el?.id}`}>
                <div className='product__image'>
                  <img src={(el?.images || []).length ? el?.images[0]?.url : ''} alt={el?.name} />
                </div>
                <div className='product__content'>
                  <h4 className='product__name'>{el?.name}</h4>
                  <div className='product__price'>{el?.contact ? "Liên hệ" : `${new Intl.NumberFormat().format(el?.price)}₫`}</div>
                  <div className='product__rate'><b>{el?.voted?.toFixed(1)}</b><FaStar /></div>
                </div>
              </Link>
            </div>
          </Col>
        })}
      </Row>
      <div className='pagination__content d-none d-md-block'>
        <Pagination propsPagination={{
          name: namePro,
          pageIndex: productsByParent?.pageIndex,
          pageSize: productsByParent?.pageSize,
          totalRow: productsByParent?.totalRow,
        }} />
      </div>
    </Fragment>
  };

  const renderDetail = () => {
    return <div className='detail__content'>{parse(brand.detail ? brand.detail : '')}</div>
  };

  return (
    <div className='brand product__style'>
      <Container fluid='lg'>
        <Row className='product__style__content'>
          <Col className='product__result' xs={12} lg={9} xl={10}>
            {(brand?.categories || []).length && subcategories?.totalRow ?
              <div className="result__wapper">
                <div className="product__style__title">
                  <DoubleTabs
                    name={nameSub}
                    topTabs={topTabs}
                    defaultActiveTopKey={activeTopTab}
                    bottomTabs={bottomTabsSub}
                    defaultActiveBottomKey={activeBottomTabSub}
                    pagination={{
                      pageIndex: subcategories?.pageIndex,
                      pageSize: subcategories?.pageSize,
                      totalRow: subcategories?.totalRow,
                    }}
                    onSelectTab={(data) => {
                      setActiveTopTab(data.keyTopTab)
                      setActiveBottomTabSub(data.keyBottomTab)
                    }}
                    onChangePage={(data) => setPageIndexSub(data.pageIndex)}
                  />
                </div>
                {
                  activeBottomTabSub !== 'detail' ?
                    renderCategories(subcategories?.data)
                    : renderDetail()
                }
              </div>
              : ''
            }
            {(brand?.categories || []).length && productsByParent?.totalProduct ?
              <div className="result__wapper">
                <div className="product__style__title">
                  <DoubleTabs
                    name={namePro}
                    topTabs={topTabs}
                    defaultActiveTopKey={activeTopTab}
                    bottomTabs={bottomTabsPro}
                    defaultActiveBottomKey={activeBottomTabPro}
                    pagination={{
                      pageIndex: productsByParent?.pageIndex,
                      pageSize: productsByParent?.pageSize,
                      totalRow: productsByParent?.totalRow,
                    }}
                    onSelectTab={(data) => {
                      setActiveTopTab(data.keyTopTab)
                      setActiveBottomTabPro(data.keyBottomTab)
                    }}
                    onChangePage={(data) => setPageIndexPro(data.pageIndex)}
                  />
                </div>
                {
                  activeBottomTabPro !== 'detail' ?
                    productsByParent?.totalRow ?
                      renderProducts(productsByParent?.data)
                      : <div className='notify'>Hiện chưa có sản phẩm</div>
                    : renderDetail()
                }
              </div>
              : ''
            }
            {(brand?.categories || []).length < 1 || (subcategories?.totalRow < 1 && productsByParent?.totalProduct < 1) ? <div className='notify'>Hiện chưa có sản phẩm</div> : ''}
          </Col>
          <Col className='filter__panel d-none d-lg-block' lg={3} xl={2}>
            <div className='section__simple'>
              <div className='section__header'>
                <div className='section__header__title'>DANH MỤC</div>
              </div>
              <div className='section__content'>
                <Nav className='related__category'>
                  {renderMenu(brand?.name ?
                    (customBrand?.data || []).findIndex((el) => el?.name === brand.name) > -1
                      ? customBrand?.data
                      : [brand].concat(customBrand?.data)
                    : []
                  )}
                </Nav>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
