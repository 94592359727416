import React, { Fragment, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Button, Col, Container, Nav, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { FaStar, FaRegMoneyBillAlt, FaPhoneAlt, } from "react-icons/fa";
import { BsCartPlus, BsFiletypePdf } from "react-icons/bs";
import { FiMinus, FiPlus } from "react-icons/fi";
import { IoMdArrowDropright, IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link, NavLink, useParams } from 'react-router-dom';
import { extractUrl } from '../../util/func';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";

// import required modules
import { Grid, Navigation } from "swiper";
import RatingStars from '../../components/RatingStars/RatingStars';
import { getProductAction, getProductsByParentAction } from '../../redux/actions/ProductAction';
import productServices from '../../services/ProductService';

export default function Product() {
    const { productUrl } = useParams();
    const id = extractUrl(productUrl, '-pro.')[0];
    const [showImageProduct, setShowImageProduct] = useState(false);
    const [showSlide, setShowSlide] = useState(0);
    const [internalLink, setInternalLink] = useState('');
    const [keyListRelated, setKeyListRelated] = useState('');
    const [keyTab, setKeyTab] = useState('');
    const [detailContent, setDetailContent] = useState([]);
    const dispatch = useDispatch();
    const { allWebInfo } = useSelector(state => state.WebInfoReducer);
    const { productsByParent, product } = useSelector(state => state.ProductReducer);

    useEffect(() => {
        const tabHeader = document.getElementById('tabHeaderDetail');
        const tabContent = document.querySelector(".tab__content");
        const startPage = window.outerWidth < 768 ? 46.5 : 0;
        if (keyTab) {
            setInternalLink('');
            const yTab = tabContent?.getBoundingClientRect().top + window.pageYOffset - tabHeader?.getBoundingClientRect().height - startPage;
            window.scrollTo({ top: yTab, behavior: 'smooth' });
        }
    }, [keyTab]);

    useEffect(() => {
        const tabHeader = document.getElementById('tabHeaderDetail');
        const targetElement = document.getElementById(internalLink);
        const startPage = window.outerWidth < 768 ? 46.5 : 0;
        if (internalLink) {
            const yLink = targetElement?.getBoundingClientRect().top + window.pageYOffset - tabHeader?.getBoundingClientRect().height - startPage;
            window.scrollTo({ top: yLink, behavior: 'smooth' });
        }
    }, [internalLink]);

    useEffect(() => {
        const handleResize = () => {
            const setHeightSwiperWrapper = document.querySelectorAll('.image__swiper>.swiper-wrapper');
            if (setHeightSwiperWrapper.length > 0) {
                let valueHeightSwiper = 'auto';
                let valueWidthSwiper = 'auto';
                const getHeightLargeImage = document.getElementById('largeImage');
                getHeightLargeImage.style.height = `${getHeightLargeImage.getBoundingClientRect().width}px`;
                const setHeightSwiper = document.getElementById('imageSwiper');
                if (window.outerWidth > 479 && window.outerWidth < 768) {
                    valueHeightSwiper = `${getHeightLargeImage.getBoundingClientRect().width}px`;
                    valueWidthSwiper = 'auto';
                }
                else {
                    setHeightSwiperWrapper[0].style.height = valueHeightSwiper;
                }
                setHeightSwiper.style.height = valueHeightSwiper;
                setHeightSwiper.style.width = valueWidthSwiper;
            }
        };
        window.addEventListener('load', handleResize);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('load', handleResize);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // id && getProductAPI();
        if(id) getProductAPI()
    }, [id]);

    useEffect(() => {
        const updateViewsProduct = async (id) => {
            await productServices.updateViewsProduct(id);
        }
        if (product?.id === Number(id)) {
            document.title = product?.name;
            getProductsByParentAPI();
            setDetailContent(product?.productDetails);
            updateViewsProduct(product?.id);
        }
    }, [product?.id]);

    const getProductAPI = () => {
        let action = getProductAction(id);
        dispatch(action);
    }

    const getProductsByParentAPI = () => {
        let filterValue = {
            categoryId: product?.categoryId,
            brandId: product?.brandId,
            pageIndex: 1,
            pageSize: 10,
            keyword: '',
            old: 0,
            order: 'DESC',
            sortBy: 'viewed'
        }
        let action = getProductsByParentAction(filterValue);
        dispatch(action);
    };

    const renderMenuLeft = (data) => {
        return data?.map((el) => {
            return <NavLink className='hadchild'
                key={`related${el?.name}`}
                to={`/products/${el?.slug}-pro.${el?.id}`}
                onClick={() => setKeyListRelated(el?.name)}
            >
                <IoMdArrowDropright />
                <span>{el?.name}</span>
            </NavLink>
        })
    }

    const renderThumbsImage = (data) => {
        return data?.map((el, index) => {
            return <SwiperSlide key={`product-img${index}`} className={index === showSlide ? "show__slide" : ''}>
                <img src={el?.url} alt={product?.name} />
            </SwiperSlide>
        })
    }

    //render tab detail
    const renderTabHeaderBottom = (data) => {
        return <Nav className="tab__item bottom">
            <div className='tab__bottom__content'>
                <div className="swiper-button-prev-tab"><IoIosArrowBack /></div>
                <Swiper id='topswiper'
                    slidesPerView={"auto"}
                    spaceBetween={21}
                    modules={[Navigation]}
                    navigation={{
                        navigation: true,
                        nextEl: '.swiper-button-next-tab',
                        prevEl: '.swiper-button-prev-tab',
                    }}
                    className='tab__swiper'
                >
                    {data?.map((el) => {
                        return <SwiperSlide key={`part-link${el?.internalLinkId}`}>
                            <Link to='' onClick={() => setInternalLink(el?.internalLinkId)}>
                                {el?.title}
                            </Link>
                        </SwiperSlide>
                    })}
                </Swiper>
                <div className="swiper-button-next-tab"><IoIosArrowForward /></div>
            </div>
        </Nav>
    }

    const renderTabContent = (data) => {
        return data?.map((el) => {
            return <div id={el?.internalLinkId} key={`part-content${el?.internalLinkId}`} className='part__content'>
                <h4 >{parse(el?.title)}</h4>
                <div>{parse(el?.content)}</div>
            </div>
        })
    }

    const renderAttributes = (data) => {
        return data?.map((el) => {
            return <tr key={`attribute-${el?.id}`}>
                <td >{parse(el?.name ? el?.name : '')}</td>
                <td>{parse(el?.productAttribute?.value ? el?.productAttribute?.value : '')}</td>
            </tr>
        })
    }

    const renderModalThumbsImage = (data) => {
        return data?.map((el, index) => {
            return <div key={`modal-img${index}`} className='item__thumb'>
                <div className={`slide ${index === showSlide ? "show__slide" : ''}`}
                    onClick={() => {
                        setShowSlide(index);
                    }}
                >
                    <img src={el?.url} alt={product?.name} />
                </div>
            </div>
        })
    }

    return (
        <div className='product__detail product__style'>
            <Container fluid='xl'>
                <Row className='product__style__content'>
                    <Col className='product__result' xs={12} lg={9} xl={10}>
                        <Row className='poduct__detail__top'>
                            <Col className='poduct__detail__image' xs={12} md={5}>
                                <div className='large__image' onClick={() => setShowImageProduct(true)}>
                                    <img id='largeImage'
                                        src={product?.images?.length > 0 ? product?.images[showSlide]?.url : ""}
                                        alt={product?.name}
                                    />
                                </div>
                                <div className='thumbs__image'>
                                    <Swiper
                                        id='imageSwiper'
                                        // ref={swiperRef}
                                        // onAfterInit={}
                                        onClick={(swiper) => {
                                            if (swiper.clickedIndex !== undefined) {
                                                setShowSlide(swiper.clickedIndex);
                                            }
                                        }}
                                        modules={[Grid, Navigation]}
                                        direction={"horizontal"}
                                        grid={{
                                            fill: 'column',
                                            rows: 1
                                        }}
                                        slidesPerView={4}
                                        spaceBetween={10}
                                        breakpoints={{
                                            480: {
                                                direction: "vertical",
                                                grid: {
                                                    fill: 'row',
                                                    rows: 1
                                                },
                                                slidesPerView: 4,
                                            },
                                            576: {
                                                direction: "vertical",
                                                grid: {
                                                    fill: 'row',
                                                    rows: 2
                                                },
                                                slidesPerView: 4
                                            },
                                            768: {
                                                direction: "horizontal",
                                                grid: {
                                                    fill: 'column',
                                                    rows: 1
                                                },
                                                slidesPerView: 3,
                                            },
                                            800: {
                                                direction: "horizontal",
                                                slidesPerView: 3,
                                            },
                                            992: {
                                                direction: "horizontal",
                                                slidesPerView: 3,
                                            },
                                            1200: {
                                                direction: "horizontal",
                                                slidesPerView: 4,
                                            },
                                        }}
                                        navigation={true}
                                        className="image__swiper"
                                    >
                                        {product?.images?.length > 0 ? renderThumbsImage(product?.images) : ""}
                                    </Swiper>
                                </div>
                            </Col>
                            <Col className='poduct__detail__sell' xs={12} md={7}>
                                <h3 className='poduct__header'>{product?.name}</h3>
                                <div className='poduct__review__wrapper'>
                                    <div className='review__item product__rate'>
                                        <b>{product?.voted?.toFixed(1)}</b><FaStar />
                                    </div>
                                    <div className='review__item'>
                                        <span className='review__number'>{product?.assess}</span>
                                        <span>Đánh Giá</span>
                                    </div>
                                    <div className='review__item'>
                                        <span className='review__number'>{product?.sold}</span>
                                        <span>Đã Bán</span>
                                    </div>
                                </div>
                                <div className='product__price'>
                                    <span>{product?.contact ? `Liên hệ ${allWebInfo?.hotline}` : `${new Intl.NumberFormat().format(product?.price)}₫`}</span>
                                </div>
                                <div className='product__document space__top'>
                                    <div className='document__item'>
                                        <div className='left__align'>Danh mục:</div>
                                        <div><Link to={`/categories/${product?.productCategory?.slug}-cat.${product?.productCategory?.id}`}>{product?.productCategory?.name}</Link></div>
                                    </div>
                                    <div className='document__item'>
                                        <div className='left__align'>Mã sản phẩm:</div>
                                        <div>{product?.name}</div>
                                    </div>
                                    {product?.catalog?.url ?
                                        <div className='document__item'>
                                            <div className='left__align'>Tài liệu kỹ thuật:</div>
                                            <div className='download__document'>
                                                <Link to={product?.catalog?.url} target="_blank"><BsFiletypePdf /></Link>
                                            </div>
                                        </div> : ''
                                    }
                                </div>
                                <div className='product__quantity__wrapper space__top'>
                                    <span className='left__align'>Số lượng:</span>
                                    {product?.contact ? "" :
                                        <div className='product__quantity__mid'>
                                            <Button><FiMinus /></Button>
                                            <Form.Control className='input__quantity' type="text" defaultValue={1} />
                                            <Button><FiPlus /></Button>
                                        </div>
                                    }
                                    <span className='me-1'>{product?.amount}</span><span> sản phẩm có sẵn</span>
                                </div>
                                <div className='product__buy__wrapper space__top'>
                                    {product?.contact ? <Button className='button__buy'><FaRegMoneyBillAlt />Yêu cầu báo giá</Button> :
                                        <Fragment>
                                            <Button className='button__cart'><BsCartPlus /><span>Thêm vào giỏ hàng</span></Button>
                                            <Button className='button__buy'>Mua ngay</Button>
                                        </Fragment>
                                    }
                                </div>
                                {(product?.productPromotions || []).length ?
                                    <div className='promotion__wrapper space__top'>
                                        <div className='left__align'>Khuyến mại:</div>
                                        <div className='promotion'>
                                            <div>
                                                {product?.productPromotions.map(el => <div>{parse(el?.content)}</div>)}
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                                }
                            </Col>
                        </Row>
                        <div className="product__detail__bottom">
                            <Tab.Container
                                defaultActiveKey="detail"
                                onSelect={(eventKey) => {
                                    setKeyTab(eventKey);
                                    if (eventKey === "reviews" || eventKey === "attribute") {
                                        setDetailContent([]);
                                    }
                                    else {
                                        setDetailContent(product?.productDetails);
                                    }
                                }}
                            >
                                <div id='tabHeaderDetail' className='tab__header'>
                                    <Nav className="tab__item top">
                                        <Nav.Item>
                                            <Nav.Link eventKey="detail">Tổng quan</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="attribute">Thông số kỹ thuật</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="reviews">Đánh giá</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    {detailContent?.length ? renderTabHeaderBottom(product?.productDetails) : ""}
                                </div>
                                <Tab.Content className='tab__content'>
                                    <Tab.Pane eventKey="detail">
                                        <div className='content__wrapper'>
                                            {detailContent?.length ? renderTabContent(product?.productDetails) : ""}
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="attribute">
                                        <div className='content__wrapper'>
                                            <div className='part__content'>
                                                <h4 >Thông số kỹ thuật {product?.name}</h4>
                                                <Table striped bordered hover>
                                                    <tbody>
                                                        <tr>
                                                            <td>Tên sản phẩm</td>
                                                            <td>{product?.name}</td>
                                                        </tr>
                                                        {(product?.productAttributes || [])?.length ? renderAttributes(product?.productAttributes) : ""}
                                                        <tr>
                                                            <td>Hãng sản xuất</td>
                                                            <td>{product?.productBrand?.name}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>

                                            </div>
                                            <div className='part__content'>
                                                <h4 >Tài liệu </h4>
                                                <div></div>
                                                {product?.catalog?.url ? <Link to={product?.catalog?.url} target="_blank"><BsFiletypePdf /> Catalog: {product?.name}</Link> : 'Tài liệu đang được cập nhật!'}
                                            </div>

                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="reviews">
                                        <div className='content__wrapper'>
                                            <Row className='reviews__header'>
                                                <Col sm={3} className='reviews__header__left'>
                                                    <h4>{product?.name}</h4>
                                                    <span className='number__rating__stars'>{product?.voted?.toFixed(1)}</span>
                                                    <div className='total__rating__stars'>
                                                        <RatingStars stars={product?.voted?.toFixed(1)} />
                                                    </div>
                                                </Col>
                                                <Col sm={8} className="reviews__header__right">
                                                    <Button className='button__stars'>Tất cả</Button>
                                                    <Button className='button__stars'>5 sao</Button>
                                                    <Button className='button__stars'>4 sao</Button>
                                                    <Button className='button__stars'>3 sao</Button>
                                                    <Button className='button__stars'>2 sao</Button>
                                                    <Button className='button__stars'>1 sao</Button>
                                                </Col>
                                            </Row>
                                            <div className='reviews__content'>
                                                <div className='reviews__avatar'>
                                                    <div className="reviews__user__avatar">
                                                        <img src="https://picsum.photos/100/100?random=12" alt="ab" />
                                                    </div>
                                                </div>
                                                <div className='reviews__product__wrapper'>
                                                    <div className='reviews__product__user'>
                                                        <div className="reviews__product__name">
                                                            Lĩnh
                                                        </div>
                                                        <div className="reviews__product__time">
                                                            2021-05-24 18:08
                                                        </div>
                                                    </div>
                                                    <div className="reviews__product__stars">
                                                        <RatingStars stars={3.5} />
                                                    </div>
                                                    <div className="reviews__product__content">
                                                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi expedita numquam voluptate omnis voluptatibus illo, aspernatur distinctio enim.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                        <div className='require'>
                            <div className='section__simple'>
                                <div className='section__header'>
                                    <div className='section__header__title'>Gửi tin nhắn cho ANTE</div>
                                </div>
                                <div className='section__content'>
                                    <span className='receiver'>Đến: ANTE</span>
                                    <Form.Control className='message'
                                        as="textarea"
                                        placeholder="Nhập chi tiết yêu cầu của bạn về sản phẩm"
                                        rows={4}
                                    />
                                    <Row className='info'>
                                        <Col xs={12} md={6}>
                                            <Form.Control type="text" placeholder="Họ và tên" />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Control type="number" placeholder="Số điện thoại" />
                                        </Col>
                                    </Row>
                                    <div className='button__wrapper'>
                                        <Button className='send__message'>Gửi</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className='filter__panel d-none d-lg-block' lg={3} xl={2}>
                        <div className='section__simple'>
                            <div className='section__content box__hotlines'>
                                <div className="box__hotlines__left">
                                    <h3>Liên hệ mua hàng</h3>
                                    <h2>
                                        <Link to={`tel:${allWebInfo?.hotline}`}>{`${allWebInfo?.hotline}`}</Link>
                                    </h2>
                                    <div className="link__about">
                                        <Link to="/about">Địa chỉ cửa hàng</Link>
                                    </div>
                                </div>
                                <div className="box__hotlines__right">
                                    <Link to={`tel:${allWebInfo?.hotline}`}><FaPhoneAlt /></Link>
                                </div>
                            </div>
                        </div>
                        <div className='section__simple'>
                            <div className='section__header'>
                                <div className='section__header__title'>
                                    <Link to={`/categories/${product?.productCategory?.slug}-cat.${product?.productCategory?.id}`}>{product?.productCategory?.name}</Link>
                                </div>
                            </div>
                            <div className='section__content'>
                                <Nav className='related__category' activeKey={keyListRelated}>
                                    {(productsByParent?.data || []).length ? renderMenuLeft(productsByParent?.data) : ''}
                                </Nav>
                            </div>
                        </div>

                    </Col>
                </Row>

                {/* show image product */}
                <Modal
                    size="lg"
                    show={showImageProduct}
                    onHide={() => setShowImageProduct(false)}
                    aria-labelledby="modal-ImageProduct"
                    centered
                    className='modal__image__product'
                >
                    <Modal.Body>
                        <Row>
                            <Col xs={12} lg={8}>
                                <div className="modal__large__image">
                                    <div className="modal__arow__prev" onClick={() => {
                                        setShowSlide(showSlide > 0 ? showSlide - 1 : 0);
                                    }}>
                                        <IoIosArrowBack />
                                    </div>
                                    <img
                                        src={product?.images?.length ? product?.images[showSlide]?.url : ""}
                                        alt={product?.name}
                                    />
                                    <div className="modal__arow__next" onClick={() => {
                                        const arrlength = product?.images?.length;
                                        setShowSlide(showSlide < arrlength - 1 ? showSlide + 1 : arrlength - 1);
                                    }}>
                                        <IoIosArrowForward />
                                    </div>
                                </div>
                            </Col>
                            <Col className='d-none d-lg-block' lg={4}>
                                <div className='modal__poduct__name'>
                                    {product?.name}
                                </div>
                                <div className="modal__thumb__image">
                                    {product?.images?.length ? renderModalThumbsImage(product?.images) : ""}
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </Container>
        </div>
    );
};
