import { 
    GET_ALL_ATTRIBUTE, 
    GET_ATTRIBUTE, 
    GET_CUSTOM_ATTRIBUTE, 
    RELOAD_ATTRIBUTE 
} from "../types/AttributeType";

const initialState = {
    allAttribute: [],
    customAttribute: {},
    attribute: {},
    reloadAttribute: false,
}

export const AttributeReducer = (state = initialState, action) => { 
    switch (action.type) {
        case GET_ALL_ATTRIBUTE:
            state.allAttribute = action.allAttribute;
           return {...state};
           case GET_CUSTOM_ATTRIBUTE:
            state.customAttribute = action.customAttribute;
            return {...state};
            case GET_ATTRIBUTE:
                state.attribute = action.attribute;
           return {...state};
           case RELOAD_ATTRIBUTE:
                state.reloadAttribute = action.reloadAttribute;
           return {...state};
        default:
            return state;
    }
 }