import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Upload, Form, Input, Button, Modal, Space } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import {
  addPostAction,
} from '../../../redux/actions/PostAction';
import postServices from '../../../services/PostService';
import { uploadFile } from '../../../util/func';

export default function AddPost() {
  const [detailImageId, setDetailImageId] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTinymce, setIsTinymce] = useState(false);
  const editorRef = useRef(null);
  let { reloadPost } = useSelector(state => state.PostReducer);
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      title: '',
      image: {},
      content: '',
      status: '',
      fileIdList: []
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Tiêu đề không được để trống")
        .test("Tiêu đề", "Tiêu đề đã tồn tại", async (values) => {
          let check = await postServices.validationPost({ title: values })
            .then((result) => {
              return result.data.content;
            });
          return !check;
        }),
    }),
    onSubmit: values => {
      let action = addPostAction(values);
      dispatch(action);
    },
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

  useEffect(() => {
    if (reloadPost !== false) {
      navigate('/admin/posts');
    }
    // eslint-disable-next-line
  }, [reloadPost]);

  useEffect(() => {
    if (isTinymce === true) {
      const content = values?.content;
      if (editorRef.current) {
        if (content) {
          editorRef.current.setContent(content)
        }
        else {
          editorRef.current.resetContent();
        }
      }
    }
  }, [isTinymce]);

  useEffect(() => {
    if (detailImageId > 0) {
      let arrFile = values?.fileIdList ? values?.fileIdList : [];
      arrFile.push(detailImageId);
      setFieldValue('fileIdList', arrFile);
    }
  }, [detailImageId]);

  //control modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const uploadDetailImageAPI = (blobInfo, progress) => new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.blob()?.name);
    let image = uploadFile(formData);
    resolve(image);
  }).then((result) => {
    setDetailImageId(result.id);
    return result.url;
  });

  //upload image
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const updateImagePostAPI = async (file) => {
    let formData = new FormData();
    formData.append('file', file, file.name);
    let imageFile = await uploadFile(formData);
    setFieldValue('image', { uid: imageFile.id, url: imageFile.url });
    return false;
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <div>
      <h2 >Thêm bài viết</h2>
      <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={values}
      >
        <Form.Item label="Tiêu đề" name="title"
          validateStatus={touched.title && errors.title ? "error" : "success"}
          help={touched.title && errors.title ? (errors.title) : null}>
          <Input onChange={handleChange} onBlur={handleBlur} />
        </Form.Item>
        <Form.Item label="Hình ảnh" valuePropName="fileList">
          <Upload
            customRequest={dummyRequest}
            listType="picture-card"
            fileList={
              Object.keys(values?.image)?.length > 0 ?
                [{
                  uid: values?.image?.uid,
                  name: `image${values?.image?.uid}`,
                  status: 'done',
                  url: values?.image?.url,
                }] : []
            }
            onRemove={() => setFieldValue('image', {})}
            beforeUpload={updateImagePostAPI}
            onPreview={onPreview}
          >
            {Object.keys(values?.image)?.length > 0 ? null : uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item label="Nội dung" name="content">
          <Button onClick={() => showModal()}>
            Thêm nội dung
          </Button>
        </Form.Item>
        <Form.Item label="Hành động">
          <Space size={'small'}>
            <Button htmlType="submit" onClick={() => {
              setFieldValue('status', 'draft');
              handleSubmit();
            }}
            >Lưu nháp</Button>
            <Button type="primary" htmlType="submit" onClick={() => {
              setFieldValue('status', 'publish');
              handleSubmit();
            }}
            >Thêm</Button>
          </Space>
        </Form.Item>
      </Form>
      <Modal title="Bài viết"
        centered
        width={1000}
        open={isModalOpen}
        onOk={() => {
          if (editorRef.current) {
            setFieldValue('content', editorRef.current.getContent());
          }
          closeModal();
        }}
        onCancel={closeModal}>
        <Editor
          apiKey='a68gdz94s7gbdrmzq6n8h99gw0yr98wxwmpfd7rfk84hdpkt'
          init={{
            height: "80vh",
            menubar: true,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            automatic_uploads: true,
            images_upload_handler: uploadDetailImageAPI,
            file_picker_types: 'image',
            forced_root_block: 'div'
          }}
          onInit={(evt, editor) => {
            editorRef.current = editor;
            setIsTinymce(editor.initialized);
          }}
        />
      </Modal>
    </div>
  );
};