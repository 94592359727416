import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import { Form, Input, Button, Table } from 'antd';
import {
    getCustomPlacementAction,
    reloadPlacementAction,
    removePlacementAction
} from '../../../redux/actions/PlacementAction';

export default function Placements() {
    const [searchName, setSearchName] = useState('');
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const { customPlacement, reloadPlacement } = useSelector(state => state.PlacementReducer);
    const { tableLoading } = useSelector(state => state.LoadingReducer);
    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        getcustomPlacementAPI();
    }, [JSON.stringify(tableParams), searchName]);

    useEffect(() => {
        if (reloadPlacement !== false) {
            getcustomPlacementAPI();
            let action = reloadPlacementAction();
            dispatch(action);
        }
    }, [reloadPlacement]);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            sorter: (a, b) => a?.id - b?.id,
        },
        {
            title: 'Tên',
            dataIndex: 'name',
        },
        {
            title: 'Khóa chính',
            dataIndex: 'key',
        },
        {
            title: 'Hàng động',
            dataIndex: '',
            width: '10%',
            render: (t, r) => <div>
                <EditFilled style={{ fontSize: '16px', color: '#1677ff', marginRight: '10px' }} onClick={() => {
                    navigate(`/admin/edit-placement/${r?.id}`);
                }} />
                <DeleteFilled style={{ fontSize: '16px', color: '#ff4d4f' }} onClick={() => {
                    let action = removePlacementAction(r?.id);
                    dispatch(action);
                }} />
            </div>,
        },
    ];

    const handleTableChange = (pagination, sorter) => {
        setTableParams({
            pagination,
            ...sorter,
        });
        // `dataSource` is useless since `pageSize` changed
        if (pagination?.pageSize !== tableParams.pagination?.pageSize) {
            customPlacement.data = [];
        }
    };

    const validate = values => {
        setSearchName(values.name.trim());
    };

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validate,
        onSubmit: values => {
            setSearchName(values.name.trim());
        },
    });

    const { handleChange, handleSubmit } = formik;

    let getcustomPlacementAPI = () => {
        let { current, pageSize } = tableParams.pagination;
        let filterValue = {
            pageIndex: current,
            pageSize: pageSize,
            keyword: searchName,
            order: 'ASC',
            sortBy: 'createdAt'
        }
        let action = getCustomPlacementAction(filterValue);
        dispatch(action);
    };

    return (
        <div>
            <h2 >Quản lý vị trí đặt</h2>
            <Button type="primary" style={{ marginBottom: '20px' }}
                onClick={() => {
                    navigate(`/admin/add-placement`);
                }}>Thêm vị trí đặt</Button>
            <Form layout="horizontal" onFinish={handleSubmit}>
                <Form.Item name="name" style={{ display: 'inline-block', width: '80%' }}>
                    <Input onChange={handleChange} placeholder="Tìm kiếm theo tên vị trí đặt" />
                </Form.Item>
                <Form.Item style={{ display: 'inline-block', marginLeft: '10px' }}>
                    <Button type="default" htmlType="submit">Tìm kiếm</Button>
                </Form.Item>
            </Form>
            <Table rowKey='id' columns={columns} dataSource={customPlacement?.data}
                pagination={{
                    current: customPlacement?.pageIndex,
                    pageSize: customPlacement?.pageSize,
                    total: customPlacement?.totalRow
                }}
                loading={tableLoading}
                onChange={handleTableChange} />
        </div>
    );
};
