import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Input, Button, DatePicker, Select, Upload } from 'antd';
import { useParams } from 'react-router-dom';
import {
    getUserAction,
    updateAvatarAction,
    updateUserAction
} from '../../../redux/actions/UserAction';
import userService from '../../../services/UserService';

export default function EditUser() {
    const { id } = useParams();
    const { user, reloadUser } = useSelector(state => state.UserReducer);
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const formik = useFormik({
        initialValues: {
            displayName: '',
            email: '',
            avatar: {},
            phone: '',
            address: '',
            birthday: '',
            gender: '',
            role: '',
        },
        validationSchema: Yup.object({
            displayName: Yup.string().required("Tên không được để trống").matches(/^[A-Z a-z]+$/, "Tên không đúng định dạng"),
            email: Yup.string().required("Email không được để trống").email("Email chưa đúng định dạng")
                .test("Email", "Email đã tồn tại", async (values) => {
                    let validationUser = { 
                        id: user?.id,
                        email: values 
                    }
                    let check = await userService.validationUser(validationUser).then((result) => {
                        return result.data.content;
                    });
                    return !check;
                }),
            phone: Yup.string().required("Số điện thoại không được để trống").matches(/(((\+|)84)|0)(3|5|7|8|9)+([0-9]{8})\b/, "Số điện thoại không đúng định dạng"),
            address: Yup.string().required("Địa chỉ không được để trống"),
            birthday: Yup.string().required("Ngày sinh không được để trống"),
        }),
        onSubmit: values => {
            let action = updateUserAction(user.id, values);
            dispatch(action);
        },
    });

    const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

    useEffect(() => {
        getUserAPI();
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (Object.keys(user).length !== 0) {
            form.setFieldsValue({
                displayName: user?.displayName,
                email: user?.email,
                phone: user?.phone,
                address: user?.address,
                birthday: dayjs(user?.birthday, 'DD/MM/YYYY'),
                gender: user?.gender,
                role: user?.role,
            });
            formik.setValues(user);
        }
        // eslint-disable-next-line
    }, [JSON.stringify(user)]);

    useEffect(() => {
        if (reloadUser !== false) {
            navigate('/admin/users');
        }
        // eslint-disable-next-line
    }, [reloadUser]);

    let getUserAPI = () => {
        let action = getUserAction(id);
        dispatch(action);
    }

    //upload image
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const updateAvatarAPI = async (file) => {
        let action = updateAvatarAction(id, file);
        dispatch(action);
        return false;
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    return (
        <div>
            <h2 >Cập nhập thông tin người dùng</h2>
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }}
                layout="horizontal"
                onFinish={handleSubmit}
                form={form}
            >
                <Form.Item label="Tên hiển thị" name="displayName"
                    validateStatus={touched.displayName && errors.displayName ? "error" : "success"}
                    help={touched.displayName && errors.displayName ? (errors.displayName) : null}>
                    <Input onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Email" name="email"
                    validateStatus={touched.email && errors.email ? "error" : "success"}
                    help={touched.email && errors.email ? (errors.email) : null}>
                    <Input onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Số điện thoại" name="phone"
                    validateStatus={touched.phone && errors.phone ? "error" : "success"}
                    help={touched.phone && errors.phone ? (errors.phone) : null}>
                    <Input onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Địa chỉ" name="address"
                    validateStatus={touched.address && errors.address ? "error" : "success"}
                    help={touched.address && errors.address ? (errors.address) : null}>
                    <Input onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Sinh nhật" name="birthday"
                    validateStatus={touched.birthday && errors.birthday ? "error" : "success"}
                    help={touched.birthday && errors.birthday ? (errors.birthday) : null}>
                    <DatePicker onChange={(date, dateString) => setFieldValue('birthday', dateString)} format='DD/MM/YYYY' onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Avatar" valuePropName="fileList">
                    <Upload
                        customRequest={dummyRequest}
                        listType="picture-card"
                        fileList={
                            Object.keys(values?.avatar || {})?.length > 0 ?
                                [{
                                    uid: values?.id,
                                    name: values?.displayName,
                                    status: 'done',
                                    url: values?.avatar?.url
                                }] : []
                        }
                        onRemove={() => setFieldValue('avatar', {})}
                        beforeUpload={updateAvatarAPI}
                        onPreview={onPreview}
                    >
                        {Object.keys(values?.avatar || {})?.length > 0 ? null : uploadButton}
                    </Upload>
                </Form.Item>
                <Form.Item label="Giới tính" name="gender" >
                    <Select
                        style={{ width: 120 }}
                        onChange={(value) => setFieldValue('gender', value)}
                        options={[
                            {
                                value: true,
                                label: 'Nam',
                            },
                            {
                                value: false,
                                label: 'Nữ',
                            },

                        ]}
                    />
                </Form.Item>
                <Form.Item label="Phân quyền" name="role">
                    <Select
                        style={{ width: 120 }}
                        onChange={(value) => setFieldValue('role', value)}
                        options={[
                            {
                                value: 'admin',
                                label: 'ADMIN',
                            },
                            {
                                value: 'editor',
                                label: 'EDITOR',
                            },
                            {
                                value: 'salesman',
                                label: 'SALESMAN',
                            },
                            {
                                value: 'user',
                                label: 'USER',
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item label="Hành động">
                    <Button type="primary" htmlType="submit">Cập nhập</Button>
                </Form.Item>
            </Form>
        </div>
    )
}
