import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/grid';
import "swiper/css/navigation";
import { Grid, Navigation } from 'swiper';

export default function Category(props) {
    const customCategory = props.customCategory;
    const renderCategory = (data = []) => {
        return data?.map((el) => {
            return <SwiperSlide key={`category${el?.slug}`}>
                <Link to={`/categories/${el?.slug}-cat.${el.id}`} className="category__item">
                    <div className="category__image">
                        <img src={el?.image?.url} alt={el?.name} loading="lazy" />
                        <div className="swiper-lazy-preloader"></div>
                    </div>
                    <div className="category__title">
                        <span>{el?.name}</span>
                    </div>
                </Link>
            </SwiperSlide>
        })
    }

    return (
        <div className='category'>
            <div className='section__simple'>
                <div className='section__header'>
                    <div className='section__header__title'>DANH MỤC</div>
                </div>
                <div className='section__content'>
                    <Swiper
                        slidesPerView={3}
                        slidesPerGroup={3}
                        spaceBetween={0}
                        grid={{
                            rows: 2,
                            fill: 'row',
                        }}
                        navigation={true}
                        breakpoints={{
                            480: {
                                slidesPerView: 4,
                                slidesPerGroup: 4,
                                grid: {
                                    rows: 2,
                                    fill: 'row',
                                }
                            },
                            576: {
                                slidesPerView: 5,
                                slidesPerGroup: 5,
                                grid: {
                                    rows: 2,
                                    fill: 'row',
                                }
                            },
                            768: {
                                slidesPerView: 6,
                                slidesPerGroup: 6,
                                grid: {
                                    rows: 2,
                                    fill: 'row',
                                }
                            },
                            992: {
                                slidesPerView: 8,
                                slidesPerGroup: 8,
                                grid: {
                                    rows: 2,
                                    fill: 'row',
                                }
                            },
                            1140: {
                                slidesPerView: 10,
                                slidesPerGroup: 10,
                                grid: {
                                    rows: 2,
                                    fill: 'row',
                                }
                            },
                        }}
                        modules={[Grid, Navigation]}
                        className='category__swiper'
                    >
                        {renderCategory(customCategory)}
                    </Swiper>
                </div>
            </div>
        </div>
    )
}
