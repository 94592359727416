import {
    GET_CUSTOM_USER,
    GET_USER,
    RELOAD_USER,
} from "../types/UserType";

const initialState = {
    user: {},
    customUser: {},
    reloadUser: false,
}

export const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER:
            state.user = action.user;
            return { ...state };
        case GET_CUSTOM_USER:
            state.customUser = action.customUser;
            return { ...state };
        case RELOAD_USER:
            state.reloadUser = action.reloadUser;
            return { ...state };
        default:
            return state;
    }
};