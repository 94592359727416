import axios from "axios";
import { ACCESS_TOKEN, URL_API } from "./setting";

export const http = axios.create({
    baseURL: URL_API,
    timeout: 30000
});

http.interceptors.request.use(config => {
    config.headers = {
        ...config.headers,
        'authorization': `Bearer ${JSON.parse(localStorage.getItem(ACCESS_TOKEN))?.token}`,
    }
    return config
}, (error) => {
    return Promise.reject(console.log(error));
})

http.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(console.log(error));
    }
);