import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import { Form, Input, Button, Table } from 'antd';
import {
    getCustomGlobalLinkAction,
    reloadGlobalLinkAction,
    removeGlobalLinkAction
} from '../../../redux/actions/GlobalLinkAction';

export default function GlobalLinks() {
    const [searchName, setSearchName] = useState('');
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    let { customGlobalLink, reloadGlobalLink } = useSelector(state => state.GlobalLinkReducer);
    let { tableLoading } = useSelector(state => state.LoadingReducer);
    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        getCustomGlobalLinkAPI();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams), searchName]);

    useEffect(() => {
        if (reloadGlobalLink !== false) {
            getCustomGlobalLinkAPI();
            let action = reloadGlobalLinkAction();
            dispatch(action);
        }
        // eslint-disable-next-line
    }, [reloadGlobalLink]);

    const validate = values => {
        setSearchName(values.name.trim());
    };

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validate,
        onSubmit: values => {
            setSearchName(values.name.trim());
        },
    });

    const { handleChange, handleSubmit } = formik;

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            sorter: (a, b) => a?.id - b?.id,
        },
        {
            title: 'Nội dung',
            dataIndex: 'text',
        },
        {
            title: 'Đường dẫn',
            dataIndex: 'href',
        },
        {
            title: 'Vị trí đặt',
            dataIndex: 'placements',
            render: (t, r) => (r?.placements || []).map((el) => `${el?.name}; `)
        },
        {
            title: 'Hàng động',
            dataIndex: '',
            width: '10%',
            render: (t, r) => <div>
                <EditFilled style={{ fontSize: '16px', color: '#1677ff', marginRight: '10px' }} onClick={() => {
                    navigate(`/admin/edit-global-link/${r?.id}`);
                }} />
                <DeleteFilled style={{ fontSize: '16px', color: '#ff4d4f' }} onClick={() => {
                    let action = removeGlobalLinkAction(r?.id);
                    dispatch(action);
                }} />
            </div>,
        },
    ];

    const handleTableChange = (pagination, sorter) => {
        setTableParams({
            pagination,
            ...sorter,
        });
        // `dataSource` is useless since `pageSize` changed
        if (pagination?.pageSize !== tableParams.pagination?.pageSize) {
            customGlobalLink.data = [];
        }
    };

    let getCustomGlobalLinkAPI = () => {
        let { current, pageSize } = tableParams.pagination;
        let filterValue = {
            pageIndex: current,
            pageSize: pageSize,
            keyword: searchName
        }
        let action = getCustomGlobalLinkAction(filterValue);
        dispatch(action);
    }

    return (
        <div>
            <h2>Danh sách liên kết</h2>
            <Button type="primary" style={{ marginBottom: '20px' }} onClick={() => {
                navigate('/admin/add-global-link');
            }}>Thêm liên kết</Button>
            <Form layout="horizontal" onFinish={handleSubmit}>
                <Form.Item name="name" style={{ display: 'inline-block', width: '80%' }}>
                    <Input onChange={handleChange} placeholder="Tìm kiếm theo vị trí đặt" />
                </Form.Item>
                <Form.Item style={{ display: 'inline-block', marginLeft: '10px' }}>
                    <Button type="default" htmlType="submit">Tìm kiếm</Button>
                </Form.Item>
            </Form>
            <Table rowKey='id' columns={columns} dataSource={customGlobalLink?.data}
                pagination={{
                    current: customGlobalLink?.pageIndex,
                    pageSize: customGlobalLink?.pageSize,
                    total: customGlobalLink?.totalRow
                }}
                loading={tableLoading}
                onChange={handleTableChange}
            />
        </div>
    );
};