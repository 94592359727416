import mediaServices from "../../services/MediaService";
import { notification} from "../../util/func";
import { 
    GET_CUSTOM_ABOUT,
    GET_CUSTOM_ADVER,
    GET_CUSTOM_BANNER,
    GET_CUSTOM_MEDIA,
    GET_MEDIA, 
    RELOAD_MEDIA
} from "../types/MediaType";

import { tableLoadingAction } from "./LoadingAction";

export const getMediaAction = (mediaId) => {
    return (dispatch2) => {
        let promise = mediaServices.getMedia(mediaId);
        promise.then((result) => {
            let action2 = {
                type: GET_MEDIA,
                media: result.data.content,
            }
            dispatch2(action2);
        });
        promise.catch((error) => {
            console.log(error)
        });
    };
};

export const getCustomMediaAction = (filterValue) => {
    return (dispatch2) => {
        dispatch2(tableLoadingAction(true));
        let promise = mediaServices.getCustomMedia(filterValue);
        promise.then((result) => {
            let action2 = {
                type: GET_CUSTOM_MEDIA,
                customMedia: result.data.content,
            };
            dispatch2(action2);
            dispatch2(tableLoadingAction(false));
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const getCustomBannerAction = (filterValue) => {
    return (dispatch2) => {
        dispatch2(tableLoadingAction(true));
        let promise = mediaServices.getCustomMedia(filterValue);
        promise.then((result) => {
            let action2 = {
                type: GET_CUSTOM_BANNER,
                customBanner: result.data.content,
            };
            dispatch2(action2);
            dispatch2(tableLoadingAction(false));
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const getCustomAdverAction = (filterValue) => {
    return (dispatch2) => {
        dispatch2(tableLoadingAction(true));
        let promise = mediaServices.getCustomMedia(filterValue);
        promise.then((result) => {
            let action2 = {
                type: GET_CUSTOM_ADVER,
                customAdver: result.data.content,
            };
            dispatch2(action2);
            dispatch2(tableLoadingAction(false));
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const getCustomAboutAction = (filterValue) => {
    return (dispatch2) => {
        dispatch2(tableLoadingAction(true));
        let promise = mediaServices.getCustomMedia(filterValue);
        promise.then((result) => {
            let action2 = {
                type: GET_CUSTOM_ABOUT,
                customAbout: result.data.content,
            };
            dispatch2(action2);
            dispatch2(tableLoadingAction(false));
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const addMediaAction = (mediaNew = {}) => {
    return (dispatch2) => {
        let promise = mediaServices.addMedia(mediaNew);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_MEDIA,
                reloadMedia: true,
            };
            dispatch2(action2);
            notification("Thêm media thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const updateMediaAction = (mediaId = '', mediaNew = {}) => {
    return (dispatch2) => {
        let promise = mediaServices.updateMedia(mediaId, mediaNew);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_MEDIA,
                reloadMedia: true,
            }
            dispatch2(action2);
            notification("Cập nhập media thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const removeMediaAction = (mediaId = '') => {
    return (dispatch2) => {
        let promise = mediaServices.deleteMedia(mediaId);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_MEDIA,
                reloadMedia: true,
            }
            dispatch2(action2);
            notification(result.data?.content);
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const reloadMediaAction = () => {
    return (dispatch2) => {
        let action2 = {
            type: RELOAD_MEDIA,
            reloadMedia: false,
        }
        dispatch2(action2);
    };
};