import { http } from "../util/config";

class AuthServices {
    login = (userInfo) => {
        return http.post(`/api/v1/auth/login`, userInfo);
    }

    userInfo = () => {
        return http.get(`/api/v1/auth/userinfo`);
    }
}

const authServices = new AuthServices();
export default authServices;