import { http } from "../util/config"

class GlobalLinkServices {
    getGlobalLink = (globalLinkId) => {
        return http.get(`/api/v1/globallinks/${globalLinkId}`);
    }

    validationGlobalLink = (validationGlobalLink) => {
        const { id, text } = validationGlobalLink;
        return http.get(`api/v1/globallinks/validation?id=${id}&text=${text}`);
    }

    getAllGlobalLink = () => {
        return http.get(`/api/v1/globallinks`);
    }

    getCustomGlobalLink = (filterValue) => {
        const { pageIndex, pageSize, keyword } = filterValue;
        return http.get(`/api/v1/globallinks/paging?pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}`);
    }

    addGloballLink = (globallink) => { 
        return http.post(`/api/v1/globallinks`, globallink);
     }

    updateGlobalLink = (id, globallink) => { 
        return http.put(`/api/v1/globallinks/${id}`, globallink);
     }
    
    deleteGlobalLink = (id) => { 
        return http.delete(`/api/v1/globallinks/${id}`);
     }
}

const globalLinkServices = new GlobalLinkServices();
export default globalLinkServices;