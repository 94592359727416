import brandServices from "../../services/BrandService";
import { notification } from "../../util/func";
import {
    GET_ALL_BRAND,
    GET_BRAND,
    GET_CUSTOM_BRAND,
    GET_SUBCATEGORIES_BY_BRAND,
    RELOAD_BRAND
} from "../types/BrandType";
import { tableLoadingAction } from "./LoadingAction";

export const getBrandAction = (id) => {
    return (dispatch2) => {
        let promise = brandServices.getBrand(id);
        promise.then((result) => {
            let action2 = {
                type: GET_BRAND,
                brand: result.data.content,
            };
            dispatch2(action2);
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const getAllBrandAction = () => {
    return (dispatch2) => {
        let promise = brandServices.getAllBrand();
        promise.then((result) => {
            let action2 = {
                type: GET_ALL_BRAND,
                allBrand: result.data.content,
            };
            dispatch2(action2);
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const getCustomBrandAction = (filterValue) => {
    return (dispatch2) => {
        dispatch2(tableLoadingAction(true));
        let promise = brandServices.getCustomBrand(filterValue);
        promise.then((result) => {
            let action2 = {
                type: GET_CUSTOM_BRAND,
                customBrand: result.data.content,
            };
            dispatch2(action2);
            dispatch2(tableLoadingAction(false));
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const getCategoriesByBrandAction = (id, categoryId) => {
    return (dispatch2) => {
        let promise = brandServices.getCategoriesByBrand(id, categoryId);
        promise.then((result) => {
            const brand = result.data.content;
            let action2 = {
                type: GET_BRAND,
                brand: brand,
            };
            dispatch2(action2);
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const getSubcategoriesbyBrandAction = (filterValue) => {
    return (dispatch2) => {
        let promise = brandServices.getSubcategoriesByBrand(filterValue)
        promise.then((result) => {
            let action2 = {
                type: GET_SUBCATEGORIES_BY_BRAND,
                subcategoriesbyBrand: result.data.content?.data,
            };
            dispatch2(action2);
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const addBrandAction = (data = {}) => {
    return (dispatch2) => {
        let promise = brandServices.addBrand(data);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_BRAND,
                reloadBrand: true,
            }
            dispatch2(action2);
            notification("Thêm nhà cung cấp thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    }
};

export const updateBrandAction = (id = '', data = {}) => {
    return (dispatch2) => {
        let promise = brandServices.updateBrand(id, data);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_BRAND,
                reloadBrand: true,
            }
            dispatch2(action2);
            notification("Cập nhập nhà cung cấp thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const removeBrandAction = (id = '') => {
    return (dispatch2) => {
        let promise = brandServices.deleteBrand(id);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_BRAND,
                reloadBrand: true,
            }
            dispatch2(action2);
            notification("Xóa nhà cung cấp thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const reloadBrandAction = () => {
    return (dispatch2) => {
        let action2 = {
            type: RELOAD_BRAND,
            reloadBrand: false,
        };
        dispatch2(action2);
    };
};