import {
    GET_FILE,
    GET_CUSTOM_FILE,
    RELOAD_FILE
} from "../types/FileType";

const initialState = {
    customFile: {},
    file: {},
    reloadFile: false,
};

export const FileReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CUSTOM_FILE:
            state.customFile = action.customFile;
            return { ...state };
        case GET_FILE:
            state.file = action.file;
            return { ...state };
        case RELOAD_FILE:
            state.reloadFile = action.reloadFile;
            return { ...state };
        default:
            return state;
    }
};