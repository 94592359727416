import authServices from "../../services/AuthService"
import { LOGIN, USER_INFO } from "../types/AuthType";


export const loginAction = (userInfo) => {
    return (dispatch2) => {
        let promise = authServices.login(userInfo);
        promise.then((result) => {
            let action2 = {
                type: LOGIN,
                token: result.data.content,
            };
            dispatch2(action2);
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const userInfoAction = () => { 
    return (dispatch2) => { 
        let promise = authServices.userInfo();
        promise.then((result) => { 
            let action2 = {
                type: USER_INFO,
                user: result.data.content,
            };
            dispatch2(action2);
         });
         promise.catch((error) => { 
            console.log(error);
          })
     };
 };