import { http } from "../util/config"

class UserService {
    getUser = (id) => {
        return http.get(`/api/v1/users/${id}`);
    }

    validationUser = (validationUser) => {
        const { id, email } = validationUser;
        return http.get(`/api/v1/users/validation?id=${id}&email=${email}`);
    }

    getCustomUser = (filterValue) => {
        const { pageIndex, pageSize, keyword, order, sortBy } = filterValue;
        return http.get(`/api/v1/users/paging?pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}&order=${order}&sortBy=${sortBy}`);
    }

    addUser = (data) => {
        return http.post(`api/v1/users`, data);
    }

    updateUser = (id, data) => {
        return http.put(`/api/v1/users/${id}`, data);
    }

    updateAvatar = (id, data) => {
        return http.put(`/api/v1/users/${id}/avatar`, data);
    }

    deleteUser = (id) => {
        return http.delete(`/api/v1/users/${id}`);
    }
}

const userService = new UserService();
export default userService;