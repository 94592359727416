import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhoneAlt,
  FaCalendarTimes
} from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { removeAccents } from '../../util/func';

export default function Footer() {
  let { allWebInfo } = useSelector(state => state.WebInfoReducer);
  let { allGlobalLink } = useSelector(state => state.GlobalLinkReducer);
  const renderFooterMenu = (data) => {
    return data?.map((el) => {
      return <li key={`footer-menu${el?.id}`}><Link to={removeAccents(el?.href)}><IoIosArrowForward /><span>{el?.text}</span></Link></li>
    })
  };
  return (
    <footer>
      <div className='footer'>
        <Container fluid="xl">
          <Row>
            <Col xs={12} sm={6} md={4} lg={3} xl>
              <div className='footer__logo'>
                <h2 >{parse(String(allWebInfo?.name))}</h2>
              </div>
              <div className='footer__content'>
                <ul className='footer__menu'>
                  <li><Link to="#"><FaMapMarkerAlt /><span>{`${allWebInfo?.location?.address}`}</span></Link></li>
                  <li><Link to={`mailto:${allWebInfo?.email}`}><FaEnvelope /><span>{`${allWebInfo?.email}`}</span></Link></li>
                  <li><Link to={`tel:${allWebInfo?.mobile}`}><FaPhoneAlt /><span>{`${allWebInfo?.mobile}`}</span></Link></li>
                  <li><Link to="#"><FaCalendarTimes /><span>Mở cửa: {`${allWebInfo?.openTime}`}</span></Link></li>
                </ul>
              </div>
            </Col>
            <Col xs={12} sm={6} md={4} lg={3} xl>
              <h3 className='footer__title'>GIỚI THIỆU</h3>
              <div className='footer__content'>
                <ul className='footer__menu'>
                  <li className='hotline'><Link to={`tel:${allWebInfo?.hotline}`}><IoIosArrowForward /><span>Hotline: {`${allWebInfo?.hotline}`}</span></Link></li>
                  {renderFooterMenu(allGlobalLink?.filter((el) => el?.placements.map(elP => elP?.key).includes('footerAbout')))}
                </ul>
              </div>
            </Col>
            <Col xs={12} sm={6} md={4} lg={3} xl>
              <h3 className='footer__title'>CHĂM SÓC KHÁCH HÀNG</h3>
              <div className='footer__content'>
                <ul className='footer__menu'>
                  {renderFooterMenu(allGlobalLink?.filter((el) => el?.placements.map(elP => elP?.key).includes('footerCustomer')))}
                </ul>
              </div>
            </Col>
            <Col xs={12} sm={6} md={4} lg={3} xl>
              <h3 className='footer__title'>THANH TOÁN</h3>
              <div className='footer__content'>
                <ul className='footer__menu'>
                  {renderFooterMenu(allGlobalLink?.filter((el) => el?.placements.map(elP => elP?.key).includes('footerPay')))}
                </ul>
              </div>
            </Col>
            <Col xs={12} sm={6} md={4} lg={3} xl>
              <h3 className='footer__title'>CHÍNH SÁCH/ĐIỀU KHOẢN</h3>
              <div className='footer__content'>
                <ul className='footer__menu'>
                  {renderFooterMenu(allGlobalLink?.filter((el) => el?.placements.map(elP => elP?.key).includes('footerPolicy')))}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='copyright'>Copyright by Q</div>
    </footer>

  )
}
