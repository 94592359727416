import {
    GET_ALL_CATEGORY,
    GET_CUSTOM_CATEGORY,
    GET_CATEGORY,
    RELOAD_CATEGORY,
    GET_SUBCATEGORIES,
} from "../types/CategoryType";

const initialState = {
    allCategory: [],
    subcategories: {},
    customCategory: {},
    category: {},
    reloadCategory: false,
}

export const CategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        //header
        case GET_ALL_CATEGORY:
            state.allCategory = action.allCategory;
            return { ...state };
        //categories
        case GET_SUBCATEGORIES:
            state.subcategories = action.subcategories;
            return { ...state };
        case GET_CUSTOM_CATEGORY:
            state.customCategory = action.customCategory;
            return { ...state };
        case GET_CATEGORY:
            state.category = action.category;
            return { ...state };
        case RELOAD_CATEGORY:
            state.reloadCategory = action.reloadCategory;
            return { ...state };
        default:
            return state;
    }
}