import React from 'react'
import { FaStar, FaRegStar } from "react-icons/fa";

export default function RatingStars(props) {
    const stars = props?.stars;
    const ratingStars = () => {
        let ratingStars = [];
        for (let index = 0; index < 5; index++) {
            let lenght = (stars - index) > 1 ? 100 : (stars - index) < 0 ? 0 : (stars - index) * 100;
            ratingStars.push(
                <div className='rating__stars__warpper' key={`rating-stars${index}`}>
                    <div className='rating__stars__lit' style={{ width: `${lenght}%` }}>
                        <FaStar />
                    </div>
                    <FaRegStar />
                </div>
            )
        }
        return ratingStars
    }
    return (
        <div className='rating__stars'>
            {ratingStars()}
        </div>
    )
}
