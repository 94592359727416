import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeTwoTone, PlusOutlined } from '@ant-design/icons';
import { Upload, Form, Input, Button, DatePicker, Select } from 'antd';
import { addUserAction } from '../../../redux/actions/UserAction';
import userService from '../../../services/UserService';

export default function AddUser() {
    const { reloadUser } = useSelector(state => state.UserReducer);
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            displayName: '',
            email: '',
            password: '',
            phone: '',
            avatar: {},
            address: '',
            birthday: '',
            gender: true,
            role: "user",
        },
        validationSchema: Yup.object({
            displayName: Yup.string().required("Tên không được để trống").matches(/^[A-Z a-z]+$/, "Tên không đúng định dạng"),
            email: Yup.string().required("Email không được để trống").email("Email chưa đúng định dạng")
                .test("Email", "Email đã tồn tại", async (email) => {
                    let check = await userService.validationUser({ email: email }).then((result) => {
                        return result.data.content;
                    });
                    return !check;
                }),
            password: Yup.string().required("Mật khẩu không được để trống").matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{0,}$/, "Mật khẩu phải bao gồm ký tự in hoa, ký tự đặc biệt và số"),
            address: Yup.string().required("Địa chỉ không được để trống"),
            phone: Yup.string().required("Số điện thoại không được để trống").matches(/(((\+|)84)|0)(3|5|7|8|9)+([0-9]{8})\b/, "Số điện thoại không đúng định dạng"),
            birthday: Yup.string().required("Ngày sinh không được để trống"),
        }),
        onSubmit: values => {
            let action = addUserAction(values);
            dispatch(action);
        },
    });

    const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

    useEffect(() => {
        if (reloadUser !== false) {
            navigate('/admin/users');
        }
        // eslint-disable-next-line
    }, [reloadUser]);

    //upload image
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const updateAvatarAPI = (file) => {
        setFieldValue('avatar',
            {
                uid: file.uid,
                name: file.name,
                originFileObj: file,
                status: 'done',
            }
        );
        return false;
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    return (
        <div>
            <h2 >Thêm người dùng</h2>
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }}
                layout="horizontal"
                onFinish={handleSubmit}
                initialValues={values}
            >
                <Form.Item label="Tên hiển thị" name="displayName"
                    validateStatus={touched.displayName && errors.displayName ? "error" : "success"}
                    help={touched.displayName && errors.displayName ? (errors.displayName) : null}>
                    <Input onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Email" name="email"
                    validateStatus={touched.email && errors.email ? "error" : "success"}
                    help={touched.email && errors.email ? (errors.email) : null}>
                    <Input onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Mật khẩu" name="password"
                    validateStatus={touched.password && errors.password ? "error" : "success"}
                    help={touched.password && errors.password ? (errors.password) : null}>
                    <Input.Password
                        onChange={handleChange}
                        onBlur={handleBlur}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                <Form.Item label="Số điện thoại" name="phone"
                    validateStatus={touched.phone && errors.phone ? "error" : "success"}
                    help={touched.phone && errors.phone ? (errors.phone) : null}>
                    <Input onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Địa chỉ" name="address"
                    validateStatus={touched.address && errors.address ? "error" : "success"}
                    help={touched.address && errors.address ? (errors.address) : null}>
                    <Input onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Sinh nhật" name="birthday"
                    validateStatus={touched.birthday && errors.birthday ? "error" : "success"}
                    help={touched.birthday && errors.birthday ? (errors.birthday) : null}>
                    <DatePicker onChange={(date, dateString) => setFieldValue('birthday', dateString)} format='DD/MM/YYYY' onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Avatar" valuePropName="fileList">
                    <Upload
                        customRequest={dummyRequest}
                        listType="picture-card"
                        fileList={
                            Object.keys(values?.avatar || {})?.length ?
                                [values?.avatar] : []
                        }
                        onRemove={() => setFieldValue('avatar', {})}
                        beforeUpload={updateAvatarAPI}
                        onPreview={onPreview}
                    >
                        {Object.keys(values?.avatar || {})?.length ? null : uploadButton}
                    </Upload>
                </Form.Item>
                <Form.Item label="Giới tính" name="gender" >
                    <Select
                        style={{ width: 120 }}
                        onChange={(value) => setFieldValue('gender', value)}
                        options={[
                            {
                                value: true,
                                label: 'Nam',
                            },
                            {
                                value: false,
                                label: 'Nữ',
                            },

                        ]}
                    />
                </Form.Item>
                <Form.Item label="Phân quyền" name="role">
                    <Select
                        style={{ width: 120 }}
                        onChange={(value) => setFieldValue('role', value)}
                        options={[
                            {
                                value: 'admin',
                                label: 'ADMIN',
                            },
                            {
                                value: 'editor',
                                label: 'EDITOR',
                            },
                            {
                                value: 'salesman',
                                label: 'SALESMAN',
                            },
                            {
                                value: 'user',
                                label: 'USER',
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item label="Hành động">
                    <Button type="primary" htmlType="submit">Thêm</Button>
                </Form.Item>
            </Form>
        </div>
    )
}