import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Upload, TreeSelect, Select, Form, Input, InputNumber, Button, Checkbox, Space, Modal } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import { addProductAction } from '../../../redux/actions/ProductAction';
import { uploadFile } from '../../../util/func';
import productServices from '../../../services/ProductService';
import { getAllCategoryAction } from '../../../redux/actions/CategoryAction';
import { getAllBrandAction } from '../../../redux/actions/BrandAction';
import { getAllAtributeAction } from '../../../redux/actions/AttributeAction';
import { getAllPromotionAction } from '../../../redux/actions/PromotionAction';

const { TextArea } = Input;

export default function AddProduct() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTinymce, setIsTinymce] = useState(false);
  const [detailKey, setDetailKey] = useState(-1);
  const [detailImageId, setDetailImageId] = useState(0);
  const editorRef = useRef(null);
  const [form] = Form.useForm();
  const { reloadProduct } = useSelector(state => state.ProductReducer);
  const { allCategory } = useSelector(state => state.CategoryReducer);
  const { allBrand } = useSelector(state => state.BrandReducer);
  const { allAttribute } = useSelector(state => state.AttributeReducer);
  const { allPromotion } = useSelector(state => state.PromotionReducer);

  let dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: '',
      images: [],
      amount: 0,
      price: 0,
      secondHand: false,
      contact: false,
      shortDescription: '',
      categoryId: '',
      brandId: '',
      catalog: {},
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Tên không được để trống")
        .test("Tên", "Tên đã tồn tại", async (name) => {
          let check = await productServices.validationProduct({ name: name }).then((result) => {
            return result.data.content;
          });
          return !check;
        }),
      categoryId: Yup.string().required("Bạn chưa lựa chọn danh mục"),
      brandId: Yup.string().required("Bạn chưa lựa chọn nhà cung cấp"),
      amount: Yup.number().typeError("Số lượng sản phẩm không phải dạng số").min(0, "Số lượng sản phẩm không hợp lệ"),
      price: Yup.number().typeError("Giá sản phẩm không phải dạng số").min(0, "Số lượng sản phẩm không hợp lệ"),
    }),

    onSubmit: values => {
      const data = {
        product: values,
        productDetails: form.getFieldValue('productDetails'),
        promotions: form.getFieldValue('promotions'),
        attributes: form.getFieldValue('attributes')
      }
      let action = addProductAction(data);
      dispatch(action);
    },
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

  useEffect(() => {
    getAllCategoryAPI();
    getAllBrandAPI();
    getAllAtributeAPI();
    getAllPromotionAPI();
  }, []);

  useEffect(() => {
    if (reloadProduct !== false) {
      navigate('/admin/products');
    }
    // eslint-disable-next-line
  }, [reloadProduct]);

  useEffect(() => {
    if (isTinymce === true) {
      if (editorRef.current) {
        const content = form.getFieldValue('productDetails')[detailKey]?.content;
        if (content) {
          editorRef.current.setContent(content)
        }
        else {
          editorRef.current.resetContent();
        }
      }
    }
  }, [isTinymce, detailKey]);

  useEffect(() => {
    if (detailImageId > 0) {
      const productDetails = form.getFieldValue('productDetails');
      let arrFile = productDetails[detailKey]?.files ? productDetails[detailKey]?.files : [];
      arrFile.push(detailImageId);
      productDetails[detailKey] = {
        ...productDetails[detailKey],
        files: arrFile
      }
      form.setFieldValue('productDetails', productDetails);
    }
  }, [detailImageId])

  //control modal
  const showModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getAllCategoryAPI = () => {
    let action = getAllCategoryAction();
    dispatch(action);
  }

  const getAllBrandAPI = () => {
    let action = getAllBrandAction();
    dispatch(action);
  }

  const getAllAtributeAPI = () => {
    let action = getAllAtributeAction();
    dispatch(action);
  }

  const getAllPromotionAPI = () => {
    let action = getAllPromotionAction();
    dispatch(action);
  }

  const treeCategories = (data) => {
    let tree = []
    for (const el of data) {
      if ((el?.childes || []).length) {
        tree.push({ title: el?.name, value: el?.id, children: treeCategories(el.childes) });
      }
      else {
        tree.push({ label: el?.name, value: el?.id })
      }
    }
    return tree;
  }

  const uploadProductDetailImageAPI = (blobInfo, progress) => new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.blob()?.name);
    let image = uploadFile(formData);
    resolve(image);
  }).then((result) => {
    setDetailImageId(result.id);
    return result.url;
  });

  //upload image
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const updateFileProductAPI = async (file) => {
    let formData = new FormData();
    formData.append('file', file, file.name);
    let data = await uploadFile(formData);
    if (file.type === "application/pdf") {
      setFieldValue('catalog', { uid: data.id, url: data.url });
    }
    else{
      let images = values.images;
      images.push({ uid: data.id, url: data.url });
      setFieldValue('images', images);
    }
    return false;
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <div>
      <h2 >Thêm sản phẩm</h2>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={handleSubmit}
        form={form}
        initialValues={values}
      >
        <Form.Item label="Tên" name="name"
          help={touched.name && errors.name ? errors.name : null}
          validateStatus={touched.name && errors.name ? "error" : "success"}
        >
          <Input onChange={handleChange} onBlur={handleBlur} />
        </Form.Item>
        <Form.Item label="Danh mục" name="categoryId"
          help={touched.categoryId && errors.categoryId ? errors.categoryId : null}
          validateStatus={touched.categoryId && errors.categoryId ? "error" : "success"}
        >
          <TreeSelect
            placeholder="Vui lòng lựa chọn"
            showSearch
            style={{
              width: '100%',
            }}
            value={values.parentId}
            dropdownStyle={{
              maxHeight: 400,
              overflow: 'auto',
            }}
            allowClear
            treeDefaultExpandAll
            onChange={(value) => setFieldValue('categoryId', value)}
            treeData={[{ title: 'Danh mục gốc', value: 0, children: treeCategories(allCategory) }]}
          />
        </Form.Item>
        <Form.Item label="Nhà cung cấp" name="brandId"
          help={touched.brandId && errors.brandId ? errors.brandId : null}
          validateStatus={touched.brandId && errors.brandId ? "error" : "success"}
        >
          <Select
            placeholder="Vui lòng lựa chọn"
            onChange={(value) => setFieldValue('brandId', value)}
            options={allBrand?.map((el) => ({ label: el?.name, value: el?.id }))}
          />
        </Form.Item>
        <Form.Item label="Hình ảnh" valuePropName="imageList">
          <Upload
            customRequest={dummyRequest}
            listType="picture-card"
            fileList={values?.images?.map((data) => {
              return {
                uid: data?.uid,
                name: `image${data?.uid}`,
                status: 'done',
                url: data?.url,
              }
            })}
            onRemove={(value) => {
              let images = values.images;
              setFieldValue('images', images.filter((el) => el.uid !== value.uid));
            }}
            beforeUpload={updateFileProductAPI}
            onPreview={onPreview}
          >
            {values?.images?.length >= 8 ? null : uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item label="Số lượng" name="amount"
          help={touched.amount && errors.amount ? errors.amount : null}
          validateStatus={touched.amount && errors.amount ? "error" : "success"}
        >
          <InputNumber min={0} max={100000} style={{ width: '50%' }} onChange={(value) => setFieldValue('amount', value)} onBlur={handleBlur} />
        </Form.Item>
        <Form.Item label="Giá tiền" name="price"
          help={touched.price && errors.price ? errors.price : null}
          validateStatus={touched.price && errors.price ? "error" : "success"}
        >
          <InputNumber min={0} style={{ width: '50%' }} onChange={(value) => setFieldValue('price', value)} />
        </Form.Item>
        <Form.Item label="Đồ cũ" name="secondHand" valuePropName="checked">
          <Checkbox onChange={handleChange} onBlur={handleBlur} />
        </Form.Item>
        <Form.Item label="Ẩn giá bán" name="contact" valuePropName="checked">
          <Checkbox onChange={handleChange} onBlur={handleBlur} />
        </Form.Item>
        <Form.Item label="Mô tả ngắn" name="shortDescription">
          <TextArea onChange={handleChange} onBlur={handleBlur} />
        </Form.Item>
        <Form.Item label="Mô tả ">
          <Form.List name="productDetails">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex' }} align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'title']}
                      rules={[{ required: true, message: 'Chưa đặt tiêu đề' }]}
                    >
                      <Input placeholder="Tiêu đề" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'content']}
                      rules={[{ required: true, message: 'Chưa có nội dung' }]}
                    >
                      <Button onClick={() => {
                        showModal();
                        setDetailKey(name);
                      }}>
                        Thêm nội dung
                      </Button>
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Thêm danh mục mô tả
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item label="Thuộc tính">
          <Form.List name="attributes">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex' }} align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'id']}
                      rules={[{ required: true, message: 'Chưa chọn thuộc tính' }]}
                    >
                      <Select
                        options={allAttribute?.map((el) => ({ label: el?.name, value: el?.id }))}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'value']}
                      rules={[{ required: true, message: 'Chưa có giá trị' }]}
                    >
                      <Input placeholder="Giá trị" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Thêm thuộc tính
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item label="Khuyến mãi" name="promotions">
          <Select
            mode="multiple"
            allowClear
            placeholder="Vui lòng lựa chọn"
            options={allPromotion?.map((el) => ({ label: el?.code, value: el?.id }))}
          />
        </Form.Item>
        <Form.Item label="Tài liệu" valuePropName="fileList">
          <Upload
            customRequest={dummyRequest}
            beforeUpload={updateFileProductAPI}
          >
            {Object.keys(values?.catalog || {})?.length ? null : <Button icon={<UploadOutlined />}>Nhấn vào để tải lên</Button>}
          </Upload>
        </Form.Item>
        <Form.Item label="Hành động">
          <Button type="primary" htmlType="submit">Thêm</Button>
        </Form.Item>
      </Form>
      <Modal title="Chi tiết sản phẩm"
        centered
        width={1000}
        open={isModalOpen}
        onOk={() => {
          if (editorRef.current) {
            const productDetails = form.getFieldValue('productDetails');
            productDetails[detailKey] = {
              ...productDetails[detailKey],
              content: editorRef.current.getContent(),
            }
            form.setFieldValue('productDetails', productDetails);
          }
          closeModal();
        }}
        onCancel={closeModal}>
        <Editor
          apiKey='a68gdz94s7gbdrmzq6n8h99gw0yr98wxwmpfd7rfk84hdpkt'
          init={{
            height: "80vh",
            menubar: true,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            automatic_uploads: true,
            images_upload_handler: uploadProductDetailImageAPI,
            file_picker_types: 'image',
            forced_root_block: 'div'
          }}
          onInit={(evt, editor) => {
            editorRef.current = editor;
            setIsTinymce(editor.initialized);
          }}
        />
      </Modal>
    </div>
  )
}