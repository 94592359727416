import React from 'react';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

export default function Post() {
  const { postUrl } = useParams();
    return (
        <div>
            <Container fluid='lg'>
                {postUrl}
            </Container>
        </div>
    )
}
