import categoryServices from "../../services/CategoryService";
import { notification } from "../../util/func";
import {
    GET_ALL_CATEGORY,
    GET_CUSTOM_CATEGORY,
    GET_CATEGORY,
    RELOAD_CATEGORY,
    GET_SUBCATEGORIES,
} from "../types/CategoryType";
import { tableLoadingAction } from "./LoadingAction";

export const getAllCategoryAction = () => {
    return (dispatch2) => {
        let promise = categoryServices.getAllCategory();
        promise.then((result) => {
            let action2 = {
                type: GET_ALL_CATEGORY,
                allCategory: result.data.content,
            };
            dispatch2(action2);
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const getCustomCategoryAction = (filterValue) => {
    return (dispatch2) => {
        dispatch2(tableLoadingAction(true));
        let promise = categoryServices.getCustomCategory(filterValue);
        promise.then((result) => {
            let action2 = {
                type: GET_CUSTOM_CATEGORY,
                customCategory: result.data.content,
            };
            dispatch2(action2);
            dispatch2(tableLoadingAction(false));
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const getCategoryAction = (id) => {
    return (dispatch2) => {
        let promise = categoryServices.getCategory(id);
        promise.then((result) => {
            let action2 = {
                type: GET_CATEGORY,
                category: result.data.content,
            };
            dispatch2(action2);
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const getBrandsByCategoryAction = (id) => {
    return (dispatch2) => {
        let promise = categoryServices.getBrandsByCategory(id);
        promise.then((result) => {
            const category = result.data.content;
            let action2 = {
                type: GET_CATEGORY,
                category: category,
            };
            dispatch2(action2);
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const getSubcategoriesAction = (filterValue) => {
    return (dispatch2) => {
        let promise = categoryServices.getFirstChildsCategory(filterValue)
        promise.then((result) => {
            let action2 = {
                type: GET_SUBCATEGORIES,
                subcategories: result.data.content,
            };
            dispatch2(action2);
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const addCategoryAction = (data = {}) => {
    return (dispatch2) => {
        let promise = categoryServices.addCategory(data);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_CATEGORY,
                reloadCategory: true,
            }
            dispatch2(action2);
            notification("Thêm nhóm sản phẩm thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    }
};

export const updateCategoryAction = (id = '', data = {}) => {
    return (dispatch2) => {
        let promise = categoryServices.updateCategory(id, data);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_CATEGORY,
                reloadCategory: true,
            }
            dispatch2(action2);
            notification("Cập nhập nhóm sản phẩm thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const removeCategoryAction = (id = '') => {
    return (dispatch2) => {
        let promise = categoryServices.deleteCategory(id);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_CATEGORY,
                reloadCategory: true,
            }
            dispatch2(action2);
            notification("Xóa nhóm sản phẩm thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const reloadCategoryAction = () => {
    return (dispatch2) => {
        let action2 = {
            type: RELOAD_CATEGORY,
            reloadCategory: false,
        }
        dispatch2(action2);
    };
};