import { http } from "../util/config";

class ProductServices {
    getProduct = (id) => { 
        return http.get(`/api/v1/products/${id}`);
    }

    validationProduct = (validationProduct) => {
        const { id, name } = validationProduct;
        return http.get(`api/v1/products/validation?id=${id}&name=${name}`);
    }

    searchProduct = (searchWord) => { 
        return http.get(`/api/v1/products/search/${searchWord}`);
    }

    getProductsByParent = (filterValue) => { 
        const { categoryId, brandId, pageIndex, pageSize, keyword, old, order, sortBy } = filterValue;
        return http.get(`/api/v1/products/parent/category/${categoryId}/brand/${brandId}?pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}&old=${old}&order=${order}&sortBy=${sortBy}`);
     }

    getCustomProduct = (filterValue) => {
        const { pageIndex, pageSize, keyword, old, order, sortBy } = filterValue;
        return http.get(`/api/v1/products/paging?pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}&old=${old}&order=${order}&sortBy=${sortBy}`);
    }

    addProduct = (data) => {
        return http.post(`/api/v1/products`, data);
    }

    updateProduct = (id, data) => {
        return http.put(`/api/v1/products/${id}`, data);
    }

    deleteProduct = (id) => {
        return http.delete(`/api/v1/products/${id}`);
    }

    updateViewsProduct = (id) => {
        return http.put(`/api/v1/products/${id}/views`);
    }
}

const productServices = new ProductServices();
export default productServices;
