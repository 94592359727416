import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { BrandReducer } from "./reducers/BrandReducer";
import { CategoryReducer } from "./reducers/CategoryReducer";
import { ProductReducer } from "./reducers/ProductReducer";
import { DoubleTabsReducer } from "./reducers/DoubleTabsReducer";
import { AuthReducer } from "./reducers/AuthReducer";
import { UserReducer } from "./reducers/UserReducer";
import { LoadingReducer } from "./reducers/LoadingReducer";
import { MediaReducer } from "./reducers/MediaReducer";
import { GlobalLinkReducer } from "./reducers/GlobalLinkReducer";
import { PostReducer } from "./reducers/PostReducer";
import { FileReducer } from "./reducers/FileReducer";
import { WebInfoReducer } from "./reducers/WebInfosReducer";
import { AttributeReducer } from "./reducers/AttributeReducer";
import { PlacementReducer } from "./reducers/PlacementReducer";
import { PromotionReducer } from "./reducers/PromotionReducer";

const rootReducer = combineReducers({
    LoadingReducer,
    DoubleTabsReducer,
    MediaReducer,
    WebInfoReducer,
    GlobalLinkReducer,
    PostReducer,
    PlacementReducer,
    AuthReducer,
    UserReducer,
    CategoryReducer,
    BrandReducer,
    AttributeReducer,
    PromotionReducer,
    ProductReducer,
    FileReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));