import postServices from "../../services/PostService";
import { notification } from "../../util/func";
import {
    GET_CUSTOM_POST,
    GET_POST,
    RELOAD_POST
} from "../types/PostType";
import { tableLoadingAction } from "./LoadingAction";

export const getPostAction = (id) => {
    return (dispatch2) => {
        let promise = postServices.getPost(id);
        promise.then((result) => {
            let action2 = {
                type: GET_POST,
                post: result.data.content,
            };
            dispatch2(action2);
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const getCustomPostAction = (filterValue) => {
    return (dispatch2) => {
        dispatch2(tableLoadingAction(true));
        let promise = postServices.getCustomPost(filterValue);
        promise.then((result) => {
            let action2 = {
                type: GET_CUSTOM_POST,
                customPost: result.data.content,
            };
            dispatch2(action2);
            dispatch2(tableLoadingAction(false));
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};


export const addPostAction = (data = {}) => {
    return (dispatch2) => { 
        let promise = postServices.addPost(data);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_POST,
                reloadPost: true,
            }
            dispatch2(action2);
            notification("Thêm bài viết thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
     }
};

export const updatePostAction = (id = '', data = {}) => {
    return (dispatch2) => {
        let promise = postServices.updatePost(id, data);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_POST,
                reloadPost: true,
            }
            dispatch2(action2);
            notification("Cập nhập bài viết thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const removePostAction = (id = '') => {
    return (dispatch2) => {
        let promise = postServices.deletePost(id);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_POST,
                reloadPost: true,
            }
            dispatch2(action2);
            notification("Xóa bài viết thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const reloadPostAction = () => {
    return (dispatch2) => {
        let action2 = {
            type: RELOAD_POST,
            reloadPost: false,
        }
        dispatch2(action2);
    };
};