import { http } from "../util/config";

class PlacementServices {
    getPlacement = (id) => {
        return http.get(`/api/v1/placements/${id}`);
    }

    validationPlacement = (validationPlacement) => {
        const { id, key } = validationPlacement;
        return http.get(`/api/v1/placements/validation?id=${id}&key=${key}`);
    }

    getAllPlacement = () => {
        return http.get(`/api/v1/placements`);
    }

    getCustomPlacement = (filterValue) => {
        const { pageIndex, pageSize, keyword } = filterValue;
        return http.get(`/api/v1/placements/paging?pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}`);
    }

    addPlacement = (data) => {
        return http.post(`/api/v1/placements`, data);
    }

    updatePlacement = (id, data) => {
        return http.put(`/api/v1/placements/${id}`, data);
    }

    deletePlacement = (id) => {
        return http.delete(`/api/v1/placements/${id}`);
    }
}

const placementServices = new PlacementServices();
export default placementServices;