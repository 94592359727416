import fileServices from "../../services/FileService";
import { notification } from "../../util/func";
import { 
    GET_FILE, 
    GET_CUSTOM_FILE, 
    RELOAD_FILE 
} from "../types/FileType";

import { tableLoadingAction } from "./LoadingAction";

export const getCustomFileAction = (filterValue) => {
    return (dispatch2) => {
        dispatch2(tableLoadingAction(true));
        let promise = fileServices.getCustomFile(filterValue);
        promise.then((result) => {
            let action2 = {
                type: GET_CUSTOM_FILE,
                customFile: result.data.content,
            };
            dispatch2(action2);
            dispatch2(tableLoadingAction(false));
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const getFileAction = (id) => {
    return (dispatch2) => {
        let promise = fileServices.getFile(id);
        promise.then((result) => {
            let action2 = {
                type: GET_FILE,
                file: result.data.content,
            };
            dispatch2(action2);
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const addFileAction = (data = []) => {
    return (dispatch2) => {
        let promise = fileServices.addFile(data);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_FILE,
                reloadFile: true,
            }
            dispatch2(action2);
            notification("Thêm file thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const updateFileAction = (id = '', data = []) => {
    return async (dispatch2) => {
        let promise = fileServices.updateFile(id, data);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_FILE,
                reloadFile: true,
            }
            dispatch2(action2);
            notification("Cập nhập file thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const removeFileAction = (id = '') => {
    return (dispatch2) => {
        let promise = fileServices.deleteFile(id);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_FILE,
                reloadFile: true,
            }
            dispatch2(action2);
            notification("Xóa file thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const reloadFileAction = () => {
    return (dispatch2) => {
        let action2 = {
            type: RELOAD_FILE,
            reloadFile: false,
        }
        dispatch2(action2);
    };
};