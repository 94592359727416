import { http } from "../util/config";

class WebInfosService {
    getWebInfo = (id) => {
        return http.get(`/api/v1/webinfos/${id}`);
    }

    validationWebInfo = (validationInfo) => {
        const { id, key } = validationInfo;
        return http.get(`api/v1/webinfos/validation?id=${id}&key=${key}`);
    }

    getAllWebInfo = () => {
        return http.get(`/api/v1/webinfos`);
    }

    getCustomWebInfo = (filterValue) => {
        const { pageIndex, pageSize, keyword } = filterValue;
        return http.get(`/api/v1/webinfos/paging?pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}`);
    }

    addWebInfo = (info) => {
        return http.post(`/api/v1/webinfos`, info);
    }

    updateWebInfo = (id, info) => {
        return http.put(`/api/v1/webinfos/${id}`, info);
    }

    updateFile = (id, data) => {
        return http.put(`/api/v1/webinfos/${id}/file`, data);
    }

    deleteWebInfo = (id) => {
        return http.delete(`/api/v1/webinfos/${id}`);
    }
}

const webInfosService = new WebInfosService();
export default webInfosService;