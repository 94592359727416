import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import placementServices from '../../../services/PlacementService';
import { addPlacementAction } from '../../../redux/actions/PlacementAction';

export default function AddPlacement() {
    const { reloadPlacement } = useSelector(state => state.PlacementReducer);
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            name: '',
            key: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Tên không được để trống"),
            key: Yup.string().required("Vị trí đặt được để trống")
                .test("Vị trí đặt", "Vị trí đặt đã tồn tại", async (key) => {
                    let check = await placementServices.validationPlacement({ key: key }).then((result) => {
                        return result.data.content;
                    });
                    return !check;
                }),
        }),
        onSubmit: values => {
            let action = addPlacementAction(values);
            dispatch(action);
        },
    });

    const { values, touched, errors, handleChange, handleBlur, handleSubmit } = formik;

    useEffect(() => {
        if (reloadPlacement !== false) {
            navigate('/admin/placements');
        }
        // eslint-disable-next-line
    }, [reloadPlacement]);

    return (
        <div>
            <h2 >Thêm vị trí đặt</h2>
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} layout="horizontal"
                onFinish={handleSubmit}
                initialValues={values}
            >
                <Form.Item label="Tên" name="name"
                    validateStatus={touched.name && errors.name ? "error" : "success"}
                    help={touched.name && errors.name ? (errors.name) : null}>
                    <Input onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Khóa chính" name="key"
                    validateStatus={touched.key && errors.key ? "error" : "success"}
                    help={touched.key && errors.key ? (errors.key) : null}>
                    <Input onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Hành động">
                    <Button type="primary" htmlType="submit">Thêm</Button>
                </Form.Item>
            </Form>
        </div>
    );
};