import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Banner from './Banner'
import Category from '../../components/Category/Category';
import FilterTabs from '../../components/FilterTabs/FilterTabs';
import About from './About';
import { Container } from 'react-bootstrap';
import { getCustomAboutAction } from '../../redux/actions/MediaAction';
import { getCustomCategoryAction } from '../../redux/actions/CategoryAction';
import { filterProductsAction } from '../../redux/actions/ProductAction';
import { useState } from 'react';

export default function Home() {
  let dispatch = useDispatch();
  const { allWebInfo } = useSelector(state => state.WebInfoReducer);
  const { customAbout } = useSelector(state => state.MediaReducer);
  const { customCategory } = useSelector(state => state.CategoryReducer);
  const { filterProducts } = useSelector(state => state.ProductReducer);
  const [keySalesTab, setKeySalesTab] = useState('hot')
  const [keyNewTab, setKeyNewTab] = useState('new')

  const salesTabs = {
    name: 'sales',
    tabs: [
      {
        eventKey: 'hot',
        title: 'BÁN CHẠY',
        filterValue: { order: 'DESC', sortBy: 'sold', old: 0 }
      },
      {
        eventKey: 'cheap',
        title: 'GIÁ TỐT',
        filterValue: { order: 'ASC', sortBy: 'price', old: 0 }
      }
    ],
    allHref: '/products',
  }

  const newTabs = {
    name: 'new',
    tabs: [
      {
        eventKey: 'new',
        title: 'SẢN PHẨM MỚI',
        filterValue: { order: 'DESC', sortBy: 'createdAt', old: 0 }
      },
      {
        eventKey: 'old',
        title: 'ĐỒ CŨ',
        filterValue: { order: 'DESC', sortBy: 'viewed', old: 1 }
      }
    ],
    allHref: '/products',
  }

  const salesProducts = filterProducts.find((el) => el.name === salesTabs?.name);
  const newProducts = filterProducts.find((el) => el.name === newTabs?.name);

  useEffect(() => {
    if (allWebInfo?.name) {
      document.title = allWebInfo?.name;
    }
  }, [allWebInfo?.name]);

  useEffect(() => {
    getCustomCategoryAPI();
    getCustomAboutAPI();
  }, []);

  useEffect(() => {
    filterProductsAPI(salesTabs, keySalesTab);
  }, [keySalesTab]);

  useEffect(() => {
    filterProductsAPI(newTabs, keyNewTab);
  }, [keyNewTab]);

  const filterProductsAPI = (filterParameter, keyTab) => {
    let tabValue = (filterParameter?.tabs || []).find((el) => el?.eventKey === keyTab)?.filterValue
    let filterValue = {
      pageIndex: 1,
      pageSize: 15,
      keyword: '',
      old: 0
    }
    filterValue = Object.assign(filterValue, tabValue ? tabValue : {});
    let action = filterProductsAction(filterParameter?.name, filterValue);
    dispatch(action);
  }

  const getCustomCategoryAPI = () => {
    let filterValue = {
      parentId: 0,
      pageIndex: 1,
      pageSize: 30,
      keyword: '',
      order: 'DESC',
      sortBy: 'viewed'
    }
    let action = getCustomCategoryAction(filterValue);
    dispatch(action);
  }

  let getCustomAboutAPI = () => {
    let filterValue = {
      pageIndex: 1,
      placement: 'homeAbout',
      type: 'banner',
    }
    let action = getCustomAboutAction(filterValue);
    dispatch(action);
  }

  return (
    <div className='home'>
      <Banner />
      <Container fluid="xl">
        {(customCategory?.data || []).length ? <Category customCategory={customCategory?.data} /> : ''}
        <FilterTabs
          tabValues={salesTabs}
          defaultActiveKey= {keySalesTab}
          selectedTab={(data) => setKeySalesTab(data?.activeKey)}
          content={salesProducts?.products}
        />
        <FilterTabs 
          tabValues={newTabs}
          defaultActiveKey= {keyNewTab}
          selectedTab={(data) => setKeyNewTab(data?.activeKey)}
          content={newProducts?.products}
        />
      </Container>
      {(customAbout?.data || []).length ? <About customAbout={customAbout?.data} /> : ''}
    </div>
  )
}
