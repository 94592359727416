import { 
    TABLE_LOADING 
} from "../types/LoadingType";

const initialState = {
    tableLoading: false,
}

export const LoadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case TABLE_LOADING:
            state.tableLoading = action.tableLoading;
            return { ...state };
        default:
            return state;
    }
}