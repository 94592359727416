import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import { Form, Input, Button, Table } from 'antd';

import { showDetail } from '../../../util/func';
import {
  getCustomMediaAction,
  reloadMediaAction,
  removeMediaAction
} from '../../../redux/actions/MediaAction';


export default function Media() {
  const [searchName, setSearchName] = useState('');
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  let { customMedia, reloadMedia } = useSelector(state => state.MediaReducer);
  let { tableLoading } = useSelector(state => state.LoadingReducer);
  let dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    getCustomMediaAPI();
    // eslint-disable-next-line
  }, [JSON.stringify(tableParams), searchName]);

  useEffect(() => {
    if (reloadMedia !== false) {
      getCustomMediaAPI();
      let action = reloadMediaAction();
      dispatch(action);
    }
    // eslint-disable-next-line
  }, [reloadMedia]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a?.id - b?.id,
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'image',
      width: '20%',
      render: (t, r) => <img style={{ maxWidth: '150px', maxHeight: '100px' }} src={`${r?.image?.url}`} alt={`${r?.title}`} />
    },
    {
      title: 'Mô tả',
      dataIndex: 'content',
      render: (t, r) => <Button type="primary" onClick={() => showDetail(r?.content)}>
        Xem chi tiết
      </Button>
    },
    {
      title: 'Vị trí đặt',
      dataIndex: 'placements',
      render: (t, r) => (r?.placements || []).map((el) => `${el?.name}; `)
    },
    {
      title: 'Loại media',
      dataIndex: 'type',
    },
    {
      title: 'Hàng động',
      dataIndex: '',
      width: '10%',
      render: (t, r) => <div>
        <EditFilled style={{ fontSize: '16px', color: '#1677ff', marginRight: '10px' }} onClick={() => {
          navigate(`/admin/edit-media/${r?.id}`);
        }} />
        <DeleteFilled style={{ fontSize: '16px', color: '#ff4d4f' }} onClick={() => {
          let action = removeMediaAction(r?.id);
          dispatch(action);
        }} />
      </div>,
    },
  ];

  const handleTableChange = (pagination, sorter) => {
    setTableParams({
      pagination,
      ...sorter,
    });
    // `dataSource` is useless since `pageSize` changed
    if (pagination?.pageSize !== tableParams.pagination?.pageSize) {
      customMedia.data = [];
    }
  };

  const validate = values => {
    setSearchName(values.name.trim());
  };

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validate,
    onSubmit: values => {
      setSearchName(values.name.trim());
    },
  });

  const { handleChange, handleSubmit } = formik;

  let getCustomMediaAPI = () => {
    let { current, pageSize } = tableParams.pagination;
    let filterValue = {
      pageIndex: current,
      pageSize: pageSize,
      placement: searchName,
      type: '',
    }
    let action = getCustomMediaAction(filterValue);
    dispatch(action);
  }

  return (
    <div>
      <h2 >Quản lý Media</h2>
      <Button type="primary" style={{ marginBottom: '20px' }} onClick={() => {
        navigate('/admin/add-media');
      }}>Thêm media</Button>
      <Form layout="horizontal" onFinish={handleSubmit}>
        <Form.Item name="name" style={{ display: 'inline-block', width: '80%' }}>
          <Input onChange={handleChange} placeholder="Tìm kiếm theo vị trí đặt" />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block', marginLeft: '10px' }}>
          <Button type="default" htmlType="submit">Tìm kiếm</Button>
        </Form.Item>
      </Form>
      <Table rowKey='id' columns={columns} dataSource={customMedia?.data}
        pagination={{
          current: customMedia?.pageIndex,
          pageSize: customMedia?.pageSize,
          total: customMedia?.totalRow
        }}
        loading={tableLoading}
        onChange={handleTableChange} />
    </div>
  )
}