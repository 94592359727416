import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Upload, Form, Input, Button, Select, Space } from 'antd';
import webInfosServices from '../../../services/WebInfosService';
import { 
  getWebInfoAction, 
  updateFileWebInfoAction, 
  updateWebInfoAction 
} from '../../../redux/actions/WebInfosAction';



const { TextArea } = Input;

export default function EditWebInfo() {
  const { id } = useParams();
  const { webInfo, reloadWebInfo } = useSelector(state => state.WebInfoReducer);
  const [form] = Form.useForm();
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: '',
      key: '',
      type: 'text',
      value: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Tên không được để trống"),
      type: Yup.string().required("Bạn chưa chọn loại dữ liệu"),
      key: Yup.string().required("Khóa chính không được để trống")
        .test("khóa chính", "khóa chính đã tồn tại", async (key) => {
          let checkValue = {
            id: webInfo?.id,
            key: key
          }
          let result = await webInfosServices.validationWebInfo(checkValue).then((result) => {
            return result.data.content;
          });
          return !result;
        }),
    }),
    onSubmit: values => {
      if (values?.type === 'list') {
        if (form.getFieldValue('value')?.length) {
          values.value = form.getFieldValue('value').reduce((obj, item) => ({ ...obj, [item?.key]: item?.value }), {})
        }
      }
      let action = updateWebInfoAction(id, values);
      dispatch(action);
    },
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

  useEffect(() => {
    getWebInfoAPI();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (Object.keys(webInfo).length !== 0) {
      form.setFieldsValue({
        name: webInfo?.name,
        key: webInfo?.key,
        type: webInfo?.type,
      });
      if (webInfo?.type === 'text') {
        form.setFieldValue('value', webInfo?.value);
      }
      else if (webInfo?.type === 'list') {
        const fields = Object.keys(webInfo?.value).map((el) => ({ key: el, value: webInfo?.value[el] }))
        form.setFieldValue('value', fields);
      }
      formik.setValues(webInfo);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(webInfo)]);

  useEffect(() => {
    if (reloadWebInfo !== false) {
      navigate('/admin/webinfos');
    }
    // eslint-disable-next-line
  }, [reloadWebInfo]);

  let getWebInfoAPI = () => {
    let action = getWebInfoAction(id);
    dispatch(action);
  };

  //upload image
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const updateFileAPI = (file) => {
    let action = updateFileWebInfoAction(id, file);
    dispatch(action);
    return false;
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const renderFile = () => {
    return <Form.Item label="File" valuePropName="fileList">
      <Upload
        customRequest={dummyRequest}
        listType="picture-card"
        fileList={
          Object.keys(values?.value || {})?.length > 0 ?
            [{
              uid: webInfo?.id,
              name: webInfo?.key,
              status: 'done',
              url: values?.value?.url
            }] : []
        }
        onRemove={() => setFieldValue('value',{})}
        beforeUpload={updateFileAPI}
        onPreview={onPreview}
      >
        {Object.keys(values?.value || {})?.length > 0 ? null : uploadButton}
      </Upload>
    </Form.Item>
  }

  const renderText = () => {
    return <Form.Item label="Giá trị" name="value">
      <TextArea onChange={handleChange} onBlur={handleBlur} />
    </Form.Item>
  }

  const renderList = () => {
    return <Form.Item label="Đối tượng">
      <Form.List name="value">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space key={key} style={{ display: 'flex' }} align="baseline">
                <Form.Item
                  {...restField}
                  name={[name, 'key']}
                  rules={[{ required: true, message: 'Chưa có khóa chính' }]}
                >
                  <Input placeholder="Khóa chính" />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'value']}
                  rules={[{ required: true, message: 'Chưa có giá trị' }]}
                >
                  <Input placeholder="Giá trị" />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Thêm thuộc tính
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  }

  return (
    <div>
      <h2>Cập nhập thông tin trang web</h2>
      <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={handleSubmit}
        form={form}
      >
        <Form.Item label="Tên" name="name"
          validateStatus={touched.name && errors.name ? "error" : "success"}
          help={touched.name && errors.name ? (errors.name) : null}>
          <Input onChange={handleChange} onBlur={handleBlur} />
        </Form.Item>
        <Form.Item label="Khóa chính" name="key"
          validateStatus={touched.key && errors.key ? "error" : "success"}
          help={touched.key && errors.key ? (errors.key) : null}>
          <Input onChange={handleChange} onBlur={handleBlur} />
        </Form.Item>
        <Form.Item label="Loại thông tin" name="type"
          help={touched.type && errors.type ? errors.type : null}
          validateStatus={touched.type && errors.type ? "error" : "success"}
        >
          <Select
            onChange={(value) => {
              if (value === webInfo?.type) {
                form.setFieldValue('value', webInfo?.type !== 'file' ? webInfo?.value : '');
                setFieldValue('value', webInfo?.value);
              }
              else {
                form.setFieldValue('value', '');
                setFieldValue('value', '');
              }
              setFieldValue('type', value);
            }}
            options={[
              { label: 'Text', value: 'text' },
              { label: 'File', value: 'file' },
              { label: 'List', value: 'list' }
            ]}
          />
        </Form.Item>
        {
          {
            text: renderText(),
            file: renderFile(),
            list: renderList()
          }[values.type]
        }
        <Form.Item label="Hành động">
          <Button type='primary' htmlType="submit">Cập nhập</Button>
        </Form.Item>
      </Form>
    </div>
  )
}
