import {
    GET_INFO,
    GET_LIST_ALL_INFO,
    GET_LIST_CUSTOM_INFO,
    RELOAD_INFO
} from "../types/WebInfosType";

const initialState = {
    allWebInfo: {},
    customWebInfo: {},
    webInfo: {},
    reloadWebInfo: false,
};

export const WebInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_ALL_INFO:
            if (action.allWebInfo.length) {
                for (const el of action.allWebInfo) {
                    let { key, value } = el;
                    state.allWebInfo[key] = value; 
                }
            }
            return { ...state };
        case GET_LIST_CUSTOM_INFO:
            state.customWebInfo = action.customWebInfo;
            return { ...state };
        case GET_INFO:
            state.webInfo = action.webInfo;
            return { ...state };
        case RELOAD_INFO:
            state.reloadWebInfo = action.reloadWebInfo;
            return { ...state };
        default:
            return state;
    }
};