import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import { Form, Input, Button, Table } from 'antd';
import {
  getCustomCategoryAction,
  reloadCategoryAction,
  removeCategoryAction
} from '../../../redux/actions/CategoryAction';
import { showDetail } from '../../../util/func';

export default function Categories() {
  const [searchName, setSearchName] = useState('');
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  let { customCategory, reloadCategory } = useSelector(state => state.CategoryReducer);
  let { tableLoading } = useSelector(state => state.LoadingReducer);
  let dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getCustomCategoryAPI();
    // eslint-disable-next-line
  }, [JSON.stringify(tableParams), searchName]);

  useEffect(() => {
    if (reloadCategory !== false) {
      getCustomCategoryAPI();
      let action = reloadCategoryAction();
      dispatch(action);
    }
    // eslint-disable-next-line
  }, [reloadCategory]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (min, max) => min?.id - max?.id,
    },
    {
      title: 'Tên',
      dataIndex: 'name',
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'image',
      width: '20%',
      render: (t, r) => <img style={{ maxWidth: '150px', maxHeight: '100px' }} src={`${r?.image?.url}`} alt={`${r?.name}`} />
    },
    {
      title: 'Mô tả',
      dataIndex: 'detail',
      render: (t, r) => <Button type="primary" onClick={() => showDetail(r?.detail)}>
        Xem chi tiết
      </Button>
    },
    {
      title: 'ID cha',
      dataIndex: 'parentId',
    },
    {
      title: 'Hàng động',
      dataIndex: '',
      width: '10%',
      render: (t, r) => <div>
        <EditFilled style={{ fontSize: '16px', color: '#1677ff', marginRight: '10px' }} onClick={() => {
          navigate(`/admin/edit-category/${r?.id}`);
        }} />
        <DeleteFilled style={{ fontSize: '16px', color: '#ff4d4f' }} onClick={() => {
          let action = removeCategoryAction(r?.id);
          dispatch(action);
        }} />
      </div>,
    },
  ];

  const handleTableChange = (pagination, sorter) => {
    setTableParams({
      pagination,
      ...sorter,
    });
    // `dataSource` is useless since `pageSize` changed
    if (pagination?.pageSize !== tableParams.pagination?.pageSize) {
      customCategory.data = [];
    }
  };


  const validate = values => {
    setSearchName(values.name.trim());
  };

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validate,
    onSubmit: values => {
      setSearchName(values.name.trim());
    },
  });

  const { handleChange, handleSubmit } = formik;

  let getCustomCategoryAPI = () => {
    let { current, pageSize } = tableParams.pagination;
    let filterValue = {
      pageIndex: current,
      pageSize: pageSize,
      keyword: searchName,
      order: 'ASC',
      sortBy: 'createdAt'
    }
    let action = getCustomCategoryAction(filterValue);
    dispatch(action);
  }

  return (
    <div>
      <h2 >Quản lý danh mục sản phẩm</h2>
      <Button type="primary" style={{ marginBottom: '20px' }}
        onClick={() => {
          navigate(`/admin/add-category`);
        }}>Thêm danh mục sản phẩm</Button>
      <Form layout="horizontal" onFinish={handleSubmit}>
        <Form.Item name="name" style={{ display: 'inline-block', width: '80%' }}>
          <Input onChange={handleChange} placeholder="Tìm kiếm theo tên nhóm sản phẩm" />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block', marginLeft: '10px' }}>
          <Button type="default" htmlType="submit">Tìm kiếm</Button>
        </Form.Item>
      </Form>
      <Table rowKey='id' columns={columns} dataSource={customCategory?.data}
        pagination={{
          current: customCategory?.pageIndex,
          pageSize: customCategory?.pageSize,
          total: customCategory?.totalRow
        }}
        loading={tableLoading}
        onChange={handleTableChange} />
    </div>
  )
}