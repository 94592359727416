import { http } from "../util/config";

class PostServices {
    getPost = (id) => {
        return http.get(`/api/v1/posts/${id}`);
    }

    validationPost = (validationPost) => {
        const { id, title } = validationPost;
        return http.get(`api/v1/posts/validation?id=${id}&title=${title}`);
    }

    getCustomPost = (filterValue) => {
        const { pageIndex, pageSize, keyword } = filterValue;
        return http.get(`/api/v1/posts/paging?pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}`);
    }

    addPost = (post) => {
        return http.post(`/api/v1/posts`, post);
    }

    updatePost = (id, post) => {
        return http.put(`/api/v1/posts/${id}`, post);
    }

    deletePost = (id) => {
        return http.delete(`/api/v1/posts/${id}`);
    }
}

const postServices = new PostServices();
export default postServices;