import { http } from "../util/config"

class CategoryServices {
    getCategory = (id) => {
        return http.get(`api/v1/categories/${id}`);
    }

    validationCategory = (validationCategory) => {
        const { id, name } = validationCategory; 
        return http.get(`api/v1/categories/validation?id=${id}&name=${name}`);
    }

    getBrandsByCategory = (id) => {
        return http.get(`api/v1/categories/${id}/brands`);
    }

    getFirstChildsCategory = (filterValue) => {
        const { categoryId, brandId, pageIndex, pageSize, keyword, order, sortBy} = filterValue;
        return http.get(`api/v1/categories/firstchilds/category/${categoryId}/brand/${brandId}?pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}&order=${order}&sortBy=${sortBy}`);
    }

    getAllCategory = () => {
        return http.get(`/api/v1/categories/0/subcategories`);
    }

    getCustomCategory = (filterValue) => {
        const { parentId, pageIndex, pageSize, keyword, order, sortBy } = filterValue;
        return http.get(`/api/v1/categories/paging?parentId=${parentId}&pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}&order=${order}&sortBy=${sortBy}`);
    }

    addCategory = (data) => {
        return http.post(`api/v1/categories`, data);
    }

    updateCategory = (id, data) => {
        return http.put(`api/v1/categories/${id}`, data);
    }

    deleteCategory = (id) => {
        return http.delete(`api/v1/categories/${id}`);
    }

    updateViewsCategory = (id) => {
        return http.put(`/api/v1/categories/${id}/views`);
    }

}

const categoryServices = new CategoryServices();
export default categoryServices;