import { Modal } from 'antd';
import parse from 'html-react-parser';
import fileServices from '../services/FileService';

export const removeAccents = (str) => {
    const accentsMap = str?.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/đ/g, 'd').replace(/Đ/g, 'D')
        .replace(/ /g, '-');
    return accentsMap?.toLowerCase();
};

export const extractUrl = (data, key) => {
    const start = data?.indexOf(key) + key?.length;
    const stop = data?.indexOf('?');
    const text = data?.slice(start > key?.length ? start : 0 , stop >-1 ? stop : data?.length );
    const arrUrl = text?.split('.');
    return arrUrl.length ? arrUrl : [];
};

export const notification = (content = '') => {
    Modal.info({
        title: 'Thông báo',
        content: (
            <div>
                <p>{content}</p>
            </div>
        ),
        onOk() { },
    });
}

export const showDetail = (contentInfo = '') => {
    Modal.info({
        width: 1000,
        centered: true,
        title: 'Xem chi tiết',
        content: (
            <div style={{ minHeight: '80vh' }}>
                {parse(String(contentInfo))}
            </div>
        ),
        onOk() { },
    });
};

export const uploadFile = async (data = []) => {
    return await fileServices.addFile(data)
        .then((result) => {
            return result.data?.content;
        })
        .catch((error) => {
            notification(error.response?.data.message);
        });
};

