export const GET_PRODUCT = "GET_PRODUCT";
export const SEARCH_PRODUCT = "SEARCH_PRODUCT";
export const GET_CUSTOM_PRODUCT = "GET_CUSTOM_PRODUCT";
export const GET_PRODUCT_BY_PARENT = "GET_PRODUCT_BY_PARENT";
export const CREATED_FILTER_PRODUCTS = "CREATED_FILTER_PRODUCTS";
export const RELOAD_PRODUCT = "RELOAD_PRODUCT";




