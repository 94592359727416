import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import { Form, Input, Button, Table } from 'antd';
import { 
  getCustomUserAction,
  reloadUserAction, 
  removeUserAction
} from '../../../redux/actions/UserAction';

export default function Users() {
  const [searchName, setSearchName] = useState('');
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  let { customUser, reloadUser } = useSelector(state => state.UserReducer);
  let { tableLoading } = useSelector(state => state.LoadingReducer);
  let dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getCustomUserAPI();
    // eslint-disable-next-line
  }, [JSON.stringify(tableParams), searchName]);

  useEffect(() => {
    if (reloadUser !== false) {
      getCustomUserAPI();
      let action = reloadUserAction();
      dispatch(action);
    }
    // eslint-disable-next-line
  }, [reloadUser]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a?.id - b?.id,
    },
    {
      title: 'Tên',
      dataIndex: 'displayName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'birthday',
    },
    {
      title: 'Giới tính',
      dataIndex: 'gender',
      render: (t, r) => r?.gender === true ? 'Nam' : 'Nữ'
    },
    {
      title: 'Vai trò',
      dataIndex: 'role',
    },
    {
      title: 'Hàng động',
      dataIndex: '',
      render: (t, r) => <div>
        <EditFilled style={{ fontSize: '16px', color: '#1677ff', marginRight: '10px' }} onClick={() => {
          navigate(`/admin/edit-user/${r?.id}`);
        }} />
        <DeleteFilled style={{ fontSize: '16px', color: '#ff4d4f' }} onClick={() => {
          let action = removeUserAction(r?.id);
          dispatch(action);
        }} />
      </div>,
    },
  ];

  const handleTableChange = (pagination, sorter) => {
    setTableParams({
      pagination,
      ...sorter,
    });
    // `dataSource` is useless since `pageSize` changed
    if (pagination?.pageSize !== tableParams.pagination?.pageSize) {
      customUser.data = [];
    }
  };

  const validate = values => {
    setSearchName(values.name.trim());
  };

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validate,
    onSubmit: values => {
      setSearchName(values.name.trim());
    },
  });

  let getCustomUserAPI = () => {
    let { current, pageSize } = tableParams.pagination;
    let filterValue = {
      pageIndex: current,
      pageSize: pageSize,
      keyword: searchName,
      order: 'ASC',
      sortBy: 'createdAt'
    }
    let action = getCustomUserAction(filterValue);
    dispatch(action);
  }

  return (
    <div >
      <h2 >Quản lý người dùng</h2>
      <Button type="primary" style={{ marginBottom: '20px' }} onClick={() => {
        navigate('/admin/add-user');
      }}>Thêm người dùng</Button>
      <Form layout="horizontal" onFinish={formik.handleSubmit}>
        <Form.Item name="name" style={{ display: 'inline-block', width: '80%' }}>
          <Input onChange={formik.handleChange} placeholder="Tìm kiếm theo tên người dùng" />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block', marginLeft: '10px' }}>
          <Button type="default" htmlType="submit">Tìm kiếm</Button>
        </Form.Item>
      </Form>
      <Table rowKey='id' columns={columns} dataSource={customUser?.data}
        pagination={{
          current: customUser?.pageIndex,
          pageSize: customUser?.pageSize,
          total: customUser?.totalRow
        }}
        loading={tableLoading}
        onChange={handleTableChange} />
    </div>
  )
}