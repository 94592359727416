import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Button, Select } from 'antd';
import {
    getGlobalLinkAction,
    updateGlobalLinkAction
} from '../../../redux/actions/GlobalLinkAction';
import globalLinkServices from '../../../services/GlobalLinkService';
import { getAllPlacementAction } from '../../../redux/actions/PlacementAction';

export default function EditGlobalLink() {
    const { id } = useParams();
    const { globalLink, reloadGlobalLink } = useSelector(state => state.GlobalLinkReducer);
    const { allPlacement } = useSelector(state => state.PlacementReducer);
    const [form] = Form.useForm();
    let dispatch = useDispatch();
    const navigate = useNavigate();

    // console.log(globalLink);
    const formik = useFormik({
        initialValues: {
            text: '',
            href: '',
            placements: [],
        },
        validationSchema: Yup.object({
            text: Yup.string().required("Văn bản không được để trống")
                .test("Văn bản", "Văn bản đã tồn tại", async (text) => {
                    let validationGlobalLink = {
                        id: globalLink?.id,
                        text: text,
                    }
                    let check = await globalLinkServices.validationGlobalLink(validationGlobalLink).then((result) => {
                        return result.data.content;
                    });
                    return !check;
                }),
            href: Yup.string().required("Đường dẫn không được để trống"),
            placements: Yup.array().required("Bạn chưa lựa chọn vị trí đặt")
        }),
        onSubmit: values => {
            let action = updateGlobalLinkAction(id, values);
            dispatch(action);
        },
    });

    const { touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

    useEffect(() => {
        getAllPlacementAPI();
      }, []);

    useEffect(() => {
        getGlobalLinkAPI();
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (Object.keys(globalLink).length !== 0) {
            form.setFieldsValue(globalLink);
            formik.setValues(globalLink);
        }
        // eslint-disable-next-line
    }, [JSON.stringify(globalLink)]);

    useEffect(() => {
        if (reloadGlobalLink !== false) {
            navigate('/admin/global-links');
        }
        // eslint-disable-next-line
    }, [reloadGlobalLink]);

    const getAllPlacementAPI = () => { 
        let action = getAllPlacementAction();
        dispatch(action);
     }

    let getGlobalLinkAPI = () => {
        let action = getGlobalLinkAction(id);
        dispatch(action);
    };

    return (
        <div>
            <h2>Cập nhập liên kết</h2>
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }}
                layout="horizontal"
                onFinish={handleSubmit}
                form={form}
            >
                <Form.Item label="Văn bản liên kết" name="text"
                    validateStatus={touched.text && errors.text ? "error" : "success"}
                    help={touched.text && errors.text ? (errors.text) : null}
                >
                    <Input onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Đường dẫn" name="href"
                    validateStatus={touched.href && errors.href ? "error" : "success"}
                    help={touched.href && errors.href ? (errors.href) : null}
                >
                    <Input onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Vị trí đặt" name="placements"
                    help={touched.placements && errors.placements ? errors.placements: null}
                    validateStatus={touched.placements && errors.placements ? "error" : "success"}
                >
                    <Select
                        mode="multiple"
                        allowClear
                        placeholder="Vui lòng lựa chọn"
                        onChange={(value) => setFieldValue('placements', value)}
                        options={allPlacement?.map((el) => ({ label: el?.name, value: el?.id }))}
                    />
                </Form.Item>
                <Form.Item label="Hành động">
                    <Button htmlType="submit">Cập nhập</Button>
                </Form.Item>
            </Form>
        </div>
    );
};
