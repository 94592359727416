import { 
    GET_ALL_PROMOTION, 
    GET_CUSTOM_PROMOTION, 
    GET_PROMOTION, 
    RELOAD_PROMOTION 
} from "../types/PromotionType";

const initialState = {
    allPromotion: [],
    customPromotion: {},
    promotion: {},
    reloadPromotion: false,
}

export const PromotionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_PROMOTION:
            state.allPromotion = action.allPromotion;
            return { ...state };
        case GET_CUSTOM_PROMOTION:
            state.customPromotion = action.customPromotion;
            return { ...state };
        case GET_PROMOTION:
            state.promotion = action.promotion;
            return { ...state };
        case RELOAD_PROMOTION:
            state.reloadPromotion = action.reloadPromotion;
            return { ...state };
        default:
            return state;
    };
};