import React, { useEffect } from 'react';
import { Form, Input, Button, Col, Row } from "antd";
import { FaKey } from "react-icons/fa";
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loginAction, userInfoAction } from '../../redux/actions/AuthAction';

export default function LoginAdmin() {
    const dispatch = useDispatch();
    const { loggedIn } = useSelector(state => state.AuthReducer)

    useEffect(() => {
        if (loggedIn) {
            userInfoAPI();
        }
    }, [loggedIn]);

    const userInfoAPI = () => { 
        let action = userInfoAction();
        dispatch(action);
     }

    const onFinish = (values) => {
        let action = loginAction(values);
        dispatch(action);
    };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='login-admin'>
            <Container>
                <Row >
                    <Col lg={{ span: 6 }} md={{ span: 4 }} />
                    <Col lg={{ span: 12 }} md={{ span: 16 }} className="login-box">
                        <Col lg={{ span: 24 }} className=" login-key">
                            <FaKey />
                        </Col>
                        <Col lg={{ span: 24 }} className="login-title">
                            ADMIN PANEL
                        </Col>
                        <Col lg={{ span: 24 }} className="login-form">
                            <Form
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <div className="form-group">
                                    <label className="form-control-label">EMAIL TÀI KHOẢN</label>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Vui lòng nhập email của bạn!',
                                            },
                                        ]}
                                    >
                                        <Input className="form-control" />
                                    </Form.Item>
                                </div>
                                <div className="form-group">
                                    <label className="form-control-label">MẬT KHẨU</label>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Vui lòng nhập mật khẩu!',
                                            },
                                        ]}
                                    >
                                        <Input.Password className="form-control" />
                                    </Form.Item>
                                </div>
                                <Row gutter={{ lg: 24 }} className="loginbttm">
                                    <Col lg={{ span: 12 }} className="login-btm login-text">
                                    </Col>
                                    <Col lg={{ span: 12 }} className="login-btm login-button">
                                        <Button type="primary" className="btn btn-outline-primary"  htmlType="submit">LOGIN</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Col>
                    <Col lg={{ span: 6 }} md={{ span: 4 }} />
                </Row>
            </Container>

            {/* add linearGradient for svg*/}
            <svg width={0} height={0}>
                <linearGradient id="lgrad" x1="100%" y1="100%" x2="0%" y2="0%">
                    <stop offset="0%" style={{ stopColor: 'rgb(39, 239, 159)', stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: 'rgb(13, 184, 222)', stopOpacity: 1 }} />
                </linearGradient>
            </svg>
        </div>
    )
}
