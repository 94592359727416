import {
    GET_ALL_BRAND,
    GET_BRAND,
    GET_CUSTOM_BRAND,
    GET_SUBCATEGORIES_BY_BRAND,
    RELOAD_BRAND,
} from "../types/BrandType";

const initialState = {
    allBrand: [],
    customBrand: {},
    subcategoriesbyBrand: [],
    brand: {},
    reloadBrand: false,
};

export const BrandReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_BRAND:
            state.allBrand = action.allBrand;
            return { ...state };
        case GET_CUSTOM_BRAND:
            state.customBrand = action.customBrand;
            return { ...state };
        case GET_SUBCATEGORIES_BY_BRAND:
            state.subcategoriesbyBrand = action.subcategoriesbyBrand;
            return { ...state };
        case GET_BRAND:
            state.brand = action.brand;
            return { ...state };
        case RELOAD_BRAND:
            state.reloadBrand = action.reloadBrand;
            return { ...state };
        default:
            return state;
    }
};