import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Upload, Form, Input, Button, Modal, Select } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import {
  addMediaAction,
} from '../../../redux/actions/MediaAction';
import { uploadFile } from '../../../util/func';
import mediaServices from '../../../services/MediaService';
import { getAllPlacementAction } from '../../../redux/actions/PlacementAction';

export default function AddMedia() {
  const [detailImageId, setDetailImageId] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalFileOpen, setIsModalFileOpen] = useState(false);
  const [isTinymce, setIsTinymce] = useState(false);
  const editorRef = useRef(null);
  const { reloadMedia } = useSelector(state => state.MediaReducer);
  const { allPlacement } = useSelector(state => state.PlacementReducer);
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      title: '',
      image: {},
      content: '',
      url: '',
      placements: [],
      type: '',
      fileIdList: []
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Tiêu đề không được để trống")
        .test("Tiêu đề", "Tiêu đề đã tồn tại", async (title) => {
          let check = await mediaServices.validationMedia({ title: title }).then((result) => {
            return result.data.content;
          });
          return !check;
        }),
      placements: Yup.array().required("Bạn chưa lựa chọn vị trí đặt"),
      type: Yup.string().required("Bạn chưa lựa chọn loại truyền thông")
    }),
    onSubmit: values => {
      let action = addMediaAction(values);
      dispatch(action);
    },
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

  const typeMedia = [
    { name: 'Băng rôn', value: 'banner' },
    { name: 'Quảng cáo', value: 'advertise' }
  ];

  useEffect(() => {
    getAllPlacementAPI();
  }, []);

  useEffect(() => {
    if (reloadMedia !== false) {
      navigate('/admin/medias');
    }
    // eslint-disable-next-line
  }, [reloadMedia]);

  useEffect(() => {
    if (isTinymce === true) {
      const content = values?.content;
      if (editorRef.current) {
        if (content) {
          editorRef.current.setContent(content)
        }
        else {
          editorRef.current.resetContent();
        }
      }
    }
  }, [isTinymce]);

  useEffect(() => {
    if (detailImageId > 0) {
      let arrFile = values?.fileIdList ? values?.fileIdList : [];
      arrFile.push(detailImageId);
      setFieldValue('fileIdList', arrFile);
    }
  }, [detailImageId]);

  //control modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const showModalFile = () => {
    setIsModalFileOpen(true);
  };

  const closeModalFile = () => {
    setIsModalFileOpen(false);
  };

  const uploadDetailImageAPI = (blobInfo, progress) => new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.blob()?.name);
    let image = uploadFile(formData);
    resolve(image);
  }).then((result) => {
    setDetailImageId(result.id);
    return result.url;
  });

  const getAllPlacementAPI = () => {
    let action = getAllPlacementAction();
    dispatch(action);
  };

  //upload image
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const updateImageMediaAPI = async (file) => {
    let formData = new FormData();
    formData.append('file', file, file.name);
    let imageFile = await uploadFile(formData);
    setFieldValue('image', { uid: imageFile.id, url: imageFile.url });
    return false;
  }

  const renderMedia = () => {
    console.log('abc')
    return <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 1400 }}>abc</div>
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <div>
      <h2 >Thêm Media</h2>
      <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={handleSubmit}
        initialValues={values}
      >
        <Form.Item label="Tiêu đề" name="title"
          validateStatus={touched.title && errors.title ? "error" : "success"}
          help={touched.title && errors.title ? (errors.title) : null}>
          <Input onChange={handleChange} onBlur={handleBlur} />
        </Form.Item>
        <Form.Item label="Hình ảnh" valuePropName="fileList">
          <Upload
            customRequest={dummyRequest}
            listType="picture-card"
            fileList={
              Object.keys(values?.image)?.length > 0 ?
                [{
                  uid: values?.image?.uid,
                  name: `image${values?.image?.uid}`,
                  status: 'done',
                  url: values?.image?.url,
                }] : []
            }
            onRemove={() => setFieldValue('image', {})}
            beforeUpload={updateImageMediaAPI}
            onPreview={onPreview}
          >
            {Object.keys(values?.image)?.length > 0 ? null : uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item label="Mô tả" name="content">
          <Button onClick={() => showModal()}>
            Thêm nội dung
          </Button>
        </Form.Item>
        <Form.Item label="Liên kết" name="url">
          <Input onChange={handleChange} onBlur={handleBlur} />
        </Form.Item>
        <Form.Item label="Loại" name="type"
          help={touched.type && errors.type ? errors.type : null}
          validateStatus={touched.type && errors.type ? "error" : "success"}
        >
          <Select
            placeholder="Vui lòng lựa chọn"
            onChange={(value) => setFieldValue('type', value)}
            options={typeMedia?.map((el) => ({ label: el?.name, value: el?.value }))}
          />
        </Form.Item>
        <Form.Item label="Vị trí đặt" name="placements"
          help={touched.placements && errors.placements ? errors.placements : null}
          validateStatus={touched.placements && errors.placements ? "error" : "success"}
        >
          <Select
            mode="multiple"
            allowClear
            placeholder="Vui lòng lựa chọn"
            onChange={(value) => setFieldValue('placements', value)}
            options={allPlacement?.map((el) => ({ label: el?.name, value: el?.id }))}
          />
        </Form.Item>
        <Form.Item label="Hành động">
          <Button type="primary" htmlType="submit">Thêm</Button>
        </Form.Item>
      </Form>
      <Modal title="Mô tả chi tiết"
        centered
        width={1000}
        open={isModalOpen}
        onOk={() => {
          if (editorRef.current) {
            setFieldValue('content', editorRef.current.getContent());
          }
          closeModal();
        }}
        onCancel={closeModal}>
        <Editor
          apiKey='a68gdz94s7gbdrmzq6n8h99gw0yr98wxwmpfd7rfk84hdpkt'
          init={{
            height: "80vh",
            menubar: true,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | collection | help',
            setup: function (editor) {
              editor.ui.registry.addButton('collection', {
                text: 'Media',
                onAction: function (_) {
                  showModalFile();
                }
              });
            },
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            automatic_uploads: true,
            images_upload_handler: uploadDetailImageAPI,
            file_picker_types: 'image',
            file_picker_callback: function (callback, value, meta) {
              // Provide image and alt text for the image dialog
              if (meta.filetype === 'image') {
                renderMedia();
                // callback(valueFile.url, {alt: valueFile.alt});
              }
            },
            forced_root_block: 'div'
          }}
          onInit={(evt, editor) => {
            editorRef.current = editor;
            setIsTinymce(editor.initialized);
          }}
        />
      </Modal>
      <Modal className='showFile'
        title="file"
        open={isModalFileOpen}
        onOk={() => {
          closeModalFile();
          if (editorRef.current) {
            editorRef.current.uploadImages('{"location": "folder/sub-folder/new-location.png" }');
            // editorRef.current.insertContent('&nbsp;<strong>It\'s my button!</strong>&nbsp;');
            // editorRef.current.images_upload_url({ "location": "folder/sub-folder/new-location.png" });
          }
          let a = { url: 'myimage.jpg', alt: 'My alt text' };
        }}
        onCancel={closeModalFile}
        zIndex={1400}
      >
        <p>abc</p>
      </Modal>
    </div>
  );
};
