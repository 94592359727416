import { createBrowserRouter } from "react-router-dom";
import HomeLayout from "../layouts/HomeLayout";
import LogInLayout from "../layouts/LogInLayout";
import AdminLayout from "../layouts/AdminLayout";
import Home from "../pages/Home/Home";
import Categories from "../pages/Categories/Categories";
import Brands from "../pages/Brands/Brands";
import Products from "../pages/Products/Products";
import Product from "../pages/Products/Product";
import NotFound from "../pages/NotFound/NotFound";
import Post from "../pages/Posts/Post";
import SubPage from "../pages/Subpage/SubPage";

import WebInfos from "../pages/Admin/WebInfo/WebInfos";
import AddWebInfo from "../pages/Admin/WebInfo/AddWebInfo";
import EditWebInfo from "../pages/Admin/WebInfo/EditWebInfo";
import PlacementsAd from "../pages/Admin/Placement/Placements";
import AddPlacement from "../pages/Admin/Placement/AddPlacement";
import EditPlacement from "../pages/Admin/Placement/EditPlacement";
import GlobalLinks from "../pages/Admin/GlobalLink/GlobalLinks";
import AddGlobalLink from "../pages/Admin/GlobalLink/AddGlobalLink";
import EditGlobalLink from "../pages/Admin/GlobalLink/EditGlobalLink";
import Users from "../pages/Admin/User/Users";
import AddUser from "../pages/Admin/User/AddUser";
import EditUser from "../pages/Admin/User/EditUser";
import CategoriesAd from "../pages/Admin/Category/Categories";
import AddCategory from "../pages/Admin/Category/AddCategory";
import EditCategory from "../pages/Admin/Category/EditCategory";
import BrandsAd from "../pages/Admin/Brand/Brands";
import AddBrand from "../pages/Admin/Brand/AddBrand";
import EditBrand from "../pages/Admin/Brand/EditBrand";
import AttributesAd from "../pages/Admin/Attribute/Attributes";
import AddAttribute from "../pages/Admin/Attribute/AddAttribute";
import EditAttribute from "../pages/Admin/Attribute/EditAttribute";
import PromotionsAd from "../pages/Admin/Promotion/Promotions";
import AddPromotion from "../pages/Admin/Promotion/AddPromotion";
import EditPromotion from "../pages/Admin/Promotion/EditPromotion";
import ProductsAd from "../pages/Admin/Product/Products";
import AddProduct from "../pages/Admin/Product/AddProduct";
import EditProduct from "../pages/Admin/Product/EditProduct";
import Medias from "../pages/Admin/Media/Medias";
import AddMedia from "../pages/Admin/Media/AddMedia";
import EditMedia from "../pages/Admin/Media/EditMedia";
import Posts from "../pages/Admin/Post/Posts";
import AddPost from "../pages/Admin/Post/AddPost";
import EditPost from "../pages/Admin/Post/EditPost";
import Files from "../pages/Admin/File/Files";
import AddFile from "../pages/Admin/File/AddFile";
import EditFile from "../pages/Admin/File/EditFile";


export default createBrowserRouter([
    {
        path: "/",
        element: <HomeLayout />,
        errorElement: <NotFound />,
        children: [
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "/home",
                element: <Home />,
            },
            {
                path: "/:subPageUrl",
                element: <SubPage />,
            },
            {
                path: "/categories/:categoryUrl",
                element: <Categories />,
            },
            {
                path: "/brands/:brandUrl",
                element: <Brands />,
            },
            {
                path: "/products",
                element: <Products />,
            },
            {
                path: "/products/:productUrl",
                element: <Product />,
            },
            {
                path: "/posts/:postUrl",
                element: <Post />,
            },
        ],
    },
    {
        path: "/auth",
        element: <LogInLayout />,
        errorElement: <NotFound />,
        children: [],
    },
    {
        path: "/admin",
        element: <AdminLayout />,
        errorElement: <NotFound />,
        children: [
            {
                path: '/admin',
                element: <WebInfos/>,
            },
            {
                path: 'webinfos',
                element: <WebInfos/>,
            },
            {
                path: 'add-webinfo',
                element: <AddWebInfo/>,
            },
            {
                path: 'edit-webinfo/:id',
                element: <EditWebInfo />,
            },
            {
                path: "placements",
                element: <PlacementsAd/>,
            },
            {
                path: "add-placement",
                element: <AddPlacement/>
            },
            {
                path: "edit-placement/:id",
                element: <EditPlacement/>,
            },
            {
                path: 'global-links',
                element: <GlobalLinks/>,
            },
            {
                path: 'add-global-link',
                element: <AddGlobalLink/>,
            },
            {
                path: 'edit-global-link/:id',
                element: <EditGlobalLink/>,
            },
            {
                path: 'medias',
                element: <Medias/>,
            },
            {
                path: 'add-media',
                element: <AddMedia/>,
            },
            {
                path: 'edit-media/:id',
                element: <EditMedia/>,
            },
            {
                path: "posts",
                element: <Posts/>,
            },
            {
                path: "add-post",
                element: <AddPost/>,
            },
            {
                path: "edit-post/:id",
                element: <EditPost/>,
            },
            {
                path: "users",
                element: <Users />,
            },
            {
                path: "add-user",
                element: <AddUser/>,
            },
            {
                path: "edit-user/:id",
                element: <EditUser/>,
            },
            {
                path: "categories",
                element: <CategoriesAd/>,
            },
            {
                path: "add-category",
                element: <AddCategory/>
            },
            {
                path: "edit-category/:id",
                element: <EditCategory/>,
            },
            {
                path: "brands",
                element: <BrandsAd/>,
            },
            {
                path: "add-brand",
                element: <AddBrand/>,
            },
            {
                path: "edit-brand/:id",
                element: <EditBrand/>,
            },
            {
                path: "promotions",
                element: <PromotionsAd/>,
            },
            {
                path: "add-promotion",
                element: <AddPromotion/>
            },
            {
                path: "edit-promotion/:id",
                element: <EditPromotion/>,
            },
            {
                path: "attributes",
                element: <AttributesAd/>,
            },
            {
                path: "add-attribute",
                element: <AddAttribute/>
            },
            {
                path: "edit-attribute/:id",
                element: <EditAttribute/>,
            },
            {
                path: "products",
                element: <ProductsAd/>,
            },
            {
                path: "add-product",
                element: <AddProduct/>,
            },
            {
                path: "edit-product/:id",
                element: <EditProduct/>,
            },
            {
                path: "files",
                element: <Files/>,
            },
            {
                path: "add-file",
                element: <AddFile/>,
            },
            {
                path: "edit-file/:id",
                element: <EditFile/>,
            },
        ],
    }
]);