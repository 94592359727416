import { http } from "../util/config";

class BrandServices {
    getBrand = (id) => {
        return http.get(`/api/v1/brands/${id}`);
    }

    getCategoriesByBrand = (id, categoryId) => {
        return http.get(`/api/v1/brands/${id}/category/${categoryId}/categories`);
    }

    getSubcategoriesByBrand = (filterValue) => {
        const { id, parentId, pageIndex, pageSize, keyword, order, sortBy} = filterValue;
        return http.get(`/api/v1/brands/${id}/category/${parentId}/subcategories?pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}&order=${order}&sortBy=${sortBy}`);
    }

    validationBrand = (validationBrand) => { 
        const { id, name } = validationBrand;
        return http.get(`/api/v1/brands/validation?id=${id}&name=${name}`);
    }

    getCustomBrand = (filterValue) => {
        const { pageIndex, pageSize, keyword, order, sortBy } = filterValue;
        return http.get(`/api/v1/brands/paging?pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}&order=${order}&sortBy=${sortBy}`);
    }

    getAllBrand = () => {
        return http.get(`/api/v1/brands`);
    }

    addBrand = (data) => {
        return http.post(`/api/v1/brands`, data);
    }

    updateBrand = (id, data) => {
        return http.put(`/api/v1/brands/${id}`, data);
    }

    deleteBrand = (id) => {
        return http.delete(`/api/v1/brands/${id}`);
    }

    updateViewsBrand = (id) => {
        return http.put(`/api/v1/brands/${id}/views`);
    }
}

const brandServices = new BrandServices();
export default brandServices;