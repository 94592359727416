import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Upload, Form, Input, Button, } from 'antd';
import { useParams } from 'react-router-dom';
import { getFileAction, updateFileAction } from '../../../redux/actions/FileAction';

const { TextArea } = Input;

export default function EditFile() {
    let { id } = useParams();
    let [fileList, setFileList] = useState([]);
    let { file, reloadFile } = useSelector(state => state.FileReducer)
    let dispatch = useDispatch();
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const formik = useFormik({
        initialValues: {
            description: '',
        },
        onSubmit: values => {
            if (fileList?.length > 0) {
                let filedata = fileList[0];
                let formData = new FormData();
                if (filedata?.originFileObj) {
                    formData.append('file', filedata?.originFileObj, filedata?.name);
                }
                else {
                    formData.append('name', filedata?.name);
                }
                formData.append('description', values?.description);
                let action = updateFileAction(file?.id, formData);
                dispatch(action);
            }
        },
    });

    const { handleChange, handleBlur, handleSubmit } = formik;

    useEffect(() => {
        getFileAPI();
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (Object.keys(file || {}).length) {
            setFileList([{
                uid: file?.id,
                name: file?.name,
                status: 'done',
                url: file?.url,
            }]);
            form.setFieldsValue({
                description: file?.description,
            });
            formik.setValues(file);
        }
        // eslint-disable-next-line
    }, [JSON.stringify(file)]);

    useEffect(() => {
        if (reloadFile !== false) {
            navigate('/admin/files');
        }
        // eslint-disable-next-line
    }, [reloadFile]);

    let getFileAPI = () => {
        let action = getFileAction(id);
        dispatch(action);
    };

    //upload image
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    return (
        <div>
            <h2>Cập nhật tập tin</h2>
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }}
                layout="horizontal"
                onFinish={handleSubmit}
                form={form}
            >
                <Form.Item label="Tập tin" valuePropName="fileList">
                    <Upload
                        customRequest={dummyRequest}
                        listType="picture-card"
                        fileList={fileList}
                        onChange={(value) => setFileList(value.fileList)}
                        onPreview={onPreview}
                    >
                        {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                </Form.Item>
                <Form.Item label="Mô tả" name="description">
                    <TextArea onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Hành động" >
                    <Button type='primary' htmlType="submit" disabled={fileList.length === 0}>Cập nhật</Button>
                </Form.Item>
            </Form>
        </div>
    );
};