import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Modal } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import { uploadFile } from '../../../util/func';
import { addPromotionAction } from '../../../redux/actions/PromotionAction';
import promotionServices from '../../../services/PromotionService';

export default function AddPromotion() {
  const [detailImageId, setDetailImageId] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTinymce, setIsTinymce] = useState(false);
  const editorRef = useRef(null);
  let { reloadPromotion } = useSelector(state => state.PromotionReducer)
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      code: '',
      content: '',
      condition: '',
      validity: '',
      fileIdList: []
    },
    validationSchema: Yup.object({
      code: Yup.string().required("Mã không được để trống")
        .test("Mã", "Mã đã tồn tại", async (code) => {
          let check = await promotionServices.validationPromotion({ code: code }).then((result) => {
            return result.data.content;
          });
          return !check;
        }),
    }),
    onSubmit: values => {
      let action = addPromotionAction(values);
      dispatch(action);
    },
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

  useEffect(() => {
    if (reloadPromotion !== false) {
      navigate('/admin/promotions');
    }
    // eslint-disable-next-line
  }, [reloadPromotion]);

  useEffect(() => {
    if (isTinymce === true) {
      const content = values?.content;
      if (editorRef.current) {
        if (content) {
          editorRef.current.setContent(content)
        }
        else {
          editorRef.current.resetContent();
        }
      }
    }
  }, [isTinymce]);

  useEffect(() => {
    if (detailImageId > 0) {
      let arrFile = values?.fileIdList ? values?.fileIdList : [];
      arrFile.push(detailImageId);
      setFieldValue('fileIdList', arrFile);
    }
  }, [detailImageId]);

  //control modal
  const showModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const uploadDetailImageAPI = (blobInfo, progress) => new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.blob()?.code);
    let image = uploadFile(formData);
    resolve(image);
  }).then((result) => {
    setDetailImageId(result.id);
    return result.url;
  });

  return (
    <div>
      <h2 >Thêm khuyến mãi</h2>
      <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} layout="horizontal"
        onFinish={handleSubmit}
        initialValues={values}
      >
        <Form.Item label="Mã" name="code"
          validateStatus={touched.code && errors.code ? "error" : "success"}
          help={touched.code && errors.code ? (errors.code) : null}>
          <Input onChange={handleChange} onBlur={handleBlur} />
        </Form.Item>
        <Form.Item label="Nội dung" name="content">
          <Button onClick={() => showModal()}>
            Thêm nội dung
          </Button>
        </Form.Item>
        <Form.Item label="Điều kiện" name="condition"
          validateStatus={touched.condition && errors.condition ? "error" : "success"}
          help={touched.condition && errors.condition ? (errors.condition) : null}>
          <Input onChange={handleChange} onBlur={handleBlur} />
        </Form.Item>
        <Form.Item label="Hiệu lực" name="validity"
          validateStatus={touched.validity && errors.validity ? "error" : "success"}
          help={touched.validity && errors.validity ? (errors.validity) : null}>
          <Input onChange={handleChange} onBlur={handleBlur} />
        </Form.Item>
        <Form.Item label="Hành động">
          <Button type="primary" htmlType="submit">Thêm</Button>
        </Form.Item>
      </Form>
      <Modal code="Nội dung chi tiết"
        centered
        width={1000}
        open={isModalOpen}
        onOk={() => {
          if (editorRef.current) {
            setFieldValue('content', editorRef.current.getContent());
          }
          closeModal();
        }}
        onCancel={closeModal}>
        <Editor
          apiKey='a68gdz94s7gbdrmzq6n8h99gw0yr98wxwmpfd7rfk84hdpkt'
          init={{
            height: "80vh",
            menubar: true,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            automatic_uploads: true,
            images_upload_handler: uploadDetailImageAPI,
            file_picker_types: 'image',
            forced_root_block: 'div'
          }}
          onInit={(evt, editor) => {
            editorRef.current = editor;
            setIsTinymce(editor.initialized);
          }}
        />
      </Modal>
    </div>
  );
};
