import userService from "../../services/UserService";
import { notification } from "../../util/func";
import {
    GET_CUSTOM_USER,
    GET_USER,
    RELOAD_USER,
} from "../types/UserType";
import { tableLoadingAction } from "./LoadingAction";

export const getUserAction = (id) => {
    return (dispatch2) => {
        let promise = userService.getUser(id);
        promise.then((result) => {
            let action2 = {
                type: GET_USER,
                user: result.data?.content,
            };
            dispatch2(action2);
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const getCustomUserAction = (filterValue) => {
    return (dispatch2) => {
        dispatch2(tableLoadingAction(true));
        let promise = userService.getCustomUser(filterValue);
        promise.then((result) => {
            let action2 = {
                type: GET_CUSTOM_USER,
                customUser: result.data?.content,
            };
            dispatch2(action2);
            dispatch2(tableLoadingAction(false));
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const addUserAction = (userInfo = {}) => {
    return (dispatch2) => {
        const avatar = userInfo?.avatar?.originFileObj;
        delete userInfo.avatar;
        let promise = userService.addUser(userInfo);
        promise.then(async (result) => {
            let user = result.data.content;
            if (avatar) {
                let formData = new FormData();
                formData.append('file', avatar);
                await userService.updateAvatar(user.id, formData);
            }
            let action2 = {
                type: RELOAD_USER,
                reloadUser: true,
            };
            dispatch2(action2);
            notification("Thêm người dùng thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const updateUserAction = (id = '', userNew = {}) => {
    return async (dispatch2) => {
        let promise = userService.updateUser(id, userNew);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_USER,
                reloadUser: true,
            }
            dispatch2(action2);
            notification("Cập nhập tài khoản thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const updateAvatarAction = (id = '', avatar = {}) => {
    return (dispatch2) => {
        let formData = new FormData();
        formData.append('file', avatar);
        let promise = userService.updateAvatar(id, formData);
        promise.then((result) => {
            let action2 = {
                type: GET_USER,
                user: result.data?.content,
            }
            dispatch2(action2);
            notification("Cập nhập avatar thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const removeUserAction = (id) => {
    return (dispatch2) => {
        let promise = userService.deleteUser(id);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_USER,
                reloadUser: true,
            }
            dispatch2(action2);
            notification(result.data?.content);
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const reloadUserAction = () => {
    return (dispatch2) => {
        let action2 = {
            type: RELOAD_USER,
            reloadUser: false,
        }
        dispatch2(action2);
    };
};