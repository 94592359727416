import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import { Form, Input, Button, Table } from 'antd';
import {
  getCustomFileAction,
  reloadFileAction,
  removeFileAction
} from '../../../redux/actions/FileAction';
import pdfImage from '../../../asset/images/pdf_file.png';

export default function Files() {
  const [searchName, setSearchName] = useState('');
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  let { customFile, reloadFile } = useSelector(state => state.FileReducer);
  let { tableLoading } = useSelector(state => state.LoadingReducer);
  let dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getCustomFileAPI();
    // eslint-disable-next-line
  }, [JSON.stringify(tableParams), searchName]);

  useEffect(() => {
    if (reloadFile !== false) {
      getCustomFileAPI();
      let action = reloadFileAction();
      dispatch(action);
    }
    // eslint-disable-next-line
  }, [reloadFile]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a?.id - b?.id,
    },
    {
      title: 'Tên',
      dataIndex: 'name',
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'url',
      width: '20%',
      render: (t, r) => <img style={{ maxWidth: '150px', maxHeight: '100px' }} src={r?.mimeType !== 'application/pdf' ? `${r?.url}` : pdfImage} alt={`${r?.name}`} />
    },
    {
      title: 'Loại file',
      dataIndex: 'mimeType',
    },
    {
      title: 'Danh mục sử dụng',
      dataIndex: 'use',
      render: (t, r) => {
        let arr = [];
        if (r?.fileFiRes?.length) {
          for (const el of r?.fileFiRes) {
            if (el?.postId !== null) {
              const index = arr.map(el => el.parent).indexOf('post');
              index > -1 ? arr[index].child.push(` ${el?.postId};`) : arr.push({ parent: 'post', name: 'Bài viết', child: [` ${el?.postId};`] });
            }
            if (el?.mediaId !== null) {
              const index = arr.map(el => el.parent).indexOf('media');
              index > -1 ? arr[index].child.push(` ${el?.mediaId};`) : arr.push({ parent: 'media', name: 'Truyền thông', child: [` ${el?.mediaId};`] });
            }
            if (el?.categoryId !== null) {
              const index = arr.map(el => el.parent).indexOf('category');
              index > -1 ? arr[index].child.push(` ${el?.categoryId};`) : arr.push({ parent: 'category', name: 'Danh mục', child: [` ${el?.categoryId};`] });
            }
            if (el?.brandId !== null) {
              const index = arr.map(el => el.parent).indexOf('brand');
              index > -1 ? arr[index].child.push(` ${el?.brandId};`) : arr.push({ parent: 'brand', name: 'Nhà cung cấp', child: [` ${el?.brandId};`] });
            }
            if (el?.productId !== null) {
              const index = arr.map(el => el.parent).indexOf('product');
              index > -1 ? arr[index].child.push(` ${el?.productId};`) : arr.push({ parent: 'product', name: 'Sản phẩm', child: [` ${el?.productId};`] });
            }
            if (el?.productDetailId !== null) {
              const index = arr.map(el => el.parent).indexOf('productDetail');
              index > -1 ? arr[index].child.push(` ${el?.productDetailId};`) : arr.push({ parent: 'productDetail', name: 'Chi tiết sản phẩm', child: [` ${el?.productDetailId};`] });
            }
            if (el?.promotionId !== null) {
              const index = arr.map(el => el.parent).indexOf('promotion');
              index > -1 ? arr[index].child.push(` ${el?.promotionId};`) : arr.push({ parent: 'promotion', name: 'Khuyến mãi', child: [` ${el?.promotionId};`] });
            }
          }
        }
        return arr?.length > 0 ? arr?.map((el, index) => <div key={`fileUse-${index}`}>{el?.name}:{el.child}</div>) : 'Chưa sử dụng';
      }
    },
    {
      title: 'Hàng động',
      dataIndex: '',
      width: '10%',
      render: (t, r) => <div>
        <EditFilled style={{ fontSize: '16px', color: '#1677ff', marginRight: '10px' }} onClick={() => {
          navigate(`/admin/edit-file/${r?.id}`);
        }} />
        <DeleteFilled style={{ fontSize: '16px', color: '#ff4d4f' }} onClick={() => {
          let action = removeFileAction(r?.id);
          dispatch(action);
        }} />
      </div>,
    },
  ];

  const handleTableChange = (pagination, sorter) => {
    setTableParams({
      pagination,
      ...sorter,
    });
    // `dataSource` is useless since `pageSize` changed
    if (pagination?.pageSize !== tableParams.pagination?.pageSize) {
      customFile.data = [];
    }
  };

  const validate = values => {
    setSearchName(values.name.trim());
  };

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validate,
    onSubmit: values => {
      setSearchName(values.name.trim());
    },
  });
  const { handleChange, handleSubmit } = formik;


  let getCustomFileAPI = () => {
    let { current, pageSize } = tableParams.pagination;
    let filterValue = {
      pageIndex: current,
      pageSize: pageSize,
      keyword: searchName,
      order: 'ASC',
      sortBy: 'createdAt'
    }
    let action = getCustomFileAction(filterValue);
    dispatch(action);
  }

  return (
    <div>
      <h2 >Quản lý tập tin</h2>
      <Button type="primary" style={{ marginBottom: '20px' }} onClick={() => {
        navigate('/admin/add-file');
      }}>Thêm tập tin</Button>
      <Form layout="horizontal" onFinish={handleSubmit}>
        <Form.Item name="name" style={{ display: 'inline-block', width: '80%' }}>
          <Input onChange={handleChange} placeholder="Tìm kiếm theo tên tập tin" />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block', marginLeft: '10px' }}>
          <Button type="default" htmlType="submit">Tìm kiếm</Button>
        </Form.Item>
      </Form>
      <Table rowKey='id' columns={columns} dataSource={customFile?.data}
        pagination={{
          current: customFile?.pageIndex,
          pageSize: customFile?.pageSize,
          total: customFile?.totalRow
        }}
        loading={tableLoading}
        onChange={handleTableChange} />
    </div>
  )
}

