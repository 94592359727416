import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import placementServices from '../../../services/PlacementService';
import { 
    getPlacementAction, 
    updatePlacementAction 
} from '../../../redux/actions/PlacementAction';

export default function EditPlacement() {
    const { id } = useParams();
    const { placement, reloadPlacement } = useSelector(state => state.PlacementReducer)
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const formik = useFormik({
        initialValues: {
            name: '',
            key: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Tên không được để trống"),
            key: Yup.string().required("Vị trí đặt được để trống")
                .test("Vị trí đặt", "Vị trí đặt đã tồn tại", async (key) => {
                    let validationPlacement = {
                        id: placement?.id,
                        key: key
                      }
                    let check = await placementServices.validationPlacement(validationPlacement).then((result) => {
                        return result.data.content;
                    });
                    return !check;
                }),
        }),
        onSubmit: values => {
            let action = updatePlacementAction(placement?.id, values);
            dispatch(action);
        },
    });

    const { touched, errors, handleChange, handleBlur, handleSubmit } = formik;

    useEffect(() => {
        getPlacementAPI();
    }, [id]);

    useEffect(() => {
        if (Object.keys(placement).length) {
            form.setFieldsValue({
                name: placement?.name,
                key: placement?.key,
            });
            formik.setValues(placement);
        }
    }, [JSON.stringify(placement)])

    useEffect(() => {
        if (reloadPlacement !== false) {
            navigate('/admin/placements');
        }
        // eslint-disable-next-line
    }, [reloadPlacement]);

    const getPlacementAPI = () => {
        let action = getPlacementAction(id);
        dispatch(action);
    }

    return (
        <div>
            <h2 >Cập nhật vị trí đặt</h2>
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} layout="horizontal"
                onFinish={handleSubmit}
                form={form}
            >
                <Form.Item label="Tên" name="name"
                    validateStatus={touched.name && errors.name ? "error" : "success"}
                    help={touched.name && errors.name ? (errors.name) : null}>
                    <Input onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Khóa chính" name="key"
                    validateStatus={touched.key && errors.key ? "error" : "success"}
                    help={touched.key && errors.key ? (errors.key) : null}>
                    <Input onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Hành động">
                    <Button type="primary" htmlType="submit">Cập nhật</Button>
                </Form.Item>
            </Form>
        </div>
    );
};
