import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Upload, Form, Input, Button, Modal, Select } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import { getMediaAction, updateMediaAction } from '../../../redux/actions/MediaAction';
import { uploadFile } from '../../../util/func';
import mediaServices from '../../../services/MediaService';
import { getAllPlacementAction } from '../../../redux/actions/PlacementAction';

export default function EditMedia() {
    const { id } = useParams();
    const [detailImageId, setDetailImageId] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isTinymce, setIsTinymce] = useState(false);
    const editorRef = useRef(null);
    const { media, reloadMedia } = useSelector(state => state.MediaReducer);
    const { allPlacement } = useSelector(state => state.PlacementReducer);
    let dispatch = useDispatch();
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const formik = useFormik({
        initialValues: {
            title: '',
            image: {},
            content: '',
            url: '',
            placements: [],
            type: '',
            fileIdList: []
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Tiêu đề không được để trống")
                .test("Tiêu đề", "Tiêu đề đã tồn tại", async (values) => {
                    let validationMedia = {
                        id: media?.id,
                        title: values,
                    }
                    let check = await mediaServices.validationMedia(validationMedia).then((result) => {
                        return result.data.content;
                    });
                    return !check;
                }),
        }),
        onSubmit: values => {
            // console.log(values);
            let action = updateMediaAction(media?.id, values);
            dispatch(action);
        },
    });

    const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

    const typeMedia = [
        { name: 'Băng rôn', value: 'banner' },
        { name: 'Quảng cáo', value: 'advertise' }
    ];

    useEffect(() => {
        getAllPlacementAPI();
    }, []);

    useEffect(() => {
        getMediaAPI();
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (Object.keys(media).length !== 0) {
            form.setFieldsValue({
                title: media?.title,
                content: media?.content,
                url: media?.url,
                placements: media?.placements,
                type: media?.type
            });
            formik.setValues(media);
        }
        // eslint-disable-next-line
    }, [JSON.stringify(media)]);

    useEffect(() => {
        if (reloadMedia !== false) {
            navigate('/admin/medias');
        }
        // eslint-disable-next-line
    }, [reloadMedia]);

    useEffect(() => {
        if (isTinymce === true) {
            const content = values?.content;
            if (editorRef.current) {
                if (content) {
                    editorRef.current.setContent(content)
                }
                else {
                    editorRef.current.resetContent();
                }
            }
        }
    }, [isTinymce]);

    useEffect(() => {
        if (detailImageId > 0) {
            let arrFile = values?.fileIdList ? values?.fileIdList : [];
            arrFile.push(detailImageId);
            setFieldValue('fileIdList', arrFile);
        }
    }, [detailImageId]);

    //control modal
    const showModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const uploadDetailImageAPI = (blobInfo, progress) => new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.blob()?.name);
        let image = uploadFile(formData);
        resolve(image);
    }).then((result) => {
        setDetailImageId(result.id);
        return result.url;
    });

    let getMediaAPI = () => {
        let action = getMediaAction(id);
        dispatch(action);
    };

    const getAllPlacementAPI = () => {
        let action = getAllPlacementAction();
        dispatch(action);
    };

    //upload image
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const updateImageMediaAPI = async (file) => {
        let formData = new FormData();
        formData.append('file', file, file.name);
        let imageFile = await uploadFile(formData);
        setFieldValue('image', { uid: imageFile.id, url: imageFile.url });
        return false;
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    return (
        <div>
            <h2 >Cập nhập media</h2>
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }}
                layout="horizontal"
                onFinish={handleSubmit}
                form={form}
            >
                <Form.Item label="Tiêu đề" name="title"
                    validateStatus={touched.title && errors.title ? "error" : "success"}
                    help={touched.title && errors.title ? (errors.title) : null}>
                    <Input onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Hình ảnh" valuePropName="fileList">
                    <Upload
                        customRequest={dummyRequest}
                        listType="picture-card"
                        fileList={
                            Object.keys(values?.image || {})?.length > 0 ?
                                [{
                                    uid: values?.image?.uid,
                                    name: `image${values?.image?.uid}`,
                                    status: 'done',
                                    url: values?.image?.url,
                                }] : []
                        }
                        onRemove={() => setFieldValue('image', {})}
                        beforeUpload={updateImageMediaAPI}
                        onPreview={onPreview}
                    >
                        {Object.keys(values?.image || {})?.length > 0 ? null : uploadButton}
                    </Upload>
                </Form.Item>
                <Form.Item label="Mô tả" name="content">
                    <Button onClick={() => showModal()}>
                        Cập nhập nội dung
                    </Button>
                </Form.Item>
                <Form.Item label="Vị trí đặt" name="placement">
                    <Input onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Loại" name="type"
                    help={touched.type && errors.type ? errors.type : null}
                    validateStatus={touched.type && errors.type ? "error" : "success"}
                >
                    <Select
                        placeholder="Vui lòng lựa chọn"
                        onChange={(value) => setFieldValue('type', value)}
                        options={typeMedia?.map((el) => ({ label: el?.name, value: el?.value }))}
                    />
                </Form.Item>
                <Form.Item label="Vị trí đặt" name="placements"
                    help={touched.placements && errors.placements ? errors.placements : null}
                    validateStatus={touched.placements && errors.placements ? "error" : "success"}
                >
                    <Select
                        mode="multiple"
                        allowClear
                        placeholder="Vui lòng lựa chọn"
                        onChange={(value) => setFieldValue('placements', value)}
                        options={allPlacement?.map((el) => ({ label: el?.name, value: el?.id }))}
                    />
                </Form.Item>
                <Form.Item label="Hành động">
                    <Button type="primary" htmlType="submit">Cập nhập</Button>
                </Form.Item>
            </Form>
            <Modal title="Mô tả chi tiết"
                centered
                width={1000}
                open={isModalOpen}
                onOk={() => {
                    if (editorRef.current) {
                        setFieldValue('content', editorRef.current.getContent());
                    }
                    closeModal();
                }}
                onCancel={closeModal}>
                <Editor
                    apiKey='a68gdz94s7gbdrmzq6n8h99gw0yr98wxwmpfd7rfk84hdpkt'
                    init={{
                        height: "80vh",
                        menubar: true,
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                        ],
                        toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        automatic_uploads: true,
                        images_upload_handler: uploadDetailImageAPI,
                        file_picker_types: 'image',
                        forced_root_block: 'div'
                    }}
                    onInit={(evt, editor) => {
                        editorRef.current = editor;
                        setIsTinymce(editor.initialized);
                    }}
                />
            </Modal>
        </div>
    )
}
