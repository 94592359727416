import { ACCESS_TOKEN } from "../../util/setting";
import { LOGIN, LOGOUT, USER_INFO } from "../types/AuthType";

const initialState = {
    loggedIn: false,
    user: {},
}

export const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            localStorage.setItem(ACCESS_TOKEN, JSON.stringify(action.token));
            state.loggedIn = true;
            return { ...state };
        case USER_INFO:
            state.user = action.user;
            state.loggedIn = true;
            return { ...state };
        case LOGOUT:
            localStorage.removeItem(ACCESS_TOKEN);
            state.user = {};
            state.loggedIn = false;
            return { ...state };
        default:
            return state;
    }
}