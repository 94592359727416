import placementServices from "../../services/PlacementService";
import { notification } from "../../util/func";
import { 
    GET_ALL_PLACEMENT, 
    GET_CUSTOM_PLACEMENT, 
    GET_PLACEMENT, 
    RELOAD_PLACEMENT 
} from "../types/PlacementType";
import { tableLoadingAction } from "./LoadingAction";

export const getPlacementAction = (id) => {
    return (dispatch2) => {
        let promise = placementServices.getPlacement(id);
        promise.then((result) => {
            let action2 = {
                type: GET_PLACEMENT,
                placement: result.data.content,
            };
            dispatch2(action2);
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const getAllPlacementAction = () => {
    return (dispatch2) => {
        let promise = placementServices.getAllPlacement();
        promise.then((result) => {
            let action2 = {
                type: GET_ALL_PLACEMENT,
                allPlacement: result.data.content,
            };
            dispatch2(action2);
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const getCustomPlacementAction = (filterValue) => {
    return (dispatch2) => {
        dispatch2(tableLoadingAction(true));
        let promise = placementServices.getCustomPlacement(filterValue);
        promise.then((result) => {
            let action2 = {
                type: GET_CUSTOM_PLACEMENT,
                customPlacement: result.data.content,
            };
            dispatch2(action2);
            dispatch2(tableLoadingAction(false));
        });
        promise.catch((error) => {
            notification(error.response.data.message);
        });
    };
};

export const addPlacementAction = (data) => {
    return (dispatch2) => {
        let promise = placementServices.addPlacement(data);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_PLACEMENT,
                reloadPlacement: true,
            };
            dispatch2(action2);
            notification("Thêm vi trí đặt thành công")
        });
        promise.catch((error) => {
            notification(error.response.data.message);
        });
    };
};

export const updatePlacementAction = (id = '', data={}) => {
    return (dispatch2) => {
        let promise = placementServices.updatePlacement(id, data);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_PLACEMENT,
                reloadPlacement: true,
            };
            dispatch2(action2);
            notification("Cập nhập vị trí đặt thành công");
        });
        promise.catch((error) => {
            notification(error.response.data.message);
        });
    };
};

export const removePlacementAction = (id = '') => {
    return (dispatch2) => {
        let promise = placementServices.deletePlacement(id);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_PLACEMENT,
                reloadPlacement: true,
            };
            dispatch2(action2);
            notification("Xóa vị trí đặt thành công");
        });
        promise.catch((error) => {
            notification(error.response.data.message);
        });
    };
};

export const reloadPlacementAction = () => { 
    return (dispatch2) => { 
        let action2 = {
            type: RELOAD_PLACEMENT,
            reloadPlacement: false,
        };
        dispatch2(action2);
     };
 };


