import {
    UPDATE_DOUBLE_TABS,
    SELECT_DOUBLE_TABS,
    DELETE_DOUBLE_TABS,
} from "../types/DoubleTabsType";

const initialState = {
    doubleTabs: [],
    topKeyActive: 0,
    indexActive: 0
}

export const DoubleTabsReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_DOUBLE_TABS:
            let iUpdate = state.doubleTabs.findIndex((el) => el.name === action.doubleTab.name);
            if (iUpdate > -1) {
                state.doubleTabs[iUpdate] = action.doubleTab;
            }
            else {
                state.doubleTabs.push(action.doubleTab);
            }
            state.indexActive = state.doubleTabs.findIndex((el) => el.name === action.doubleTab.name);
            state.topKeyActive = action.doubleTab.topKey;
            return { ...state };
        case SELECT_DOUBLE_TABS:
            const { name, topKey, bottomKey, filterTabs } = action.selectDoubleTab;
            let iSelect = state.doubleTabs.findIndex((el) => el.name === name);
            if (iSelect > -1) {
                state.indexActive = iSelect;
                if (topKey) {
                    state.topKeyActive = topKey;
                    for (const el of state.doubleTabs) {
                        if (el.topTabGroupName !== '' && el.topTabGroupName === state.doubleTabs[iSelect].topTabGroupName) {
                            el.topKey = topKey;
                        }
                    }
                }
                if (bottomKey) {
                    state.doubleTabs[iSelect].bottomKey = bottomKey;
                    state.doubleTabs[iSelect].filterTabs = filterTabs;
                }
            }
            return { ...state };
        case DELETE_DOUBLE_TABS:
            let iDelete = state.doubleTabs.findIndex((el) => action.name);
            if (iDelete > -1) {
                state.doubleTabs = state.doubleTabs.filter((el) => el.name !== action.name);
            }
            return { ...state };
        default:
            return state;
    }
}