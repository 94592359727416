import React, { useEffect } from 'react';
import { ACCESS_TOKEN } from '../util/setting';
import { useDispatch, useSelector } from 'react-redux';
import { userInfoAction } from '../redux/actions/AuthAction';
import Admin from '../pages/Admin/Admin';
import LoginAdmin from '../pages/Login/LoginAdmin';

export default function AdminLayout() {
  const { user } = useSelector(state => state.AuthReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      userInfoAPI();
    }
  },[]);

  const userInfoAPI = () => { 
    let action = userInfoAction();
    dispatch(action);
 }

  return (
    <div>
      {/* {Object.keys(user).length !== 0 ? (user?.role === 'admin' ? <Admin/> : <LoginAdmin/>): ''} */}
      {user?.role === 'admin' ? <Admin/> : <LoginAdmin/>}
    </div>
  )
}
