import webInfosService from "../../services/WebInfosService";
import { notification } from "../../util/func";
import {
    GET_INFO,
    GET_LIST_ALL_INFO,
    GET_LIST_CUSTOM_INFO,
    RELOAD_INFO
} from "../types/WebInfosType";
import { tableLoadingAction } from "./LoadingAction";


export const getWebInfoAction = (id = '') => {
    return (dispatch2) => {
        let promise = webInfosService.getWebInfo(id);
        promise.then((result) => {
            let action2 = {
                type: GET_INFO,
                webInfo: result.data.content,
            }
            dispatch2(action2);
        });
        promise.catch((error) => {
            console.log(error)
        });
    };
};

export const getAllWebInfoAction = () => {
    return (dispatch2) => {
        let promise = webInfosService.getAllWebInfo();
        promise.then((result) => {
            let action2 = {
                type: GET_LIST_ALL_INFO,
                allWebInfo: result.data.content,
            }
            dispatch2(action2);
        });
        promise.catch((error) => {
            console.log(error)
        });
    };
};

export const getCustomWebInfoAction = (filterValue = {}) => {
    return (dispatch2) => {
        dispatch2(tableLoadingAction(true));
        let promise = webInfosService.getCustomWebInfo(filterValue);
        promise.then((result) => {
            let action2 = {
                type: GET_LIST_CUSTOM_INFO,
                customWebInfo: result.data.content,
            };
            dispatch2(action2);
            dispatch2(tableLoadingAction(false));
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const addWebInfoAction = (data = {}) => {
    return (dispatch2) => {
        let promise = webInfosService.addWebInfo(data);
        promise.then(async (result) => {
            const webInfo = result.data.content;
            if (Object.keys(data?.file).length) {
                let formData = new FormData();
                formData.append('file', data?.file?.originFileObj);
                await webInfosService.updateFile(webInfo?.id, formData)
            }
            let action2 = {
                type: RELOAD_INFO,
                reloadWebInfo: true,
            };
            dispatch2(action2);
            notification("Thêm thông tin thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const updateWebInfoAction = (id = '', data = {}) => {
    return async (dispatch2) => {
        let promise = webInfosService.updateWebInfo(id, data);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_INFO,
                reloadWebInfo: true,
            }
            dispatch2(action2);
            notification("Cập nhập thông tin thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const updateFileWebInfoAction = (id = '', file = {}) => {
    return (dispatch2) => {
        let formData = new FormData();
        formData.append('file', file);
        let promise = webInfosService.updateFile(id, formData);
        promise.then((result) => {
            let action2 = {
                type: GET_INFO,
                webInfo: result.data.content,
            }
            dispatch2(action2);
            notification("Cập nhập file thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const removeWebInfoAction = (id = '') => {
    return (dispatch2) => {
        let promise = webInfosService.deleteWebInfo(id);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_INFO,
                reloadWebInfo: true,
            }
            dispatch2(action2);
            notification("Xóa thông tin thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const reloadWebInfoAction = () => {
    return (dispatch2) => {
        let action2 = {
            type: RELOAD_INFO,
            reloadWebInfo: false,
        }
        dispatch2(action2);
    };
};

