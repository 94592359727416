import React from 'react';
import Button from 'react-bootstrap/Button';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

export default function Pagination({
    name,
    pageIndex,
    pageSize,
    totalRow,
    onChangePage = () => { }
}) {
    
    const renderPaging = () => {
        let renderPaging = [];
        const totalPaging = Math.ceil(totalRow / pageSize);
        const pagingAct = Math.ceil((pageIndex * pageSize) / pageSize);
        let startPaging = (pagingAct > 6) ? pagingAct > (totalPaging - 6) ? totalPaging - 6 : (pagingAct - 3) : ((totalPaging - 6) > 0 && pagingAct > (totalPaging - 6)) ? totalPaging - 6 : 1;
        let endPaging = totalPaging < 7 || pagingAct > (totalPaging - 6) ? totalPaging : (pagingAct < 7) ? 7 : pagingAct + 3;
        if (totalPaging > 7 && (pagingAct > 6 || pagingAct > totalPaging - 6)) {
            renderPaging.push(<Button className='button__pagination' >...</Button>)
        }
        for (let index = startPaging; index <= endPaging; index++) {
            if (index === pagingAct) {
                renderPaging.push(
                    <Button key={`paging-${index}`} className='button__pagination active'
                        onClick={() => {
                            onChangePage({
                                name: name,
                                pageIndex: index
                            });
                        }}
                    >
                        {index}
                    </Button>
                );
            }
            else {
                renderPaging.push(
                    <Button key={`paging-${index}`} className='button__pagination'
                        onClick={() => {
                            onChangePage({
                                name: name,
                                pageIndex: index
                            });
                        }}
                    >
                        {index}
                    </Button>
                );
            }
        }
        if (totalPaging > 7 && pagingAct <= totalPaging - 6) {
            renderPaging.push(<Button className='button__pagination' >...</Button>)
        }

        return <div className='pagination' >
            <Button className='button__pagination button__left'
                onClick={() => {
                    onChangePage({
                        name: name,
                        pageIndex: pagingAct > 1 ? pagingAct - 1 : 1
                    });
                }}
            >
                <IoIosArrowBack />
            </Button>
            {renderPaging}
            <Button className='button__pagination button__right'
                onClick={() => {
                    onChangePage({
                        name: name,
                        pageIndex: pagingAct < totalPaging ? pagingAct + 1 : totalPaging
                    });
                }}
            >
                <IoIosArrowForward />
            </Button>
        </div>
    }

    return (
        totalRow ? renderPaging() : ''
    )
}
