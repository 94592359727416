import { 
    GET_ALL_MEDIA, 
    GET_CUSTOM_ABOUT, 
    GET_CUSTOM_ADVER, 
    GET_CUSTOM_BANNER, 
    GET_CUSTOM_MEDIA, 
    GET_MEDIA, 
    RELOAD_MEDIA 
} from "../types/MediaType";


const initialState = {
    allMedia: [],
    customMedia: {},
    customBanner: {},
    customAdver: {},
    customAbout: {},
    media: {},
    reloadMedia: false,
};

export const MediaReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_MEDIA:
            state.allMedia = action.allMedia;
            return { ...state };
        case GET_CUSTOM_MEDIA:
            state.customMedia = action.customMedia;
            return { ...state };
        case GET_CUSTOM_BANNER:
            state.customBanner = action.customBanner;
            return { ...state };
        case GET_CUSTOM_ADVER:
            state.customAdver = action.customAdver;
            return { ...state };
        case GET_CUSTOM_ABOUT:
            state.customAbout = action.customAbout;
            return { ...state };
        case GET_MEDIA:
            state.media = action.media;
            return { ...state };
        case RELOAD_MEDIA:
            state.reloadMedia = action.reloadMedia;
            return { ...state };
        default:
            return state;
    }
};