import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import attributeServices from '../../../services/AttributeService';
import { addAttributeAction } from '../../../redux/actions/AttributeAction';

export default function AddAttribute() {
    const { reloadAttribute } = useSelector(state => state.AttributeReducer)
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Tên không được để trống")
                .test("Tên", "Tên đã tồn tại", async (name) => {
                    let check = await attributeServices.validationAtribute({ name: name }).then((result) => {
                        return result.data.content;
                    });
                    return !check;
                }),
        }),
        onSubmit: values => {
            let action = addAttributeAction(values);
            dispatch(action);
        },
    });

    const { values, touched, errors, handleChange, handleBlur, handleSubmit } = formik;

    useEffect(() => {
        if (reloadAttribute !== false) {
            navigate('/admin/attributes');
        }
        // eslint-disable-next-line
    }, [reloadAttribute]);

    return (
        <div>
            <h2 >Thêm thuộc tính</h2>
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} layout="horizontal"
                onFinish={handleSubmit}
                initialValues={values}
            >
                <Form.Item label="Tên" name="name"
                    validateStatus={touched.name && errors.name ? "error" : "success"}
                    help={touched.name && errors.name ? (errors.name) : null}>
                    <Input onChange={handleChange} onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Hành động">
                    <Button type="primary" htmlType="submit">Thêm</Button>
                </Form.Item>
            </Form>
        </div>
    );
};