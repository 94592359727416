import { 
    GET_ALL_PLACEMENT, 
    GET_CUSTOM_PLACEMENT, 
    GET_PLACEMENT, 
    RELOAD_PLACEMENT 
} from "../types/PlacementType";

const initialState = {
    allPlacement: [],
    customPlacement: {},
    placement: {},
    reloadPlacement: false,
}
export const PlacementReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_PLACEMENT:
            state.allPlacement = action.allPlacement;
            return { ...state };
        case GET_CUSTOM_PLACEMENT:
            state.customPlacement = action.customPlacement;
            return { ...state };
        case GET_PLACEMENT:
            state.placement = action.placement;
            return { ...state };
        case RELOAD_PLACEMENT:
            state.reloadPlacement = action.reloadPlacement;
            return { ...state };
        default:
            return state;
    }
}