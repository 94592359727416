import attributeServices from "../../services/AttributeService";
import { notification } from "../../util/func";
import { 
    GET_ALL_ATTRIBUTE, 
    GET_ATTRIBUTE, 
    GET_CUSTOM_ATTRIBUTE, 
    RELOAD_ATTRIBUTE 
} from "../types/AttributeType";
import { tableLoadingAction } from "./LoadingAction";

export const getAllAtributeAction = () => {
    return (dispatch2) => {
        let promise = attributeServices.getAllAtribute();
        promise.then((result) => {
            let action2 = {
                type: GET_ALL_ATTRIBUTE,
                allAttribute: result.data.content,
            };
            dispatch2(action2);
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const getCustomAttributeAction = (filterValue) => {
    return (dispatch2) => {
        dispatch2(tableLoadingAction(true));
        let promise = attributeServices.getCustomAttribute(filterValue);
        promise.then((result) => {
            let action2 = {
                type: GET_CUSTOM_ATTRIBUTE,
                customAttribute: result.data.content,
            };
            dispatch2(action2);
            dispatch2(tableLoadingAction(false));
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const getAttributeAction = (id) => {
    return (dispatch2) => {
        let promise = attributeServices.getAtribute(id);
        promise.then((result) => {
            let action2 = {
                type: GET_ATTRIBUTE,
                attribute: result.data.content,
            };
            dispatch2(action2);
        });
        promise.catch((error) => {
            notification(error.response.data.message);
        });
    };
};

export const addAttributeAction = (data = {}) => {
    return (dispatch2) => {
        let promise = attributeServices.addAttribute(data);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_ATTRIBUTE,
                reloadAttribute: true,
            };
            dispatch2(action2);
            notification("Thêm thuộc tính thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const updateAttributeAction = (id = '', data = {}) => {
    return (dispatch2) => {
        let promise = attributeServices.updateAttribute(id, data);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_ATTRIBUTE,
                reloadAttribute: true,
            };
            dispatch2(action2);
            notification("Cập nhập thuộc tính thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const removeAttributeAction = (id = '') => {
    return (dispatch2) => {
        let promise = attributeServices.deleteAttribute(id);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_ATTRIBUTE,
                reloadAttribute: true,
            };
            dispatch2(action2);
            notification("Xóa thuộc tính thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const reloadAttributeAction = () => {
    return (dispatch2) => {
        let action2 = {
            type: RELOAD_ATTRIBUTE,
            reloadAttribute: false,
        };
        dispatch2(action2);
    };
};


