import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import { Form, Input, Button, Table } from 'antd';
import { useEffect } from 'react';
import {
    deletePromotionAction,
    getCustomPromotionAction,
    reloadPromotionAction
} from '../../../redux/actions/PromotionAction';
import { useFormik } from 'formik';
import { showDetail } from '../../../util/func';

export default function Promotions() {
    const [searchName, setSearchName] = useState('');
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const { customPromotion, reloadPromotion } = useSelector(state => state.PromotionReducer);
    const { tableLoading } = useSelector(state => state.LoadingReducer);
    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        getCustomPromotionAPI();
    }, [JSON.stringify(tableParams), searchName]);

    useEffect(() => {
        if (reloadPromotion !== false) {
            getCustomPromotionAPI();
            let action = reloadPromotionAction();
            dispatch(action);
        }
    }, [reloadPromotion]);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            sorter: (a, b) => a?.id - b?.id,
        },
        {
            title: 'Mã',
            dataIndex: 'code',
        },
        {
            title: 'Nội dung',
            dataIndex: 'content',
            render: (t, r) => <Button type="primary" onClick={() => showDetail(r?.content)}>
                Xem chi tiết
            </Button>
        },
        {
            title: 'Điều kiện',
            dataIndex: 'condition',
        },
        {
            title: 'Hiệu lực',
            dataIndex: 'validity',
        },
        {
            title: 'Hàng động',
            dataIndex: '',
            width: '10%',
            render: (t, r) => <div>
                <EditFilled style={{ fontSize: '16px', color: '#1677ff', marginRight: '10px' }} onClick={() => {
                    navigate(`/admin/edit-promotion/${r?.id}`);
                }} />
                <DeleteFilled style={{ fontSize: '16px', color: '#ff4d4f' }} onClick={() => {
                    let action = deletePromotionAction(r?.id);
                    dispatch(action);
                }} />
            </div>,
        },
    ];

    const handleTableChange = (pagination, sorter) => {
        setTableParams({
            pagination,
            ...sorter,
        });
        // `dataSource` is useless since `pageSize` changed
        if (pagination?.pageSize !== tableParams.pagination?.pageSize) {
            customPromotion.data = [];
        }
    };

    const validate = values => {
        setSearchName(values.name.trim());
    };

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validate,
        onSubmit: values => {
            setSearchName(values.name.trim());
        },
    });

    const { handleChange, handleSubmit } = formik;

    let getCustomPromotionAPI = () => {
        let { current, pageSize } = tableParams.pagination;
        let filterValue = {
            pageIndex: current,
            pageSize: pageSize,
            keyword: searchName,
            order: 'ASC',
            sortBy: 'createdAt'
        }
        let action = getCustomPromotionAction(filterValue);
        dispatch(action);
    };
    return (
        <div>
            <h2 >Quản lý khuyến mãi</h2>
            <Button type="primary" style={{ marginBottom: '20px' }}
                onClick={() => {
                    navigate(`/admin/add-promotion`);
                }}>Thêm khuyến mãi</Button>
            <Form layout="horizontal" onFinish={handleSubmit}>
                <Form.Item name="name" style={{ display: 'inline-block', width: '80%' }}>
                    <Input onChange={handleChange} placeholder="Tìm kiếm theo tiêu đề khuyến mãi" />
                </Form.Item>
                <Form.Item style={{ display: 'inline-block', marginLeft: '10px' }}>
                    <Button type="default" htmlType="submit">Tìm kiếm</Button>
                </Form.Item>
            </Form>
            <Table rowKey='id' columns={columns} dataSource={customPromotion?.data}
                pagination={{
                    current: customPromotion?.pageIndex,
                    pageSize: customPromotion?.pageSize,
                    total: customPromotion?.totalRow
                }}
                loading={tableLoading}
                onChange={handleTableChange} />
        </div>
    );
};
