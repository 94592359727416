import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import { Outlet } from 'react-router-dom'
import BackToTop from '../components/BackToTop/BackToTop'
import ScrollToTop from '../components/ScrollToTop/ScrollToTop'

export default function HomeLayout() {
  return (
    <div>
      <ScrollToTop />
      <Header />
      <Outlet />
      <Footer />
      <BackToTop />
    </div>
  )
}
