import React, { Fragment, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaStar } from "react-icons/fa";
import SimpleTabs from '../../components/SimpleTabs/SimpleTabs';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomProductAction } from '../../redux/actions/ProductAction';
import Pagination from '../../components/Pagination/Pagination';
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';

const nameSimpleTab = 'products__SimpleTab';
//instart element simple tab
const tabs = [
    {
        eventKey: 'pop',
        title: 'Phổ biến',
        filterValue: { order: 'DESC', sortBy: 'viewed', old: 0, detail: false }
    },
    {
        eventKey: 'new',
        title: 'Mới nhất',
        filterValue: { order: 'DESC', sortBy: 'createdAt', old: 0, detail: false }
    },
    {
        eventKey: 'hot',
        title: 'Bán chạy',
        filterValue: { order: 'DESC', sortBy: 'sold', old: 0, detail: false }
    },
    {
        eventKey: 'price',
        title: 'Giá',
        filterValue: { order: 'ASC', sortBy: 'price', old: 0 },
        childs: [
            {
                eventKey: 'cheap',
                title: 'Giá: Thấp đến Cao',
                filterValue: { order: 'ASC', sortBy: 'price', old: 0, detail: false }
            },
            {
                eventKey: 'expensive',
                title: 'Giá: Cao đến Thấp',
                filterValue: { order: 'DESC', sortBy: 'price', old: 0, detail: false }
            },
        ]
    },
    {
        eventKey: 'old',
        title: 'Đồ cũ',
        filterValue: { order: 'DESC', sortBy: 'viewed', old: 1, detail: false }
    },
    // {
    //     eventKey: 'detail',
    //     title: 'Thông tin chi tiết',
    //     filterValue: { order: 'DESC', sortBy: 'viewed', old: 0, detail: true }
    // },
];

const tabsConvert = tabs.flatMap(element => element.childs ? [element, ...element.childs.map((el) => ({...el, parentKey: element.eventKey}))] : [element]);

export default function Products() {
    const dispatch = useDispatch();
    let { state } = useLocation();
    const [activeTab, setActiveTab] = useState(tabsConvert.find((el) => el.eventKey === state?.activeTab)?.parentKey || state?.activeTab || 'pop');
    const [pageIndex, setPageIndex] = useState(1);
    const { customProduct } = useSelector(state => state.ProductReducer);
    useEffect(() => {
        document.title = "Tất cả sản phẩm";
    }, [])

    //get result by simple tab,  pagingation
    useEffect(() => {
        if (activeTab) {
            getCustomProductAPI();
        }
        // eslint-disable-next-line
    }, [activeTab, pageIndex]);

    let getCustomProductAPI = () => {
        let tabValue = tabsConvert.find((el) => el?.eventKey === activeTab)?.filterValue
        let filterValue = {
            pageIndex: pageIndex,
            pageSize: 30,
            keyword: '',
        }
        filterValue = Object.assign(filterValue, tabValue ? tabValue : {});
        let action = getCustomProductAction(filterValue);
        dispatch(action);
    };

    const renderProducts = (data = []) => {
        return <Fragment>
            <Row className='result__content' xs={2} sm={3} md={4} xl={5}>
                {data?.map((el) => {
                    return <Col className='result__item' key={`product-${el?.name}`}>
                        <div className='product__item'>
                            <Link to={`/products/${el?.slug}-pro.${el?.id}`}>
                                <div className='product__image'>
                                    <img src={(el?.images || []).length ? el?.images[0]?.url : ''} alt={el?.name} />
                                </div>
                                <div className='product__content'>
                                    <h4 className='product__name'>{el?.name}</h4>
                                    <div className='product__price'>{el?.contact ? "Liên hệ" : `${new Intl.NumberFormat().format(el?.price)}₫`}</div>
                                    <div className='product__rate'><b>{el?.voted?.toFixed(1)}</b><FaStar /></div>
                                </div>
                            </Link>
                        </div>
                    </Col>
                })}
            </Row>
            <div className='pagination__content d-none d-md-block'>
                <Pagination
                    name={nameSimpleTab}
                    pageIndex={customProduct?.pageIndex}
                    pageSize={customProduct?.pageSize}
                    totalRow={customProduct?.totalRow}
                    onChangePage={(data) => setPageIndex(data.pageIndex)}
                />
            </div>
        </Fragment>
    };

    return (
        <div className='brand product__style'>
            <Container fluid='lg'>
                <Row className='product__style__content'>
                    <Col className='product__result' xs={12} lg={9} xl={10}>
                        <div className="result__wapper">
                            <div className="product__style__title">
                                <SimpleTabs
                                    name={nameSimpleTab}
                                    tabs={tabs}
                                    defaultActiveTab={activeTab}
                                    onSelectTab={(data) => setActiveTab(data.keyTab)}
                                    pagination={{
                                        pageIndex: customProduct?.pageIndex,
                                        pageSize: customProduct?.pageSize,
                                        totalRow: customProduct?.totalRow,
                                    }}
                                    onChangePage={(data) => setPageIndex(data.pageIndex)}
                                />
                            </div>
                            {(customProduct?.data || []).length ? renderProducts(customProduct?.data) : <div className='notify'>Hiện chưa có sản phẩm</div>}
                        </div>
                    </Col>
                    <Col className='filter__panel d-none d-lg-block' lg={3} xl={2}>
                        <div className='section__simple'>
                            <div className='section__header'>
                                <div className='section__header__title'>BỘ LỌC TÌM KIẾM</div>
                            </div>
                            <div className='section__content'>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
