import productServices from "../../services/ProductService";
import { notification } from "../../util/func";
import {
    GET_CUSTOM_PRODUCT,
    GET_PRODUCT,
    RELOAD_PRODUCT,
    SEARCH_PRODUCT,
    GET_PRODUCT_BY_PARENT,
    CREATED_FILTER_PRODUCTS,
} from "../types/ProductType";
import { tableLoadingAction } from "./LoadingAction";

export const getProductAction = (id) => {
    return (dispatch2) => {
        let promise = productServices.getProduct(id);
        promise.then((result) => {
            let action2 = {
                type: GET_PRODUCT,
                product: result.data.content,
            };
            dispatch2(action2);
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        })
    }
}

export const getCustomProductAction = (filterValue) => {
    return (dispatch2) => {
        dispatch2(tableLoadingAction(true));
        let promise = productServices.getCustomProduct(filterValue);
        promise.then((result) => {
            let action2 = {
                type: GET_CUSTOM_PRODUCT,
                customProduct: result.data.content,
            }
            dispatch2(action2);
            dispatch2(tableLoadingAction(false));
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const getProductsByParentAction = (filterValue) => {
    return (dispatch2) => {
        let promise = productServices.getProductsByParent(filterValue)
        promise.then((result) => {
            let action2 = {
                type: GET_PRODUCT_BY_PARENT,
                productsByParent: result.data.content,
            };
            dispatch2(action2);
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const searchProductAction = (searchWord = '') => {
    return (dispatch2) => {
        let promise = productServices.searchProduct(searchWord);
        promise.then((result) => {
            let action2 = {
                type: SEARCH_PRODUCT,
                searchProduct: result.data.content,
            }
            dispatch2(action2);
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const filterProductsAction = (name, filterValue) => {
    return (dispatch2) => {
        let promise = productServices.getCustomProduct(filterValue);
        promise.then((result) => {
            let action2 = {
                type: CREATED_FILTER_PRODUCTS,
                filterProduct: {
                    name: name,
                    products: result.data.content?.data
                },
            }
            dispatch2(action2);
        });
        promise.catch((error) => {
            console.log(error);
        })
    }
}

export const addProductAction = (data = {}) => {
    return (dispatch2) => {
        let promise = productServices.addProduct(data);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_PRODUCT,
                reloadProduct: true,
            };
            dispatch2(action2);
            notification("Thêm sản phẩm thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const updateProductAction = (id = '', productUp = {}) => {
    return (dispatch2) => {
        const promise = productServices.updateProduct(id, productUp);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_PRODUCT,
                reloadProduct: true,
            }
            dispatch2(action2);
            notification("Cập nhập sản phẩm thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const removeProductAction = (id = '') => {
    return (dispatch2) => {
        let promise = productServices.deleteProduct(id);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_PRODUCT,
                reloadProduct: true,
            }
            dispatch2(action2);
            notification("Xóa sản phẩm thành công");
        });
        promise.catch((error) => {
            notification(error.response?.data.message);
        });
    };
};

export const reloadProductAction = () => {
    return (dispatch2) => {
        let action2 = {
            type: RELOAD_PRODUCT,
            reloadProduct: false,
        }
        dispatch2(action2);
    };
};

