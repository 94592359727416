import promotionServices from "../../services/PromotionService"
import { notification } from "../../util/func";
import { 
    GET_ALL_PROMOTION, 
    GET_CUSTOM_PROMOTION, 
    GET_PROMOTION, 
    RELOAD_PROMOTION 
} from "../types/PromotionType";
import { tableLoadingAction } from "./LoadingAction";

export const getPromotionAction = (id) => {
    return (dispatch2) => {
        let promise = promotionServices.getPromotion(id);
        promise.then((result) => {
            let action2 = {
                type: GET_PROMOTION,
                promotion: result.data.content,
            }
            dispatch2(action2);
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const getAllPromotionAction = () => {
    return (dispatch2) => {
        let promise = promotionServices.getAllPromotion();
        promise.then((result) => {
            let action2 = {
                type: GET_ALL_PROMOTION,
                allPromotion: result.data.content,
            }
            dispatch2(action2);
        });
        promise.catch((error) => {
            console.log(error);
        });
    };
};

export const getCustomPromotionAction = (filterValue) => {
    return (dispatch2) => {
        dispatch2(tableLoadingAction(true));
        let promise = promotionServices.getCustomPromotion(filterValue);
        promise.then((result) => {
            let action2 = {
                type: GET_CUSTOM_PROMOTION,
                customPromotion: result.data.content,
            }
            dispatch2(action2);
            dispatch2(tableLoadingAction(false));
        });
        promise.catch((error) => {
            notification(error.response.data.message);
        });
    };
};

export const addPromotionAction = (data = {}) => {
    return (dispatch2) => {
        let promise = promotionServices.addPromotion(data);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_PROMOTION,
                reloadPromotion: true,
            }
            dispatch2(action2);
            notification("Thêm khuyến mãi thành công");
        });
        promise.catch((error) => {
            notification(error.response.data.message);
        });
    };
};

export const updatePromotionAction = (id = '', data = {}) => {
    return (dispatch2) => {
        let promise = promotionServices.updatePromotion(id, data);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_PROMOTION,
                reloadPromotion: true,
            };
            dispatch2(action2);
            notification("Cập nhập khuyến mãi thành công");
        });
        promise.catch((error) => {
            notification(error.response.data.message);
        });
    };
};

export const deletePromotionAction = (id = '') => {
    return (dispatch2) => {
        let promise = promotionServices.deletePromotion(id);
        promise.then((result) => {
            let action2 = {
                type: RELOAD_PROMOTION,
                reloadPromotion: true,
            };
            dispatch2(action2);
            notification("Xóa khuyến mãi thành công");
        });
        promise.catch((error) => {
            notification(error.response.data.message);
        });
    };
};

export const reloadPromotionAction = () => {
    return (dispatch2) => {
        let action2 = {
            type: RELOAD_PROMOTION,
            reloadPromotion: false,
        };
        dispatch2(action2);
    };
};
